import Box from "pages/_components/Box";
import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import AssistantAlert from "pages/login/_components/AssistantAlert";
import Step1Content from "pages/login/_components/Step1Content";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { selectors as assistantSelectors } from "reducers/assistant";
import { selectors as loginSelectors, actions as loginActions } from "reducers/login";
import { actions as softTokenActions, selectors as softTokenSelector } from "reducers/softToken";
import { actions as notificationActions } from "reducers/notification";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import * as i18n from "util/i18n";
import { push } from "react-router-redux";
import Button from "pages/_components/Button";
import ImageSlider from "pages/_components/ImageSlider";
import LoginWrapper from "./_components/LoginWrapper";

const Lobby = (props) => {
    const {
        dispatch,
        hasActiveSession,
        // showSuspiciousActivityModal,
        isDesktop,
        isMobileNative,
        isFromAmazon,
        isFromGoogle,
        isFromMessenger,
        isFromWhatsapp,
        savedPinCode,
    } = props;
    // const [showSuspiciousModal, setShowSuspiciousModal] = useState();

    useEffect(() => {
        dispatch(softTokenActions.getDevicePin());
    }, []);

    const handleBack = () => {
        dispatch(routerActions.push("/"));
        dispatch(notificationActions.removeNotification());
    };

    const setIsSubmiting = (newValue) => {
        dispatch(loginActions.setSubmiting(newValue));
    };

    const generateSoftToken = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("PIN: ", savedPinCode);
        if (savedPinCode) {
            dispatch(push("/softTokenPin"));
        } else {
            dispatch(push("/softToken"));
        }
    };

    const login = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const routerAction = {
            pathname: "/loginStep1",
            state: { handleBackPage: "OK", titleStep1: "global.login" },
        };
        dispatch(routerActions.replace(routerAction));
        // dispatch(sessionActions.isAnonymousSet(true));
        // dispatch(push(routerAction));
    };

    return (
        <>
            {true && (
                <>
                    <Notification scopeToShow="login" />
                    <Box display="flex" column alignX="between" className="scroll mt-7 lobby-container" fullHeight>
                        {isMobileNative && <Head position="relative" {...(isMobileNative && { onBack: handleBack })} />}
                        <Box
                            display="flex"
                            alignX="flex-end"
                            component="main"
                            position="relative"
                            zIndex="9"
                            className="login-background mt-auto">
                            <Box display="block" position="absolute" top="n-12" fullWidth>
                                <BoxErrorNotification isMobile scopeToShow="externalLayout" />
                            </Box>
                            <Box display="flex" fullWidth fullHeight className="login-form" alignY="center">
                                <Box display="flex" column fullWidth alignX="center">
                                    <ImageSlider
                                        images={[
                                            "images/login_slider_1.png",
                                            "images/login_slider_2.png",
                                            "images/login_slider_3.png",
                                        ]}
                                    />
                                    <LoginWrapper showInfoLinks={false}>
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            defaultLabelText={i18n.get("global.login", "Iniciar sesión")}
                                            btnUppercase={false}
                                            onClick={login}
                                            block
                                        />
                                        <Button
                                            image="images/keyhole.svg"
                                            type="button"
                                            bsStyle="outline"
                                            label="softToken.generate"
                                            onClick={generateSoftToken}
                                            btnUppercase={false}
                                            block
                                        />
                                    </LoginWrapper>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    isAssistantLogin: assistantSelectors.isAssistantLogin(state),
    isFromAmazon: assistantSelectors.isFromAmazon(state),
    isFromGoogle: assistantSelectors.isFromGoogle(state),
    isFromMessenger: assistantSelectors.isFromMessenger(state),
    isFromWhatsapp: assistantSelectors.isFromWhatsapp(state),
    activeRegion: loginSelectors.getRegion(state),
    hasActiveSession: sessionSelectors.isLoggedIn(state),
    isSubmiting: loginSelectors.getSubmiting(state),
    savedPinCode: softTokenSelector.getDevicePin(state),
});

Lobby.propTypes = {
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    isMobileNative: bool.isRequired,
    dispatch: func.isRequired,
    isFromAmazon: bool,
    isFromGoogle: bool,
    isFromMessenger: bool,
    isFromWhatsapp: bool,
    activeRegion: string,
    hasActiveSession: bool.isRequired,
    isSubmiting: bool.isRequired,
    location: shape({}).isRequired,
    isUserBloqued: bool,
    savedPinCode: string,
};

Lobby.defaultProps = {
    isFromAmazon: false,
    isFromGoogle: false,
    isFromMessenger: false,
    isFromWhatsapp: false,
    activeRegion: "",
    isUserBloqued: false,
    savedPinCode: "",
};

export default connect(mapStateToProps)(Lobby);
