import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Info from "pages/_components/Info";
import ColumnHeader from "pages/_components/container/ColumnHeader";
import CapsList from "pages/administration/_components/CapsList";
import PermissionsList from "pages/administration/_components/PermissionsList";
import UserDetailsGroupList from "pages/administration/_components/UserDetailsGroupList";
import RestrictionsUserProfile from "pages/administration/restrictions/RestrictionsUserProfile";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { formatToken, maskEmail, maskPhoneNumber } from "util/format";
import * as i18nUtils from "util/i18n";
import {
    TOKEN_STATUS_AS_PENDING_LIST,
    TOKEN_STATUS_AS_UNAVAILABLE,
    USER_AUTHENTICATOR_TOKENRO,
    USER_TOKEN_STATUS_ACTIVE,
    renderAuthenticatorStatusLabel,
} from "util/userToken.util";

/* eslint-disable react/no-multi-comp */

class Details extends Component {
    static propTypes = {
        actions: shape({
            loadDetailsRequest: func.isRequired,
            changeUserStatusPreview: func.isRequired,
            updateDispatcherRequest: func.isRequired,
            getRestrictions: func.isRequired,
        }).isRequired,
        match: shape({
            params: shape({ id: string }),
        }).isRequired,
        routerActions: shape({
            goBack: func.isRequired,
        }).isRequired,
        userEnvStatus: string,
        user: shape({
            idUser: string,
            fullName: string,
        }),
        hasMassiveEnabled: bool,
        schemeName: string,
        fetching: bool.isRequired,
        signatureLevel: string.isRequired,
        userInfo: shape({
            dispatcher: bool,
        }),
        loggedUser: shape({}).isRequired,
        goUsers: func.isRequired,
        userTokenStatus: string.isRequired,
        userSerialToken: string.isRequired,
    };

    static defaultProps = {
        userEnvStatus: "",
        user: {},
        hasMassiveEnabled: false,
        schemeName: "",
        userInfo: {},
    };

    state = {
        fetchingAction: false,
    };

    componentDidMount() {
        const { actions, match } = this.props;
        const idUser = match.params.id;
        actions.loadDetailsRequest(idUser);
        actions.getRestrictions(idUser);
    }

    handleBack = () => {
        const { goUsers } = this.props;
        goUsers();
    };

    handleSelect = (key, fullName) => {
        const { actions, match, userEnvStatus } = this.props;
        let actionOnTheUser = "delete";
        if (key === 0) {
            actionOnTheUser = userEnvStatus === "active" ? "block" : "unblock";
        }
        this.setState({ fetchingAction: true });
        actions.changeUserStatusPreview([match.params.id], [fullName], actionOnTheUser);
    };

    handleToggleDispatcher = (dispatcher) => {
        const { user, actions } = this.props;
        actions.updateDispatcherRequest(user.idUser, dispatcher);
    };

    renderHeader = () => {
        const { user, userEnvStatus, userInfo } = this.props;
        const actionKey = userEnvStatus === "active" ? "administration.block" : "administration.unblock";
        const isAdministrator = userInfo.isAdmin;

        return (
            <>
                <Head
                    onBack={this.handleBack}
                    textBack="administration.users.returnToList"
                    hasCenterContent
                    {...(!isAdministrator && {
                        rightChildren: (
                            <Box display="flex" gap="7">
                                <Button
                                    image="images/icons/deleteTrash.svg"
                                    bsStyle="outline"
                                    className="btn-width-lg"
                                    label="administration.delete"
                                    onClick={() => {
                                        this.handleSelect(1, user);
                                    }}
                                    btnUppercase={false}
                                />
                                <Button
                                    image="images/icons/locked.svg"
                                    bsStyle="primary"
                                    className="btn-width-lg"
                                    // TODO (LABEL)
                                    label={actionKey}
                                    onClick={() => {
                                        this.handleSelect(0, user);
                                    }}
                                    btnUppercase={false}
                                />
                            </Box>
                        ),
                    })}>
                    <Box display="flex" alignY="center" gap="3">
                        <Text component="h2" className="m-0 mr-4 full-width" color="heading-color">
                            {user.fullName}
                        </Text>
                        {isAdministrator && (
                            // <Box
                            //     display="flex"
                            //     alignX="center"
                            //     alignY="center"
                            //     background="background-divider"
                            //     className="px-5 py-1"
                            //     borderRadius="sm">
                            //     <Text labelKey="administration.administrator" size="7" color="text-boton" bold />
                            // </Box>
                            <Info
                                className="info-user-list info-user-admin"
                                text={i18nUtils.get(`administration.administrator`)}
                            />
                        )}
                        {/* <Box
                            display="flex"
                            alignX="center"
                            alignY="center"
                            background={userEnvStatus === "active" ? "success" : "error-background-color"}
                            className="px-5 py-1"
                            borderRadius="sm">
                            <Text labelKey={`user.status.${userEnvStatus}`} size="7" color="text-boton" bold />
                        </Box> */}
                        <Info
                            className={`info-user-list info-user-${userEnvStatus}`}
                            text={i18nUtils.get(`global.${userEnvStatus}`)}
                        />
                    </Box>
                </Head>
                {/* NO BORRAR (IMPLEMENTACION DE PRODUCTO) */}
                {/* <AdministrationHeading>
                    {({ email, fullName }) => (
                        <div>
                            <Grid>
                                <Row className="content-data">
                                    <Heading.Data>
                                        <Heading.DataGroup label="administration.permissions.email" data={email} />
                                    </Heading.Data>
                                    <Heading.Data>
                                        <Heading.DataGroup
                                            label="administration.permissions.status"
                                            data={
                                                <span>
                                                    <I18n id={`user.status.${userEnvStatus}`} />
                                                </span>
                                            }
                                            dataClassName={classNames("data-tag", {
                                                "data-tag-blocked": userEnvStatus === "blocked",
                                            })}
                                        />
                                    </Heading.Data>
                                </Row>
                            </Grid>
                            {hasMassiveEnabled && (
                                <Grid fluid>
                                    <Nav
                                        className="product-detail-commands"
                                        variant="pills"
                                        onSelect={(key) => this.handleSelect(key, fullName)}>
                                        <Nav.Item className="btn btn-outline" href="#">
                                            <Image src="images/lock--checked.svg" className="svg-icon svg-caret" />

                                            <I18n id={actionKey} />
                                        </Nav.Item>
                                        <Nav.Item className="btn btn-outline" href="#">
                                            <Image src="images/delete-message.svg" className="svg-icon svg-caret" />
                                            <I18n id="administration.delete" />
                                        </Nav.Item>
                                    </Nav>
                                </Grid>
                            )}
                        </div>
                    )}
                </AdministrationHeading> */}
            </>
        );
    };

    renderAdvancedContent = () => {
        const { user, signatureLevel, schemeName, userInfo } = this.props;
        const isAdministrator = userInfo.isAdmin;

        return (
            <>
                <Row className="mb-7">
                    {this.renderUserResume()}
                    <Col xs={6}>
                        <Box background="white" borderRadius="lg" className="p-7 box-shadow-small">
                            <Box display="flex" fullWidth>
                                <Box display="flex" alignY="center" fullWidth>
                                    <Text bold size="4" labelKey="administration.channels.title" />
                                </Box>
                                {!isAdministrator && (
                                    <Box display="flex" alignY="center" alignX="end" fullWidth>
                                        <Button
                                            href={`/administration/${schemeName}/channels/${user.idUser}`}
                                            bsStyle="outline"
                                            label="administration.configure"
                                            btnUppercase={false}
                                            className="btn-small"
                                        />
                                    </Box>
                                )}
                            </Box>
                            <Box className="mt-6">
                                <CapsList />
                            </Box>
                        </Box>
                        <Box background="white" borderRadius="lg" className="p-7 mt-7 box-shadow-small">
                            <Box display="flex" fullWidth>
                                <Box display="flex" alignY="center">
                                    <Text bold size="4" labelKey="administration.permissions.signature" />
                                </Box>
                                <Box display="flex" alignY="center" fullWidth>
                                    <Box
                                        display="flex"
                                        background="primary"
                                        borderRadius="xl"
                                        alignX="center"
                                        alignY="center"
                                        className="ml-4 width-signature width-signature-level text-bold color-inverse size-6">
                                        {signatureLevel
                                            ? i18nUtils.get(
                                                  `administration.signatures.create.signatureLevelsCounts.${signatureLevel}.label`,
                                              )
                                            : i18nUtils.get("global.no")}
                                    </Box>
                                </Box>
                                {!isAdministrator && (
                                    <Box display="flex" alignY="center" alignX="end" fullWidth>
                                        <Button
                                            href={`/administration/${schemeName}/signature/${user.idUser}`}
                                            bsStyle="outline"
                                            label="administration.configure"
                                            className="btn-small"
                                            btnUppercase={false}
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={6}>
                        <Box background="white" borderRadius="lg" className="p-7 box-shadow-small" fullHeight>
                            <Box display="flex" fullWidth>
                                <Box display="flex" alignY="center" className="py-3">
                                    <Text bold size="4" labelKey="administration.user.groups.title" />
                                </Box>
                                <Box display="flex" alignY="center" alignX="end" fullWidth>
                                    {/* <Button
                                        href={`/administration/${schemeName}/groupsOfUser/${user.idUser}`}
                                        bsStyle="outline"
                                        label="administration.configure"
                                        btnUppercase={false}
                                    /> */}
                                </Box>
                            </Box>
                            <Box className="mt-5">
                                <UserDetailsGroupList />
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </>
        );
    };

    isPendingStatus = (tokenStatus) => tokenStatus && TOKEN_STATUS_AS_PENDING_LIST.some((item) => item === tokenStatus);

    getColorClass = (tokenStatus) => {
        let colorClass = "";
        switch (tokenStatus) {
            case USER_TOKEN_STATUS_ACTIVE:
                colorClass = "active";
                break;
            default:
                if (this.isPendingStatus(tokenStatus)) {
                    colorClass = "pending";
                } else {
                    colorClass = "inactive";
                }
                break;
        }
        return colorClass;
    };

    renderUserResume = () => {
        const { user, fetching, schemeName, userTokenStatus, userSerialToken } = this.props;
        // const isLoggedUser = loggedUser?.userId === user?.idUser;
        return (
            <>
                {!fetching && (
                    <Box background="white" borderRadius="lg" className="p-7 box-shadow-small">
                        <Box display="flex" fullWidth>
                            <Box display="flex" alignY="center" fullWidth>
                                <Text bold size="4" labelKey="administration.user.info.title" />
                            </Box>
                            <Box display="flex" alignY="center" alignX="end" fullWidth>
                                <Button
                                    bsStyle="outline"
                                    label="global.edit"
                                    btnUppercase={false}
                                    className="btn-small"
                                    href={`/administration/${schemeName}/user/${user.idUser}/contacts`}
                                />
                            </Box>
                        </Box>
                        <Box display="flex" className="mt-7" gapX="3">
                            <Info
                                labelKey="administration.user.details.documentNumber"
                                text={`${i18nUtils.get(`documentType.abbreviation.${user?.documentType}`)} ${user?.documentNumber}`}
                            />
                            <Info
                                labelKey="administration.user.details.email"
                                text={maskEmail(user?.email)?.toLowerCase()}
                            />
                            <Info
                                labelKey="administration.user.details.phone"
                                text={maskPhoneNumber(user.mobileNumber)}
                            />
                            <Info
                                labelKey="administration.permissions.lastConnection"
                                date={user.lastLogin && user.lastLogin}
                                text={!user.lastLogin && "-"}
                                anotherFormatDate="DD/MM/YYYY hh:mm a"
                            />
                            <div className="info-data full-width">
                                <div className="info-data-label">
                                    <Text labelKey="administration.user.details.token" />
                                    {userTokenStatus && !TOKEN_STATUS_AS_UNAVAILABLE.includes(userTokenStatus) && (
                                        <span className={`token-status-${this.getColorClass(userTokenStatus)}`}>
                                            {renderAuthenticatorStatusLabel(
                                                userTokenStatus,
                                                USER_AUTHENTICATOR_TOKENRO,
                                            )}
                                        </span>
                                    )}
                                </div>
                                <div className="info-data-value mt-3">{`${formatToken(userSerialToken, "Soft Token")}`}</div>
                            </div>
                        </Box>
                    </Box>
                )}
            </>
        );
    };

    renderMediumContent = () => {
        const { user, schemeName, userInfo } = this.props;

        return (
            <>
                <Row>
                    {this.renderUserResume()}
                    <Col xs={4}>
                        <Box display="flex" column gap="7" fullHeight>
                            <Box display="flex" column background="component-background" borderRadius="default">
                                <ColumnHeader
                                    title="administration.channels.title"
                                    {...(!userInfo.isAdmin && {
                                        url: `/administration/${schemeName}/channels/${user.idUser}`,
                                        linkText: "administration.configure",
                                    })}
                                />
                                <Box display="flex" column className="px-8 pt-5 pb-9">
                                    <CapsList />
                                </Box>
                            </Box>
                            <Box display="flex" column background="component-background" borderRadius="default" flex1>
                                <ColumnHeader
                                    title="administration.permissions.signature"
                                    {...(!userInfo.isAdmin && {
                                        linkText: `administration.signature.configure.text.${userInfo.hasSignature}`,
                                        url: `/administration/${schemeName}/signature/${user.idUser}/confirm`,
                                    })}
                                    tooltipText={
                                        userInfo.hasSignature
                                            ? "administration.user.details.signature.tooltip.hasSignature"
                                            : "administration.user.details.signature.tooltip"
                                    }
                                />
                                <Box display="flex" column className="px-8 pt-5 pb-9">
                                    <Box
                                        display="flex"
                                        alignX="center"
                                        background={userInfo.hasSignature ? "success-color" : "error-border-color"}
                                        className="p-3 min-width-2-5rem"
                                        borderRadius="xl"
                                        fitWidth>
                                        <Text
                                            color="text-inverse-color"
                                            size="6"
                                            bold
                                            labelKey={`administration.user.signature.${userInfo.hasSignature}`}
                                            uppercase
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={4}>
                        <Box display="flex" column background="component-background" borderRadius="default" fullHeight>
                            <ColumnHeader
                                className="px-5 py-3"
                                title="administration.permissions"
                                {...(!userInfo.isAdmin && {
                                    linkText: "administration.configure",
                                    url: `/administration/${schemeName}/permissions/${user.idUser}`,
                                })}
                            />
                            <Box display="flex" column className="pb-9">
                                <PermissionsList
                                    classNameContainer={null}
                                    borderContainer={null}
                                    accordionBorderRadius={null}
                                    childrenListULClassName="m-0 full-width"
                                    childrenListLIClassName="px-0"
                                    childrenListLIInnerClassName="pl-5 pr-7 min-height-9"
                                    contentClassName="my-0 px-0">
                                    {(list) =>
                                        list || (
                                            <Text
                                                labelKey="administration.permissions.empty"
                                                size="6"
                                                align="center"
                                                className="px-5 py-3 mt-3"
                                            />
                                        )
                                    }
                                </PermissionsList>
                            </Box>
                        </Box>
                    </Col>
                    <Col xs={4}>
                        <Box display="flex" column background="component-background" borderRadius="default" fullHeight>
                            <ColumnHeader title="administration.restrictions.title" />
                            <Box display="flex" column className="pt-5 pb-9" position="relative" fullHeight>
                                <RestrictionsUserProfile idUser={user.idUser} isAdminUser={userInfo.isAdmin} />
                            </Box>
                        </Box>
                    </Col>
                </Row>
            </>
        );
    };

    renderContent = () => {
        const { schemeName } = this.props;

        if (schemeName === "medium") {
            return this.renderMediumContent();
        }

        return this.renderAdvancedContent();
    };

    render() {
        const { fetching } = this.props;
        const { fetchingAction } = this.state;
        const isLoading = fetching; // && !Object.keys(user).length;

        return (
            <>
                <Notification scopeToShow="administration" />
                {this.renderHeader()}
                <MainContainer
                    className="main-container border-radius-lg mt-3 p-7 box-shadow-small background-white"
                    showLoader={isLoading || fetchingAction}>
                    {this.renderContent()}
                </MainContainer>
            </>
        );
    }
}

export default Details;
