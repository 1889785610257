import { bool, func, oneOfType, shape } from "prop-types";
import React, { Component } from "react";
import Image from "pages/_components/Image";
import I18n from "pages/_components/I18n";
import Movement from "./Movement";
// import AdditionalHeader from "./AdditionalHeader";
import Box from "pages/_components/Box";
import { dictionaryMiniatureCard } from "util/creditCards.js";
import NoResults from "pages/_components/NoResultsMessage";
import Row from "pages/_components/Row";
import Col from "react-bootstrap/lib/Col";
import { isDesktop } from "react-device-detect";
import * as i18nUtils from "util/i18n";
import classNames from "classnames";
import Info from "pages/_components/Info";
import { Collapse } from "react-bootstrap";
import Text from "pages/_components/Text";

class CreditCardDeferred extends Component {
    static propTypes = {
        creditCards: oneOfType([shape({})]).isRequired,
        showProductIcon: bool,
        setFavoriteAccount: func,
        completeFavorites: bool,
    };

    static defaultProps = {
        showProductIcon: true,
        setFavoriteAccount: () => {},
        completeFavorites: false,
    };

    constructor(props) {
        super(props);
        const numberOfDetails = props.creditCards.length || 3; // Ajusta el tamaño del array
        this.state = {
            moreDetails: Array(numberOfDetails).fill(false), // Inicializa el array con `numberOfDetails` elementos, todos `false`
            // moreDetails: false,
        };
    }

    renderEmptyResults = () => (
        <Box className="my-9">
            <NoResults />
        </Box>
    );

    moreDetailsHandler = (index) => {
        this.setState((prevState) => {
            const newMoreDetails = [...prevState.moreDetails];
            newMoreDetails[index] = !newMoreDetails[index];
            return { moreDetails: newMoreDetails };
        });
    };

    renderList = () => {
        const { creditCards, isRequestAvailable } = this.props;
        if (!creditCards.length) {
            {
                this.renderEmptyResults();
            }
        }
        return (
            <>
                {creditCards.length === 0 && <>{this.renderEmptyResults()}</>}
                {creditCards.length > 0 && (
                    // children-bg-color

                    <Box className="children-bg-color paginate-table children-alternate-bg-color full-height full-width">
                        <Box display="flex" fullWidth alignY="center" className="p-5 size-6 pr-10 mr-10">
                            <I18n id="creditCard.details.info.nameAndNumber" />
                        </Box>
                        {creditCards.map((creditCard, index) => (
                            <>
                                {isDesktop ? (
                                    <Box borderRadius="lg" background="background-disabled" className="mt-5 mb-5">
                                        <Box
                                            borderRadius="lg"
                                            display="flex"
                                            className={classNames({
                                                "pt-5 pb-5 pl-8 pr-8": isDesktop,
                                                "p-5": !isDesktop,
                                            })}
                                            onClick={() => this.moreDetailsHandler(index)}
                                            pointer
                                            fullWidth>
                                            {/* <Box fullWidth>
                                                <Text align="left" bold>
                                                    {creditCard.cardStyle} {creditCard.shortLabel.slice(-9)}
                                                </Text>
                                                <Text align="left">{creditCard.nameOnCard}</Text>
                                            </Box> */}

                                            <Box fullWidth>
                                                <Info
                                                    className="info-account-list"
                                                    labelText={`${creditCard.cardStyle} ${creditCard.shortLabel.slice(-9)}`}
                                                    // text={creditCardData.shortLabel}
                                                />
                                                <Box className="mt-2">
                                                    <Text color="text-color" size="6" regular>
                                                        {creditCard.nameOnCard}
                                                    </Text>
                                                </Box>
                                            </Box>

                                            <Box alignY="center">
                                                <Image
                                                    src={`images/util/arrow-${
                                                        this.state.moreDetails[index] ? "up" : "down"
                                                    }.svg`}
                                                />
                                            </Box>
                                        </Box>
                                        <Collapse in={this.state.moreDetails[index]}>
                                            <Box
                                                className={classNames({
                                                    "pl-8 pr-8": isDesktop,
                                                    "pl-5 pr-5": !isDesktop,
                                                })}>
                                                {creditCard.statements.map((statement, indexStatement) => (
                                                    <Box
                                                        display={isDesktop && "flex"}
                                                        className={`${isDesktop ? "mt-7" : "mt-5"}`}>
                                                        <Info
                                                            labelText={i18nUtils.get(
                                                                "creditCards.movements.filters.selectedDate.label",
                                                            )}
                                                            date={statement.date}
                                                            flex={!isDesktop}
                                                        />
                                                        <Info
                                                            labelText={statement.concept}
                                                            text={`Referencia: ${statement.idStatement}`}
                                                            flex={!isDesktop}
                                                        />
                                                        <Info
                                                            labelText={i18nUtils.get("creditCard.installment.loaded")}
                                                            text={`${statement.installmentPurchaseNumber} de ${statement.installmentPurchaseTotal}`}
                                                            flex={!isDesktop}
                                                        />
                                                        <Info
                                                            labelText={i18nUtils.get("creditCard.installment.value")}
                                                            amount={statement.installmentPurchaseAmount}
                                                            flex={!isDesktop}
                                                        />
                                                        <Info
                                                            labelText={i18nUtils.get("creditCard.installment.balance")}
                                                            amount={statement.installmentPurchaseBalance}
                                                            flex={!isDesktop}
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Collapse>
                                    </Box>
                                ) : (
                                    <></>
                                )}
                            </>
                        ))}
                    </Box>
                )}
            </>
        );
    };

    render() {
        // return <>{this.renderList()}</>;
        return <Box className="mt-3">{this.renderList()}</Box>;
    }
}

export default CreditCardDeferred;
