import { routerActions } from "react-router-redux";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { types } from "reducers/changeExpiredPassword";
import { actions as notificationActions } from "reducers/notification";
import { adjustIdFieldErrors } from "util/form";
import * as changePass from "middleware/changeExpiredPassword";
import { types as sessionTypes } from "reducers/session";
import { types as secondFactorTypes } from "reducers/secondFactor";
import * as i18n from "util/i18n";

const sagas = [
    takeEvery(types.RESET, reset),
    takeLatest(types.CHANGE_PASSWORD_STEP_1_REQUEST, handleChangeExpiredPassStep1),
    takeLatest(types.CHANGE_PASSWORD_STEP_2_REQUEST, handleChangeExpiredPassStep2),
];
export default sagas;
function* reset() {
    yield put(routerActions.replace({ pathname: "/loginStep1" }));
}
function* handleChangeExpiredPassStep1({ username, password, newPassword, newPasswordRepeat, formikBag, onSuccess }) {
    const response = yield call(changePass.changeExpiredPassStep1, username, password, newPassword, newPasswordRepeat);
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        yield put(notificationActions.showNotification(response.data.message, "error", [""]));
    } else {
        const { _exchangeToken, email, mobileNumber } = response.data.data;
        yield put({ type: sessionTypes.SET_MEDIA_CONTACT, email, mobileNumber });
        yield put({
            type: types.CHANGE_PASSWORD_STEP_1_SUCCESS,
            username,
            email,
            exchangeToken: _exchangeToken,
        });
        if (onSuccess) {
            onSuccess({ password, newPassword, newPasswordRepeat });
        }
    }
    formikBag.setSubmitting(false);
}
function* handleChangeExpiredPassStep2({ password, secondFactor, formikBag, onSuccess }) {
    const response = yield call(changePass.changeExpiredPassStep2, password, secondFactor);
    if (response.type === "W") {
        
        const { code, data } = response.data;
        if (data.secondFactor) {
            formikBag.setErrors(data);
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            return;
        }

        if (code === "API707W") {
            yield put(
                notificationActions.showNotification(data.message, "error", [
                    "externalLayout",
                ]),
            );
            return;
        }

        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(i18n.get("secondFactor.credential.otp.expired", "Código OTP expirado, solicite un nuevo código OTP"), "warning", [
                    "externalLayout",
                ]),
            );
            return;
        }

        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        yield put(notificationActions.showNotification(response.data.message, "error", [""]));
    } else {
        yield put({
            type: types.CHANGE_PASSWORD_STEP_2_SUCCESS,
        });
        if (onSuccess) {
            onSuccess();
        }
    }
    formikBag.setSubmitting(false);
}
