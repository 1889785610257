import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import { bool, func, string, node } from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { selectors as softTokenSelector } from "reducers/softToken";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as secondFactorActions, selectors as secondFactorSelectors } from "reducers/secondFactor";
import { isMobileNativeFunc } from "util/device";
import * as config from "util/config";
import * as i18n from "util/i18n";
import { maskPhoneNumber, simpleMaskEmail } from "util/format";
import { Field } from "formik";
import PinInput from "pages/_components/fields/PinInput";
import { SECOND_FACTOR_METHOD_CONST, getSecondFactorFormat } from "util/secondFactorUtils";
import Tooltip from "pages/_components/Tooltip";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import PopOver from "pages/_components/PopOver";
import CountdownCounter from "pages/login/_components/CountdownCounter";
import { routerActions } from "react-router-redux/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { actions as notificationActions } from "reducers/notification";
import Loader from "pages/_components/Loader";

const FORM_ID = "secondFactor.credential";

const scopeToShowLocal = "externalLayout";
const OTP_LENGTH = config.getInteger("credential.otp.length", 6);

const RESEND_WAITING_TIME = 300000; // 5 min

const OtpValidation = ({
    isDesktop,
    credentialType,
    credentialMethod,
    goToLink,
    isFetching,
    onValidSubmit,
    email,
    mobileNumber,
    isCodeSent,
    secondFactorVerificationCode,
    withEchangeToken,
    attempts,
    emailOtp,
    mobileNumberOtp,
    showTitle,
    scopeToShow,
    showNotification,
    fetchingOTP,
    buttons,
    onChangeToken,
    isSubmitting,
}) => {
    const maxAttempts = config.getInteger("core.attempts.secondFactor.maxAttempts");

    const [startCountDown, setStartCountDown] = useState(false);
    const [isResendEnabled, setResendEnabled] = useState(false);

    useEffect(() => {
        if (isCodeSent) {
            setStartCountDown(true);
        }
    }, [isCodeSent]);

    const handleResendCode = () => {
        if (isResendEnabled) {
            setStartCountDown(false);
            secondFactorVerificationCode();
            setResendEnabled(false);
        } else {
            showNotification(i18n.get("secondFactor.credential.otp.active.message"), "warning", [
                scopeToShow || scopeToShowLocal,
            ]);
        }
    };

    const [popoverData, setPopoverData] = useState({
        buttonTitle: null,
        image: "",
        textRegular: null,
        textBold: null,
        textSecondRegular: null,
        textExtraInfo: null,
    });

    const isEmailMethod = credentialMethod === SECOND_FACTOR_METHOD_CONST.EMAIL;
    const isSmsMethod = credentialMethod === SECOND_FACTOR_METHOD_CONST.SMS;
    const isSmsEmailMethod = credentialMethod === SECOND_FACTOR_METHOD_CONST.SMS_EMAIL;

    useEffect(() => {
        secondFactorVerificationCode();

        const newPopoverData = {};
        newPopoverData.buttonTitle = "¿Qué es el código temporal?";
        newPopoverData.image = "images/iconPopOvers/OTP.svg";
        newPopoverData.textRegular = "El código temporal u OTP es un código de 6 dígitos que enviamos a su&nbsp;";
        newPopoverData.textBold = "correo electrónico o por mensaje de texto";
        newPopoverData.textSecondRegular = "&nbsp;para confirmar su transacción.";
        newPopoverData.textExtraInfo =
            "Una vez enviada tendrá hasta 5min para ingresarla y poder continuar con su transacción.";
        setPopoverData(newPopoverData);
    }, [credentialType, credentialMethod]);

    const resendButton = useMemo(
        () => (
            <Tooltip
                onHover
                forEllipsis
                inText={i18n.get("", "Solicitar nuevo OTP")}
                position="top-centered"
                className="height-fit-content pos-relative"
                classNameTooltip="login-tooltip">
                <Button
                    onClick={handleResendCode}
                    bsStyle="only-icon"
                    image="images/sync-btn.svg"
                    imageMd
                    imageColor="primary"
                    className="m-0"
                />
            </Tooltip>
        ),
        [isResendEnabled],
    );

    const renderCredentials = () => (
        <Box className="second-factor-validation" display="flex" column fullWidth>
            {showTitle && (
                <Box className="title-with-icon">
                    <Image src="images/iconTitles/security_device.svg" />
                    <Text component="h3" defaultValue={i18n.get("", "Verificación")} />
                </Box>
            )}
            <Text
                component="h4"
                color="heading"
                bold
                labelKey=""
                defaultValue={i18n.get("login.step3.secondFactor.otp.title", "Ingrese su código temporal (OTP)")}
            />
            {isEmailMethod && (
                <Box display="block" fullWidth className="mb-7">
                    <Text
                        className="line-height-15"
                        defaultValue={i18n.get("login.step3.secondFactor.otp.email.desc")}
                    />
                    <Text bold>{simpleMaskEmail(email || (withEchangeToken && emailOtp))}</Text>
                </Box>
            )}
            {isSmsMethod && (
                <Box display="block" fullWidth className="mb-7">
                    <Text defaultValue={i18n.get("login.step3.secondFactor.otp.sms.desc")} />
                    <Text bold>{maskPhoneNumber(mobileNumber || (withEchangeToken && mobileNumberOtp))}</Text>
                </Box>
            )}
            {isSmsEmailMethod && (
                <Box display="block" fullWidth className="mb-7">
                    <Text defaultValue={i18n.get("login.step3.secondFactor.otp.email.desc")} />
                    <Text bold>{simpleMaskEmail(email || (withEchangeToken && emailOtp))}</Text>
                    <Text defaultValue={i18n.get("", "&nbsp;y al número celular&nbsp;")} />
                    <Text bold>{maskPhoneNumber(mobileNumber || (withEchangeToken && mobileNumberOtp))}</Text>
                </Box>
            )}
            <Box display="flex" fullWidth>
                <Field
                    idForm={FORM_ID}
                    autoComplete="off"
                    name="secondFactor"
                    component={PinInput}
                    maxLength={1}
                    labelNoMarginTop
                    noMarginBottom
                    type="number"
                    inputLenght={OTP_LENGTH}
                    autoFocus={isDesktop}
                    className="remove-flex"
                    credentialType={credentialType}
                    maxAttempts={maxAttempts}
                    attempts={attempts}
                    handleChangeProp={(value) => {
                        if (value) {
                            const submit = value?.length === OTP_LENGTH;
                            onValidSubmit({ submit });
                            if (submit) {
                                const valueTokenFormat = getSecondFactorFormat(credentialType, value);
                                onChangeToken(valueTokenFormat);
                            }
                        }
                    }}
                />
                {fetchingOTP || isSubmitting ? <Loader bgTransparent /> : <>{resendButton}</>}
            </Box>

            {startCountDown && (
                <Box display="flex" className={`mt-3 mb-4 ${isSubmitting && "hidden"}`} >
                    <Image
                        src="images/icons/info.svg"
                        wrapperClassName="fit-content-width align-self-center svg-color-login-i"
                    />
                    <Text
                        color="text-disabled-color"
                        size="5"
                        className="pl-2 pt-1 pb-2"
                        defaultValue="Su código temporal expirará en&nbsp;"
                    />
                    <CountdownCounter
                        total={RESEND_WAITING_TIME}
                        startCountDown={startCountDown}
                        setStartCountDown={setStartCountDown}
                        onFinish={() => {
                            setResendEnabled(true);
                        }}
                        className="pt-1 pb-2 color-text-disabled-color"
                        size="5"
                    />
                    <Text color="text-disabled-color" className="pt-1 pb-2" size="5" defaultValue="&nbsp;minutos" />
                </Box>
            )}
            {buttons && buttons}

            {isDesktop ? (
                <PopOver
                    image={popoverData.image}
                    content={
                        <>
                            <Box display="block" fullWidth>
                                <Text defaultValue={i18n.get("", popoverData.textRegular)} size="7" />
                                <Text bold defaultValue={i18n.get("", popoverData.textBold)} size="7" />
                                {popoverData.textSecondRegular && (
                                    <Text defaultValue={i18n.get("", popoverData.textSecondRegular)} size="7" />
                                )}
                            </Box>
                            {popoverData.textExtraInfo && (
                                <Box display="block" fullWidth className="mt-3">
                                    <Text
                                        bold
                                        size="small"
                                        color="strong-primary"
                                        defaultValue={i18n.get("", popoverData.textExtraInfo)}
                                    />
                                </Box>
                            )}
                        </>
                    }
                    position="top-left">
                    <Button
                        primaryLink
                        bsStyle="link"
                        defaultLabelText={i18n.get("login.step3.secondFactor.otp.help.text", popoverData.buttonTitle)}
                        className="px-0"
                    />
                </PopOver>
            ) : (
                <Button
                    primaryLink
                    bsStyle="link"
                    defaultLabelText={i18n.get("login.step3.secondFactor.otp.help.text", popoverData.buttonTitle)}
                    className="px-0"
                    onClick={() => {
                        goToLink("/info-otp");
                    }}
                />
            )}
        </Box>
    );

    const renderDesktop = () => renderCredentials();
    const renderMobile = () => renderCredentials();

    const loadingToken = () => (
        <div className="widget-preloader no-background min-height-2rem">
            <div>
                <span />
                <span />
                <span />
            </div>
        </div>
    );

    return (
        <>
            {isFetching && !isMobileNativeFunc() && loadingToken()}
            {!isMobileNativeFunc() && !isFetching && renderDesktop()}
            {isMobileNativeFunc() && renderMobile()}
        </>
    );
};

const mapStateToProps = (state) => ({
    tokenStatus: softTokenSelector.getStatusToken(state),
    isFetching: softTokenSelector.isFetching(state),
    email: sessionSelectors.getEmail(state),
    mobileNumber: sessionSelectors.getMobileNumber(state),
    isCodeSent: secondFactorSelectors.isCodeSent(state),
    attempts: secondFactorSelectors.getAttempts(state),
    emailOtp: secondFactorSelectors.getEmail(state),
    mobileNumberOtp: secondFactorSelectors.getMobileNumber(state),
    fetchingOTP: secondFactorSelectors.isFetchingOTP(state),
});

const mapDispatchToProps = (dispatch, { withEchangeToken }) => ({
    secondFactorVerificationCode: () => {
        dispatch(secondFactorActions.secondFactorVerificationCodeRequest({ exchangeToken: withEchangeToken }));
    },
    goToLink: (url) => {
        dispatch(routerActions.push(url));
    },
    showNotification: (message, level, scopes) => {
        dispatch(notificationActions.showNotification(message, level, scopes));
    },
});

OtpValidation.propTypes = {
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    fetchingOTP: bool,
    tokenStatus: string,
    onChangeToken: func,
    onValidSubmit: func,
    // propsForm: shape({}),
    isFetching: bool.isRequired,
    // relativeStyleError: bool,
    // warningIcon: string,
    credentialType: string,
    isCodeSent: string,
    withEchangeToken: string,
    secondFactorVerificationCode: func,
    showTitle: bool,
    emailOtp: string,
    mobileNumberOtp: string,
    goToLink: func,
    showNotification: func.isRequired,
    scopeToShow: string,
    buttons: node,
    isSubmitting: bool,
};

OtpValidation.defaultProps = {
    tokenStatus: undefined,
    onChangeToken: () => {},
    onValidSubmit: () => {},
    credentialType: null,
    isCodeSent: false,
    withEchangeToken: null,
    secondFactorVerificationCode: () => {},
    showTitle: true,
    emailOtp: undefined,
    mobileNumberOtp: undefined,
    scopeToShow: undefined,
    fetchingOTP: false,
    buttons: null,
    isSubmitting: false,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(resizableRoute(OtpValidation));
