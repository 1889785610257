import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Text from "pages/_components/Text";
import { bool, func, number, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux/actions";
import { actions as fingerprintActions, selectors as fingerprintSelectors } from "reducers/fingerprint";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { actions as softTokenActions, selectors as softTokenSelector } from "reducers/softToken";
// import { actions as authenticateHandlerActions, selectors as authenticateHandlerSelector } from "reducers/authenticateHandler";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import { isMobileNativeFunc } from "util/device";
import * as i18n from "util/i18n";
import { ENT000, hasRegisteredIdentity } from "util/softToken.util";
import SoftTokenTimer from "./_components/SoftTokenTimer";

const SoftToken = (props) => {
    const {
        isFetching,
        getTokenStatus,
        tokenStatus,
        isMobile,
        handleBack,
        getBiometricPrompt,
        validateFingerPrinter,
        deviceHasFingerPrinter,
        updateDeviceHasFingerPrinter,
        isSuccessfulVerification,
        pinCode,
        getDevicePin,
        savedPinCode,
        checkFingerprintSession,
        existFingerPrintSession,
    } = props;

    const [showInactiveModal, setShowInactiveModal] = useState(false);
    const [hasEntrustIdentity, setHasEntrustIdentity] = useState(false);
    const [showNoFingerprintModal, setShowNoFingerprintModal] = useState(false);

    const validateRegisteredIdentity = async () => {
        try {
            const hasRegisteredIdentityResponse = await hasRegisteredIdentity();

            if (!hasRegisteredIdentityResponse) {
                setHasEntrustIdentity(false);
                setShowInactiveModal(true);
                return;
            }

            const { code, data } = JSON.parse(hasRegisteredIdentityResponse);
            if (!code || code !== ENT000 || !data || data !== "true") {
                setHasEntrustIdentity(false);
                setShowInactiveModal(true);
                return;
            }

            setHasEntrustIdentity(true);
            setShowInactiveModal(false);
        } catch (error) {
            console.log("Ha ocurrido un error verificando la identidad entrust ", error);
        }
    };

    useEffect(() => {
        validateRegisteredIdentity();
    }, []);

    useEffect(() => {
        if (isMobileNativeFunc()) {
            checkFingerprintSession();
        }
    }, []);

    useEffect(() => {
        if (isMobileNativeFunc()) {
            getDevicePin();
        }
    }, []);

    const onSuccess = () => {};

    useEffect(() => {
        if (existFingerPrintSession) {
            validateFingerPrinter();

            console.log("SoftToken checkFingerprintSession existFingerPrintSession::::: ", existFingerPrintSession);
            if (deviceHasFingerPrinter) {
                getBiometricPrompt(onSuccess);
            }
        }
    }, [existFingerPrintSession, deviceHasFingerPrinter]);

    useEffect(() => {
        if (isMobileNativeFunc()) {
            console.log("PIN registrado: ", savedPinCode);
        }
    }, [savedPinCode]);

    const handleReturnModal = () => {
        handleBack();
        setShowInactiveModal(false);
        setShowNoFingerprintModal(false);
    };

    const loadingToken = () => (
        <div className="widget-preloader no-background min-height-2rem">
            <div>
                <span />
                <span />
                <span />
            </div>
        </div>
    );

    const renderHeader = () => (
        <>
            <Notification isMobile={isMobile} scopeToShow="softToken" />
            <Head isMobile={isMobile} onBack={handleBack} title="settings.softToken" textBack="softToken" />
        </>
    );

    const renderInactiveModal = () => (
        <TextAndButtonsModal
            modalShow={!hasEntrustIdentity || showInactiveModal}
            acceptFunction={handleReturnModal}
            confirmLabel="global.return"
            headingText={i18n.get("softToken.configuredDevices.inactive.title")}
            text={i18n.get("softToken.configuredDevices.inactive.text")}
        />
    );

    const renderNoFingerprintModal = () => (
        <TextAndButtonsModal
            modalShow={!existFingerPrintSession || showNoFingerprintModal}
            acceptFunction={handleReturnModal}
            confirmLabel="global.return"
            headingText={i18n.get("softToken.configuredFingerprint.inactive.title")}
            text={i18n.get("softToken.configuredFingerprint.inactive.text")}
        />
    );

    const renderToken = () => {
        if (isFetching) {
            return loadingToken();
        }

        console.log(
            "RenderToken Re5: ",
            deviceHasFingerPrinter,
            isSuccessfulVerification,
            existFingerPrintSession,
            savedPinCode,
            hasEntrustIdentity,
        );

        if (!existFingerPrintSession && !(savedPinCode && savedPinCode !== "")) {
            return renderNoFingerprintModal();
        }

        if (
            (deviceHasFingerPrinter && isSuccessfulVerification && existFingerPrintSession) ||
            (!(deviceHasFingerPrinter && existFingerPrintSession) && savedPinCode && savedPinCode !== "")
        ) {
            if (hasEntrustIdentity) {
                return <SoftTokenTimer />;
            }
            console.log("Modal inactivo");
            return (
                <TextAndButtonsModal
                    modalShow={!hasEntrustIdentity || showInactiveModal}
                    acceptFunction={handleReturnModal}
                    confirmLabel="global.return"
                    headingText={i18n.get("softToken.configuredDevices.inactive.title")}
                    text={i18n.get("softToken.configuredDevices.inactive.text")}
                />
            );
        }
        return loadingToken();
    };

    const renderContent = () => (
        <>
            <div className="above-the-fold">
                <Box
                    fullWidth
                    display="flex"
                    className="px-5 py-5 mb-7 border-radius-lg box-shadow-small background-white">
                    <Box fullWidth>
                        <Box>
                            <Text component="h2" defaultValue={i18n.get("softToken.generate.title")} bold />
                        </Box>
                        <Box>
                            <Text defaultValue={i18n.get("softToken.generate.text")} />
                        </Box>
                    </Box>
                    <Box display="flex softoken-svg">
                        <Image src="images/icons/settings_st_header.svg" color="transparent" />
                    </Box>
                </Box>
            </div>
            {renderToken()}
        </>
    );

    return (
        <>
            {renderHeader()}
            <MainContainer className="main-container rounded-border-top p-7 box-shadow-small background-white texture-header">
                {renderContent()}
            </MainContainer>
        </>
    );
};

SoftToken.propTypes = {
    isFetching: bool,
    handleBack: func.isRequired,
    getBiometricPrompt: func,
    validateFingerPrinter: func,
    tokenStatus: string,
    deviceHasFingerPrinter: bool,
    updateDeviceHasFingerPrinter: number,
    isSuccessfulVerification: bool,
    pinCode: string,
    getDevicePin: func,
    savedPinCode: string,
    checkFingerprintSession: func,
    existFingerPrintSession: bool,
};

SoftToken.defaultProps = {
    isFetching: false,
    activationStep: "",
    tokenStatus: "",
    deviceHasFingerPrinter: false,
    updateDeviceHasFingerPrinter: 0,
    isSuccessfulVerification: false,
    pinCode: "",
    savedPinCode: "",
    existFingerPrintSession: false,
};

const mapStateToProps = (state) => ({
    // tokenStatus: softTokenSelector.getStatusToken(state),
    deviceHasFingerPrinter: fingerprintSelectors.deviceHasFingerPrinter(state),
    updateDeviceHasFingerPrinter: fingerprintSelectors.updateDeviceHasFingerPrinter(state),
    isSuccessfulVerification: fingerprintSelectors.isSuccessfulVerification(state),
    savedPinCode: softTokenSelector.getDevicePin(state),
    existFingerPrintSession: loginSelectors.existFingerPrintSession(state),
    isFetching: loginSelectors.getFetching(state),
    // tokenStatus: authenticateHandlerSelector.getTokenStatus(state),
    // isFetching: authenticateHandlerSelector.isFetching(state),
    // serialToken: authenticateHandlerSelector.getSerialToken(state),
});

const mapDispatchToProps = (dispatch) => ({
    handleBack: () => {
        dispatch(fingerprintActions.fingerprintResetVerification());
        dispatch(push("/"));
    },
    getBiometricPrompt: (onSuccess) => {
        dispatch(fingerprintActions.fingerprintAnonymousVerification(onSuccess));
    },
    validateFingerPrinter: () => {
        dispatch(fingerprintActions.configurationDeviceHasFingerPrinterRequest());
    },
    getDevicePin: () => {
        dispatch(softTokenActions.getDevicePin());
    },
    checkFingerprintSession: () => {
        dispatch(loginActions.fingerprintCheckSession());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SoftToken);
