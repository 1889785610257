import classNames from "classnames";
import { Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import Row from "pages/_components/Row";
import Stepper from "pages/_layouts/DefaultStepperLayout/Stepper";
import Credential from "pages/forms/_components/credential/Credential";
import SecondFactor from "pages/secondFactor/SecondFactor";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as softTokenActions } from "reducers/softToken";
import * as i18n from "util/i18n";
import * as Yup from "yup";

const FORM_ID = "softToken.activate";
const STEP_ID = 3;
const SoftTokenConfirm = ({ steps, isDesktop, dispatch, handleSubmit }) => {
    const [canSubmit, setCanSubmit] = useState(false);

    const handleBack = () => {
        dispatch(softTokenActions.setActivationStep("securityMethod"));
        // dispatch(routerActions.goBack());
        dispatch(
            routerActions.push({
                pathname: "/activateSoftToken",
            }),
        );
    };

    const renderHeader = () => (
        <>
            <Notification scopeToShow="softTokenTermsAndConditions" />
            <Head onBack={handleBack} title="settings.softToken" textBack="softToken" />
        </>
    );

    const handleSubmitForm = (values, { setSubmitting, resetForm }, formikBag) => {
        setSubmitting(false);
        const { pinCode, secondFactor } = values;
        console.log("submitting!!!", pinCode);
        dispatch(
            softTokenActions.saveTokenRequest({
                redirectSuccess: "/desktop",
                scopeSuccess: ["desktop", "softTokenTermsAndConditions"],
                scopeError: ["desktop", "softTokenTermsAndConditions"],
                credentials: pinCode,
                secondFactor,
                onSuccess: handleSubmit,
                formikBag,
            }),
        );
        // handleSubmit();
        resetForm({
            pinCode: "",
        });
    };

    const validationSchema = Yup.object().shape({
        secondFactor: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
    });

    const renderForm = (propsForm) => (
        <Form className="display-flex flex-column justify-content-between full-height">
            <Box
                background="white"
                borderRadius="lg"
                className={classNames("mt-3 ", {
                    "p-7 box-shadow-small": isDesktop,
                    "pt-6": !isDesktop,
                })}>
                <Box>
                    <Stepper className="mb-6" stepsList={steps} currentStep={3} completed={2} showLabelMobile />
                </Box>
                <Row>
                    <Col xs={12} md={8} mdOffset={2} lg={8} lgOffset={2}>
                        <Box className="form-confirmation-info-wrapper security-verification">
                            <SecondFactor
                                onChangeToken={(tokenCode) => {
                                    if (propsForm?.setFieldValue) {
                                        propsForm.setFieldValue("secondFactor", tokenCode);
                                        propsForm.submitForm();
                                    }
                                }}
                                onValidSubmit={(validData) => {
                                    // console.log("validData: ",validData);
                                    setCanSubmit(validData.submit);
                                }}
                                idActivity="token.entrust.saveSoftToken"
                                isSubmitting={propsForm?.isSubmitting}
                            />
                        </Box>
                    </Col>
                </Row>
            </Box>
            <Row
                gapX="7"
                className={classNames("mt-7 mb-7", {
                    "d-flex flex-column-reverse": !isDesktop,
                })}>
                <Col xs={3} xsOffset={3}>
                    <Button
                        type="button"
                        bsStyle="outline"
                        label="global.cancel"
                        btnUppercase={false}
                        block
                        onClick={handleBack}
                    />
                </Col>
                <Col xs={3}>
                    <Button
                        type="submit"
                        bsStyle="primary"
                        label="global.continue"
                        btnUppercase={false}
                        // loading={isFetching}
                        loading={propsForm?.isSubmitting}
                        disabled={!(propsForm.isValid && propsForm.dirty) && !canSubmit}
                        block
                    />
                </Col>
            </Row>
        </Form>
    );
    return (
        <>
            {renderHeader()}
            <MainContainer
                className={classNames("main-container", {
                    "background-white": !isDesktop,
                })}>
                <Formik
                    initialValues={{ secondFactor: "" }}
                    onSubmit={handleSubmitForm}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize
                    validationSchema={validationSchema}>
                    {renderForm}
                </Formik>
            </MainContainer>
        </>
    );
};

SoftTokenConfirm.propTypes = {
    dispatch: func.isRequired,
    handleSubmit: func.isRequired,
    isDesktop: bool.isRequired,
    // isFetching: bool,
    credentials: shape({}),
    steps: arrayOf(shape({})).isRequired,
};

const mapStateToProps = (state) => ({
    // isFetching: selectorSoftToken.isFetching(state),
    /* credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(transactionsSelectors.getCredentialsGroups(state)), */
});

export default connect()(SoftTokenConfirm);
