import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import { bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actions as unlockUserActions, selectors } from "reducers/unlockUser";
import { selectors as sessionSelectors } from "reducers/session";
import classNames from "classnames";
import LoginWrapper from "pages/login/_components/LoginWrapper";
import * as i18n from "util/i18n";
import { Form, withFormik } from "formik";
import * as Yup from "yup";
import { compose } from "redux";
import BoxErrorNotification from "pages/_components/BoxErrorNotification";
import { push } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import SecondFactor from "pages/secondFactor/SecondFactor";
import HeaderWithoutSession from "pages/_components/HeaderWithoutSession";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";
import ExitModal from "pages/_components/modal/ExitModal";

const FORM_ID = "unlockUser.step2";

const UnlockUserStep2 = ({ dispatch, isMobile, isSubmitting, setFieldValue, exchangeToken, submitForm }) => {
    const [showExitModal, setShowExitModal] = useState(false);

    const [canSubmit, setCanSubmit] = useState(false);

    const handleBack = () => {
        dispatch(routerActions.goBack());
    };

    const goToLogin = () => {
        dispatch(push("/"));
    };

    const handlePreCancel = () => {
        setShowExitModal(true);
    };
    const handleAcceptModal = () => {
        goToLogin();
        setShowExitModal(false);
    };
    const handleHideModal = () => {
        setShowExitModal(false);
    };

    useEffect(() => {
        if (!exchangeToken) {
            dispatch(push("/unlockUser/step1"));
        }
    }, []);

    if (!exchangeToken) {
        return null;
    }

    return (
        <LoginWrapper>
            <>
                {!isMobile && <BoxErrorNotification isMobile={isMobile} scopeToShow="unlockUser" />}
                <HeaderWithoutSession labelKey="unlockUser.step1.header" handleOnBack={handleBack} showBack />
                <MainContainer>
                    <Form
                        className={classNames({
                            "pointer-events-none": isSubmitting,
                        })}
                        noValidate="novalidate">
                        <Box display="flex" column className="mb-2 mt-5">
                            <SecondFactor
                                onChangeToken={(tokenCode) => {
                                    if (setFieldValue) {
                                        setFieldValue("secondFactor", tokenCode);
                                        submitForm();
                                    }
                                }}
                                onValidSubmit={(validData) => {
                                    setCanSubmit(validData.submit);
                                }}
                                withEchangeToken={exchangeToken}
                                idActivity="session.unlockUser.step2"
                                showTitle={false}
                                isSubmitting={isSubmitting}
                            />

                            <Row
                                gapX="7"
                                className={classNames("mb-3 mt-6", {
                                    "d-flex flex-column": isMobile,
                                })}>
                                <Col xs={6}>
                                    <Button
                                        bsStyle="primary"
                                        label="global.continue"
                                        loading={isSubmitting}
                                        disabled={!canSubmit}
                                        block
                                        type="submit"
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Button
                                        bsStyle="outline"
                                        label="global.cancel"
                                        block
                                        type="button"
                                        onClick={handlePreCancel}
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </Form>
                </MainContainer>
                <ExitModal
                    modalShow={showExitModal}
                    acceptFunction={handleAcceptModal}
                    cancelFunction={handleHideModal}
                    headingText={i18n.get("confirm.exit.title")}
                    text={i18n.get("confirm.exit.info")}
                />
            </>
        </LoginWrapper>
    );
};

UnlockUserStep2.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    formData: shape({}),
    isActiveCorporate: bool.isRequired,
    isSubmitting: bool.isRequired,
};

UnlockUserStep2.defaultProps = {
    formData: {},
};

const mapStateToProps = (state) => ({
    fetching: selectors.getFetching(state),
    formData: selectors.getFormData(state),
    isActiveCorporate: sessionSelectors.isActiveCorporate(state),
    exchangeToken: selectors.getExchangeToken(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            secondFactor: "",
        }),
        validationSchema: ({}) =>
            Yup.object().shape({
                secondFactor: Yup.string().required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: ({ secondFactor }, formikBag) => {
            const {
                props: { exchangeToken },
            } = formikBag;
            formikBag.props.dispatch(unlockUserActions.unlockUserStep2(secondFactor, exchangeToken, formikBag));
        },
    }),
)(UnlockUserStep2);
