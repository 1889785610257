// Action types
export const types = {
    NOTIFICATIONS_CONFIGURATION_PRE: "settings/NOTIFICATIONS_CONFIGURATION_PRE",
    NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS: "settings/NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS",
    COMMUNICATION_TYPE_PRE: "settings/COMMUNICATION_TYPE_PRE",
    COMMUNICATION_TYPE_PRE_SUCCESS: "settings/COMMUNICATION_TYPE_PRE_SUCCESS",
    MODIFY_NOTIFICATION_CONFIGURATIONS: "settings/MODIFY_NOTIFICATION_CONFIGURATIONS",
    MODIFY_PAPERLESS_CONFIGURATIONS: "settings/MODIFY_PAPERLESS_CONFIGURATIONS",

    LIST_ENVIRONMENTS_REQUEST: "settings/LIST_ENVIRONMENTS_REQUEST",
    LIST_ENVIRONMENTS_SUCCESS: "settings/LIST_ENVIRONMENTS_SUCCESS",
    LIST_ENVIRONMENTS_ERROR: "settings/LIST_ENVIRONMENTS_ERROR",

    CHANGE_LANGUAGE_PRE: "settings/CHANGE_LANGUAGE_PRE",
    CHANGE_LANGUAGE_PRE_SUCCESS: "settings/CHANGE_LANGUAGE_PRE_SUCCESS",
    CHANGE_LANGUAGE_PRE_ERROR: "settings/CHANGE_LANGUAGE_PRE_ERROR",
    CHANGE_DEFAULT_ENVIRONMENT_PRE: "settings/CHANGE_DEFAULT_ENVIRONMENT_PRE",
    CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS: "settings/CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS",
    CHANGE_DEFAULT_ENVIRONMENT_PRE_ERROR: "settings/CHANGE_DEFAULT_ENVIRONMENT_PRE_ERROR",
    CHANGE_LANGUAGE: "settings/CHANGE_LANGUAGE",

    GET_USER_DATA_FAILURE: "settings/GET_USER_DATA_FAILURE",
    GET_USER_DATA_SUCCESS: "settings/GET_USER_DATA_SUCCESS",
    GET_USER_DATA: "settings/GET_USER_DATA",

    MAIL_SEND_CODE: "settings/MAIL_SEND_CODE",

    MAIL_UPDATE: "settings/MAIL_UPDATE",
    MAIL_UPDATE_FAILURE_REQUIRE_CAPTCHA: "MAIL_UPDATE_FAILURE_REQUIRE_CAPTCHA",

    MOBILEPHONE_SEND_CODE_SUCCESS: "settings/MOBILEPHONE_SEND_CODE_SUCCESS",
    MOBILEPHONE_SEND_CODE_FAILURE: "settings/MOBILEPHONE_SEND_CODE_FAILURE",
    MOBILEPHONE_SEND_CODE: "settings/MOBILEPHONE_SEND_CODE",

    MOBILEPHONE_UPDATE: "settings/MOBILEPHONE_UPDATE",
    MOBILEPHONE_UPDATE_SUCCESS: "settings/MOBILEPHONE_UPDATE_SUCCESS",
    MOBILEPHONE_UPDATE_FAILURE: "settings/MOBILEPHONE_UPDATE_FAILURE",

    CHANGE_PASSWORD_REQUEST: "settings/CHANGE_PASSWORD_REQUEST",
    CHANGE_PASSWORD_SUCCESS: "settings/CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILURE: "settings/CHANGE_PASSWORD_FAILURE",

    CHANGE_PASSWORD_CONFIRM_REQUEST: "settings/CHANGE_PASSWORD_CONFIRM_REQUEST",
    CHANGE_PASSWORD_CONFIRM_SUCCESS: "settings/CHANGE_PASSWORD_CONFIRM_SUCCESS",
    CHANGE_PASSWORD_CONFIRM_FAILURE: "settings/CHANGE_PASSWORD_CONFIRM_FAILURE",

    CHANGE_PASSWORD_RESEND_OTP_REQUEST: "settings/CHANGE_PASSWORD_RESEND_OTP_REQUEST",

    CHANGE_SECURITY_SEAL_PRE: "settings/CHANGE_SECURITY_SEAL_PRE",
    CHANGE_SECURITY_SEAL_PRE_SUCCESS: "settings/CHANGE_SECURITY_SEAL_PRE_SUCCESS",
    CHANGE_SECURITY_SEAL_CONFIRMATION_PRE: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_PRE",
    CHANGE_SECURITY_SEAL_CONFIRMATION: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION",
    CHANGE_SECURITY_SEAL_CONFIRMATION_BACK: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_BACK",
    CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR",
    CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS: "settings/CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS",

    LOAD_DOCUMENTS_REQUEST: "settings/LOAD_DOCUMENTS_REQUEST",
    LOAD_DOCUMENTS_SUCCESS: "settings/LOAD_DOCUMENTS_SUCCESS",
    DOWNLOAD_DOCUMENT_REQUEST: "settings/DOWNLOAD_DOCUMENT_REQUEST",

    FETCH_PRODUCTS_LIST: "settings/FETCH_PRODUCTS_LIST",
    FETCH_PRODUCTS_LIST_SUCCESS: "settings/FETCH_PRODUCTS_LIST_SUCCESS",
    UPDATE_PRODUCT_LIST: "settings/UPDATE_PRODUCT_LIST",
    CLEAN_ERRORS: "settings/CLEAN_ERRORS",

    SET_ALL_PAPERLESS_REQ: "settings/SET_ALL_PAPERLESS_REQ",
    SET_ALL_PAPERLESS_SUCCESS: "settings/SET_ALL_PAPERLESS_SUCCESS",
    SET_ALL_PAPERLESS_ERROR: "settings/SET_ALL_PAPERLESS_ERROR",

    GET_ADDRESSES_INFORMATION_REQ: "settings/GET_ADDRESSES_INFORMATION_REQ",
    GET_ADDRESSES_INFORMATION_SUCCESS: "settings/GET_ADDRESSES_INFORMATION_SUCCESS",
    GET_ADDRESSES_INFORMATION_FAILURE: "settings/GET_ADDRESSES_INFORMATION_FAILURE",

    SET_ADDRESSES_INFORMATION_REQ: "settings/SET_ADDRESSES_INFORMATION_REQ",
    UPDATE_PERMISSION_LOCAL: "settings/UPDATE_PERMISSION_LOCAL",
    PHONE_CAN_BE_USED_IN_WALLY: "settings/PHONE_CAN_BE_USED_IN_WALLY",

    GET_SECURITY_QUESTIONS_REQ: "settings/GET_SECURITY_QUESTIONS_REQ",
    GET_SECURITY_QUESTIONS_SUCCESS: "settings/GET_SECURITY_QUESTIONS_SUCCESS",
    GET_SECURITY_QUESTIONS_FAILURE: "settings/GET_SECURITY_QUESTIONS_FAILURE",

    REGISTER_SECURITY_QUESTIONS_REQ: "settings/REGISTER_SECURITY_QUESTIONS_REQ",
    REGISTER_SECURITY_QUESTIONS_SUCCESS: "settings/REGISTER_SECURITY_QUESTIONS_SUCCESS",
    REGISTER_SECURITY_QUESTIONS_FAILURE: "settings/REGISTER_SECURITY_QUESTIONS_FAILURE",

    UPDATE_SECURITY_QUESTIONS_REQ: "settings/UPDATE_SECURITY_QUESTIONS_REQ",
    UPDATE_SECURITY_QUESTIONS_SUCCESS: "settings/UPDATE_SECURITY_QUESTIONS_SUCCESS",
    UPDATE_SECURITY_QUESTIONS_FAILURE: "settings/UPDATE_SECURITY_QUESTIONS_FAILURE",
};

// Initial state
export const INITIAL_STATE = {
    documents: [],
    communicationTypes: null,
    communicationTransportsConfigurations: null,
    securitySeals: {},
    selectedSecuritySealId: null,
    supportedLanguages: null,
    userData: null,
    fetching: false,
    errors: null,
    productsList: null,
    address: {},
    mailingAddress: {},
    countries: [],
    showCaptcha: false,
    phoneCanBeUsedInWally: false,
    showSettingsExitModal: false,
    finalPassword: "",
    contact: "",
    operationType: "",
    otpMethod: "",
    enabledUpdate: true,
    hasRegisteredQuestions: false,
    environments: [],
};

// Reducer
export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        // Address information
        case types.GET_ADDRESSES_INFORMATION_REQ:
            return {
                ...state,
                address: {},
                mailingAddress: {},
                countries: [],
                fetching: true,
            };
        case types.GET_ADDRESSES_INFORMATION_FAILURE:
        case types.GET_ADDRESSES_INFORMATION_SUCCESS:
            return {
                ...state,
                address: action.address,
                mailingAddress: action.mailingAddress,
                countries: action.countries,
                fetching: false,
            };
        // Set all paperless action
        case types.SET_ALL_PAPERLESS_REQ:
            return { ...state, fetching: true, productsList: null };
        case types.SET_ALL_PAPERLESS_SUCCESS:
            return { ...state };
        case types.SET_ALL_PAPERLESS_ERROR:
            return { ...state, fetching: false };
        // Change language actions
        case types.CHANGE_LANGUAGE_PRE:
            return { ...state, fetching: true };
        case types.CHANGE_LANGUAGE_PRE_SUCCESS:
            return { ...state, fetching: false, supportedLanguages: action.supportedLanguages };
        case types.CHANGE_LANGUAGE_PRE_ERROR:
            return { ...state, fetching: false };
        // Change default environment actions
        case types.CHANGE_DEFAULT_ENVIRONMENT_PRE:
            return { ...state, fetching: true };
        case types.CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS:
            return { ...state, fetching: false };
        case types.CHANGE_DEFAULT_ENVIRONMENT_PRE_ERROR:
            return { ...state, fetching: false };
        case types.CHANGE_LANGUAGE:
            return { ...state, fetching: true };
        // Change notifications configuration actions
        case types.NOTIFICATIONS_CONFIGURATION_PRE:
            return { ...state, fetching: true, communicationTypes: null };
        case types.NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS:
            return { ...state, fetching: false, communicationTypes: action.communicationTypes };
        case types.COMMUNICATION_TYPE_PRE:
            return { ...state, fetching: true, communicationTransportsConfigurations: null };
        case types.COMMUNICATION_TYPE_PRE_SUCCESS:
            return {
                ...state,
                fetching: false,
                communicationTransportsConfigurations: action.communicationTransportsConfigurations,
            };
        case types.MODIFY_NOTIFICATION_CONFIGURATIONS:
            return {
                ...state,
                communicationTransportsConfigurations: {
                    ...state.communicationTransportsConfigurations,
                    [action.transportConfiguration]: action.subscribed,
                },
                notificationType: action.notificationType,
            };
        // obtain user data actions
        case types.GET_USER_DATA:
            return {
                ...state,
                fetching: true,
            };
        case types.GET_USER_DATA_SUCCESS:
            return {
                ...state,
                userData: action.data,
                fetching: false,
            };

        // change security seal actions
        case types.CHANGE_SECURITY_SEAL_PRE:
            return { ...state, fetching: true, selectedSecuritySealId: null };
        case types.CHANGE_SECURITY_SEAL_PRE_SUCCESS:
            return { ...state, fetching: false, securitySeals: action.securitySeals };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE:
            return { ...state, selectedSecuritySealId: action.selectedSecuritySealId };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION:
            return { ...state, errors: null };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK:
            return { ...state, selectedSecuritySealId: null, errors: null };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR:
            return { ...state, errors: action.errors };
        case types.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS:
            return { ...state, errors: null };
        case types.LOAD_DOCUMENTS_REQUEST:
            return { ...state, fetching: true };
        case types.LOAD_DOCUMENTS_SUCCESS:
            return { ...state, fetching: false, documents: action.documents };
        // fetch product list
        case types.FETCH_PRODUCTS_LIST:
            return { ...state, fetching: true };
        case types.FETCH_PRODUCTS_LIST_SUCCESS:
            return { ...state, fetching: false };
        case types.UPDATE_PRODUCT_LIST:
            return { ...state, productsList: action.productsList };
        // general actions
        case types.CLEAN_ERRORS:
            return { ...state, errors: null };
        case types.MAIL_UPDATE_FAILURE_REQUIRE_CAPTCHA:
            return { ...state, showCaptcha: true };
        case types.MAIL_SEND_CODE:
            return { ...state, showCaptcha: false };
        case types.PHONE_CAN_BE_USED_IN_WALLY:
            return { ...state, phoneCanBeUsedInWally: action.phoneCanBeUsedInWally };
        case types.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                finalPassword: action.finalPassword,
                operationType: action.operationType,
                contact: action.contact,
                otpMethod: action.otpMethod,
            };
        case types.GET_SECURITY_QUESTIONS_REQ:
            return {
                ...state,
                fetching: true,
            };
        case types.GET_SECURITY_QUESTIONS_SUCCESS:
            return {
                ...state,
                securityQuestions: action.securityQuestions,
                enabledUpdate: action.enabledUpdate,
                hasRegisteredQuestions: action.hasRegisteredQuestions,
                fetching: false,
            };
        case types.GET_SECURITY_QUESTIONS_FAILURE:
            return {
                ...state,
                securityQuestions: null,
                fetching: false,
            };
        case types.REGISTER_SECURITY_QUESTIONS_REQ:
        case types.UPDATE_SECURITY_QUESTIONS_REQ:
            return {
                ...state,
                fetching: true,
            };
        case types.REGISTER_SECURITY_QUESTIONS_SUCCESS:
        case types.REGISTER_SECURITY_QUESTIONS_FAILURE:
        case types.UPDATE_SECURITY_QUESTIONS_SUCCESS:
        case types.UPDATE_SECURITY_QUESTIONS_FAILURE:
            return {
                ...state,
                fetching: false,
            };
        case types.LIST_ENVIRONMENTS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.LIST_ENVIRONMENTS_SUCCESS:
            return {
                ...state,
                fetching: false,
                environments: action.environments,
            };
        case types.LIST_ENVIRONMENTS_ERROR:
            return {
                ...state,
                fetching: false,
                environments: [],
            };
        default:
            return state;
    }
};

// Action creators
export const actions = {
    updateProductList: (productsList) => ({
        type: types.UPDATE_PRODUCT_LIST,
        productsList,
    }),
    setAllPaperlessValue: (paperless) => ({
        type: types.SET_ALL_PAPERLESS_REQ,
        paperless,
    }),
    // language actions
    changeLanguage: (language, changeUserLanguage = true) => ({
        type: types.CHANGE_LANGUAGE_PRE,
        language,
        changeUserLanguage,
    }),
    changeDefaultEnvironment: (idEnvironment) => ({
        type: types.CHANGE_DEFAULT_ENVIRONMENT_PRE,
        idEnvironment,
    }),
    notificationsConfigurationPre: () => ({
        type: types.NOTIFICATIONS_CONFIGURATION_PRE,
    }),
    communicationTypePre: (communicationType) => ({
        type: types.COMMUNICATION_TYPE_PRE,
        communicationType,
    }),
    modifyNotificationConfigurations: (notificationType, transport, subscribed, transportConfiguration) => ({
        type: types.MODIFY_NOTIFICATION_CONFIGURATIONS,
        notificationType,
        transport,
        subscribed,
        transportConfiguration,
    }),
    modifyPaperlessConfigurations: (idProduct, paperless) => ({
        type: types.MODIFY_PAPERLESS_CONFIGURATIONS,
        idProduct,
        paperless,
    }),
    fetchProductsList: () => ({ type: types.FETCH_PRODUCTS_LIST }),

    // user data actions
    getUserData: (validateWally) => ({
        type: types.GET_USER_DATA,
        validateWally,
    }),
    // send mail code actions
    sendMailCode: (mail, otp, formikBag) => ({
        type: types.MAIL_SEND_CODE,
        mail,
        otp,
        formikBag,
    }),
    // updating mail actions (needs mail code)
    updateMail: (mailCode, captcha, formikBag) => ({
        type: types.MAIL_UPDATE,
        mailCode,
        captcha,
        formikBag,
    }),
    // send mobile phone code actions
    sendMobilePhoneCode: (mobilePhone, otp, formikBag) => ({
        type: types.MOBILEPHONE_SEND_CODE,
        mobilePhone,
        otp,
        formikBag,
    }),
    // updating mobile phone actions (needs mobile phone code)
    updateMobilePhone: (mobilePhoneCode, formikBag) => ({
        type: types.MOBILEPHONE_UPDATE,
        mobilePhoneCode,
        formikBag,
    }),
    changePassword: (password, newPassword, newPasswordConfirmation, formikBag) => ({
        type: types.CHANGE_PASSWORD_REQUEST,
        password,
        newPassword,
        newPasswordConfirmation,
        formikBag,
    }),
    changePasswordConfirm: (secondFactor, formikBag) => ({
        type: types.CHANGE_PASSWORD_CONFIRM_REQUEST,
        secondFactor,
        formikBag,
    }),
    changePasswordResendOtp: () => ({
        type: types.CHANGE_PASSWORD_RESEND_OTP_REQUEST,
    }),
    setAddressesInformation: (address, mailingAddress, otp, formikBag) => ({
        type: types.SET_ADDRESSES_INFORMATION_REQ,
        address,
        mailingAddress,
        otp,
        formikBag,
    }),

    changeSecuritySealPre: () => ({
        type: types.CHANGE_SECURITY_SEAL_PRE,
    }),
    changeSecuritySealConfirmationPre: (selectedSecuritySealId) => ({
        type: types.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE,
        selectedSecuritySealId,
    }),
    changeSecuritySealConfirmationBack: () => ({
        type: types.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK,
    }),
    changeSecuritySealConfirmation: (selectedSecuritySealId, otp, formikBag) => ({
        type: types.CHANGE_SECURITY_SEAL_CONFIRMATION,
        securitySeal: selectedSecuritySealId,
        otp,
        formikBag,
    }),
    loadDocumentsRequest: () => ({
        type: types.LOAD_DOCUMENTS_REQUEST,
    }),
    downloadDocumentRequest: (fileNameKey) => ({
        type: types.DOWNLOAD_DOCUMENT_REQUEST,
        fileNameKey,
    }),
    // general actions
    cleanErrors: () => ({
        type: types.CLEAN_ERRORS,
    }),

    // Address information
    getAddressesInformation: () => ({
        type: types.GET_ADDRESSES_INFORMATION_REQ,
    }),
    updatePermissionLocal: (permissions) => ({
        type: types.UPDATE_PERMISSION_LOCAL,
        permissions,
    }),
    getSecurityQuestions: () => ({
        type: types.GET_SECURITY_QUESTIONS_REQ,
    }),
    registerSecurityQuestions: (questions, secondFactor, formikBag, onSuccess) => ({
        type: types.REGISTER_SECURITY_QUESTIONS_REQ,
        questions,
        secondFactor,
        formikBag,
        onSuccess,
    }),
    updateSecurityQuestions: (questions, secondFactor, formikBag, onSuccess) => ({
        type: types.UPDATE_SECURITY_QUESTIONS_REQ,
        questions,
        secondFactor,
        formikBag,
        onSuccess,
    }),

    getEnvironmentsRequest: () => ({
        type: types.LIST_ENVIRONMENTS_REQUEST,
    }),
};

// Selectors
export const selectors = {
    getSecuritySeals: (state) => state.settings.securitySeals,
    getSelectedSecuritySeal: (state) => {
        if (state.settings.selectedSecuritySealId) {
            return state.settings.securitySeals[state.settings.selectedSecuritySealId];
        }
        return null;
    },
    getSelectedSecuritySealId: (state) => state.settings.selectedSecuritySealId,
    getSupportedLanguages: (state) => state.i18n.supportedLanguages,
    getCommunicationTypes: (state) => state.settings.communicationTypes,
    getCommunicationTransportsConfigurations: (state) => state.settings.communicationTransportsConfigurations,
    getCommunicationTransportsConfigurationsKeys: (state) => {
        if (!state.settings.communicationTransportsConfigurations) {
            return [];
        }
        const keys = Object.keys(state.settings.communicationTransportsConfigurations);
        keys.sort();
        return keys;
    },
    getErrors: (state) => state.settings.errors,
    isFetching: (state) => state.settings.fetching,
    getEmail: (state) => (state.settings.userData && state.settings.userData.mail ? state.settings.userData.mail : ""),
    getEmailConfirm: (state) => state.settings.confirmEmailCode,
    getMobilePhone: (state) => {
        if (state.settings.userData && state.settings.userData.mobilePhone) {
            return state.settings.userData.mobilePhone;
        }
        return null;
    },
    getDocuments: (state) => state.settings.documents,
    getProductsLists: (state) => state.settings.productsList,
    getAddress: (state) => state.settings.address,
    getMailingAdress: (state) => state.settings.mailingAddress,
    getCountries: (state) => state.settings.countries,
    getShowCaptcha: (state) => state.settings.showCaptcha,
    getUserData: (state) => state?.settings?.userData,
    getMobilePhoneMask: (state) => state?.settings?.userData?.mobilePhoneMask || "",
    getEmailMask: (state) => state?.settings?.userData?.emailMask || "",
    phoneCanBeUsedInWally: ({ settings }) => settings?.phoneCanBeUsedInWally,
    getShowSettingsExitModal: ({ settings }) => settings?.showSettingsExitModal,
    getFinalPassword: ({ settings }) => settings?.finalPassword,
    getOperationType: ({ settings }) => settings?.operationType,
    getOtpMethod: ({ settings }) => settings?.otpMethod,
    getContact: ({ settings }) => settings?.contact,
    getSecurityQuestions: (state) => state.settings.securityQuestions,
    getHasRegisteredQuestions: (state) => state.settings.hasRegisteredQuestions,
    isEnabledUpdate: (state) => state.settings.enabledUpdate,
    getEnvironments: (state) => state.settings.environments,
};
