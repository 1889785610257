import Box from "pages/_components/Box";
import { resizableRoute } from "pages/_components/Resizable";
import { arrayOf, bool, func, node, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectors as softTokenSelector } from "reducers/softToken";
import { actions as secondFactorActions, selectors as secondFactorSelectors } from "reducers/secondFactor";
import { actions as notificationActions } from "reducers/notification";
import * as config from "util/config";
import * as i18n from "util/i18n";
import { Field } from "formik";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import QuestionField from "pages/_components/fields/QuestionField";
import Notification from "pages/_components/Notification";

const FORM_ID = "secondFactor.credential";
const scopeToShowLocal = "externalLayout";

const SecurityQuestionsValidation = ({
    isMobile,
    isDesktop,
    credentialType,
    credentialMethod,
    questions,
    dispatch,
    tokenStatus,
    onChangeToken,
    isFetching,
    isFetchingQuestion,
    onValidSubmit,
    withEchangeToken,
    secondFactorVerificationQuestions,
    scopeToShow,
    error,
    form,
    attempts,
    showNotification,
    showTitle,
    buttons,
    attemptsChangeQuestion,
}) => {
    const [step, setStep] = useState("loading");

    const maxAttempts = config.getInteger("core.attempts.secondFactor.maxAttempts");
    const maxReloads = config.getInteger("core.attempts.secondFactor.reloadQuestions.maxAttempts");
    const [attemptsReload, setAttemptsReload] = useState(0);

    useEffect(() => {
        secondFactorVerificationQuestions(false);
    }, [credentialType, credentialMethod]);

    useEffect(() => {
        if (questions) {
            setStep("questions");
            if(attemptsChangeQuestion){
                setAttemptsReload(attemptsChangeQuestion);
            }  
        }
    }, [questions]);

    useEffect(() => {
        if (error) {
            setStep("questions");
        }
    }, [error]);

    const renderCredentials = () => (
        <>
            <Notification scopeToShow={scopeToShowLocal} />

            <Box display="flex" className="security-question-label second-factor-validation" column fullWidth>
                {!scopeToShowLocal && (
                    <Box className="title-with-icon">
                        <Image src="images/iconTitles/security_device.svg" />
                        <Text component="h3" defaultValue={i18n.get("", "Verificación")} />
                    </Box>
                )}

                {showTitle && (
                    <Text
                        size={isMobile ? "4" : "3"}
                        component="h2"
                        color="heading"
                        bold
                        labelKey="secondFactor.credential.question.label"
                    />
                )}

                {questions && questions.length > 0 ? (
                    <Box display="flex" fullWidth>
                        <Field
                            idForm={FORM_ID}
                            component={QuestionField}
                            questions={questions}
                            name="secondFactor"
                            reducedColumnGap
                            dispatch={dispatch}
                            maxReloads={maxReloads}
                            maxAttempts={maxAttempts}
                            attemptsReload={attemptsReload}
                            attempts={attempts}
                            scopeToShow={scopeToShowLocal}
                            {...(isMobile && { idNumberStyle2: true })}
                            credentialType={credentialType}
                            changeQuestionProp={() => {                                  
                                // alcanzo max - se deshabilita
                                if (attemptsReload === maxReloads) {
                                    showNotification(
                                        i18n.get("secondFactor.credential.question.attempt.change.message"),
                                        "warning",
                                        [scopeToShow || scopeToShowLocal],
                                    );
                                } else {
                                    const count = attemptsReload + 1;
                                    // cuando resta 1 intento muestra un mensaje
                                    if (count + 1 === maxReloads) {
                                        showNotification(
                                            i18n.get("secondFactor.credential.question.attempt.next.change.message"),
                                            "warning",
                                            [scopeToShow || scopeToShowLocal],
                                        );
                                    }
                                    // al llegar al max muestra mensaje ultimo intento
                                    if (count === maxReloads) {
                                        showNotification(
                                            i18n.get("secondFactor.credential.question.attempt.change.message"),
                                            "warning",
                                            [scopeToShow || scopeToShowLocal],
                                        );
                                    }
                                    setAttemptsReload(count);
                                    secondFactorVerificationQuestions(true);
                                }
                            }}
                            handleAnswerChangeProp={(value) => {
                                if (value) {
                                    const submit = value?.length > 2;
                                    onValidSubmit({ submit });
                                }
                            }}
                        />
                    </Box>
                ) : (
                    error && <p>{error}</p>
                )}
                {isFetchingQuestion && <>{loadingToken()}</>}

                {buttons && buttons}
            </Box>
        </>
    );

    const loadingToken = () => (
        <div className="widget-preloader no-background min-height-2rem">
            <div>
                <span />
                <span />
                <span />
            </div>
        </div>
    );

    const renderContent = () => {
        switch (step) {
            case "loading":
                return loadingToken();
            case "questions":
                return renderCredentials();
            default:
                return (
                    <>
                        <div />
                    </>
                );
        }
    };

    return renderContent();
};

const mapStateToProps = (state) => ({
    isFetching: softTokenSelector.isFetching(state),
    isFetchingQuestion: secondFactorSelectors.isFetching(state),
    questions: secondFactorSelectors.getQuestions(state),
    error: secondFactorSelectors.getError(state),
    attempts: secondFactorSelectors.getAttempts(state),
    attemptsChangeQuestion: secondFactorSelectors.getAttemptsChangeQuestion(state),
});

const mapDispatchToProps = (dispatch, { withEchangeToken, scopeToShow }) => ({
    secondFactorVerificationQuestions: (isChange) => {
        const scope = scopeToShow || scopeToShowLocal;
        dispatch(
            secondFactorActions.secondFactorVerificationQuestionsRequest(
                {
                    exchangeToken: withEchangeToken,
                    scopeToShow: scope,
                },
                isChange,
            ),
        );
    },
    showNotification: (message, level, scopes) => {
        dispatch(notificationActions.showNotification(message, level, scopes));
    },
});

SecurityQuestionsValidation.propTypes = {
    form: shape({}).isRequired,
    dispatch: func.isRequired,
    isDesktop: bool.isRequired,
    onChangeToken: func,
    onValidSubmit: func,
    propsForm: shape({}),
    isFetching: bool.isRequired,
    relativeStyleError: bool,
    warningIcon: string,
    credentialType: string,
    credentialTypeMethod: string,
    withEchangeToken: string,
    secondFactorVerificationQuestions: func,
    showNotification: func,
    questions: arrayOf(string).isRequired,
    scopeToShow: string,
    showTitle: bool,
    buttons: node,
};

SecurityQuestionsValidation.defaultProps = {
    onChangeToken: () => {},
    onValidSubmit: () => {},
    propsForm: {},
    relativeStyleError: true,
    warningIcon: "tooltip.svg",
    credentialType: null,
    credentialTypeMethod: null,
    withEchangeToken: null,
    secondFactorVerificationQuestions: () => {},
    questions: undefined,
    scopeToShow: null,
    showTitle: true,
    buttons: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(resizableRoute(SecurityQuestionsValidation));
