import * as config from "util/config";

export const validatePassword = (values) => {
    const minLength = config.getInteger("core.password.minLength");
    const maxLength = config.getInteger("core.password.maxLength");
    const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
    const hasSpecialChar = specialCharRegex.test(values.newPassword);
    const numericCharRegex = /[0-9]/;
    const hasNumericChar = numericCharRegex.test(values.newPassword);
    const uppercaseRegex = /[A-Z]/;
    const hasUppercase = uppercaseRegex.test(values.newPassword);
    const lowercaseRegex = /[a-z]/;
    const hasLowercase = lowercaseRegex.test(values.newPassword);
    const isErrorForm =
        values.newPassword.length >= minLength &&
        values.newPassword.length < maxLength &&
        hasSpecialChar &&
        hasNumericChar &&
        hasUppercase &&
        hasLowercase;
    return { isErrorForm, hasSpecialChar, hasNumericChar, hasUppercase, hasLowercase, maxLength, minLength };
};

export default {};
