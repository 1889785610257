import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import RadioButton from "pages/_components/fields/Radio";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import { arrayOf, bool, func, number, oneOfType, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as settingsActions, selectors as settingsSelectors } from "reducers/settings";
import * as i18n from "util/i18n";
import * as config from "util/config";

const ChangeDefaultEnvironment = ({ environments, currentDefaultEnvironment, isFetching, dispatch }) => {
    const [selectedEnvironment, setSelectedEnironment] = useState(null);
    const [showSettingsExitModal, setShowSettingsExitModal] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        dispatch(settingsActions.getEnvironmentsRequest());
        setRowsPerPage(config.getInteger("table.rowsPerPage", 10));
    }, []);

    useEffect(() => {
        if (selectedEnvironment === null && currentDefaultEnvironment !== null) {
            setSelectedEnironment(currentDefaultEnvironment);
        }
    }, [selectedEnvironment, currentDefaultEnvironment]);

    const handleClickOption = (envId) => {
        setSelectedEnironment(envId);
    };

    const handleClickCancel = () => {
        setShowSettingsExitModal(true);
    };

    const handleClickSubmit = () => {
        dispatch(settingsActions.changeDefaultEnvironment(selectedEnvironment));
    };

    // const handleBack = () => {
    //     dispatch(goBack());
    // };

    const handleGoSettings = () => {
        dispatch(push("/settings"));
    };

    const environmentItem = ({ environments: data, selected }) => (
        <>
            {data?.map((envData) => (
                <Box
                    key={envData.idEnvironment}
                    fullWidth
                    display="flex"
                    alignX="space-between"
                    className={classNames("box-shadow-small cursor-pointer  border-radius-lg", {
                        "p-7 mb-7": isDesktop,
                        "px-5 py-4 mb-6": !isDesktop,
                    })}
                    onClick={() => handleClickOption(envData.idEnvironment)}>
                    {isDesktop ? (
                        <>
                            <Box fullWidth display="flex" column className="pr-5 line-height-15">
                                <Text defaultValue={envData.name} bold />
                                <Text size="6" labelKey="settings.environments.ruc" ruc={envData.productGroupId} />
                            </Box>
                            <Box fullWidth display="flex" column className="pr-5 line-height-15">
                                <Text labelKey="settings.environments.creationDate" />
                                <FormattedDate bold color="heading" date={envData.creationDate} />
                            </Box>
                            <Box fitWidth display="flex" alignY="center">
                                <Text
                                    color={envData.idEnvironment !== selected ? "inverse" : "primary"}
                                    noWrap
                                    labelKey="settings.environments.frequent"
                                    className="mr-5"
                                    bold
                                />
                                {!!envData.idEnvironment && (
                                    <RadioButton
                                        id={envData.idEnvironment}
                                        key={envData.idEnvironment}
                                        name="new_env"
                                        value={envData.idEnvironment}
                                        checked={envData.idEnvironment === selected}
                                        className="gap-0"
                                    />
                                )}
                            </Box>
                        </>
                    ) : (
                        <Box fullWidth display="flex" className="line-height-15">
                            <Box fullWidth>
                                <Box>
                                    <Text size="3" defaultValue={envData.name} bold />
                                </Box>
                                <Box>
                                    <Text size="6" labelKey="settings.environments.ruc" ruc={envData.productGroupId} />
                                </Box>
                                <Box>
                                    <Text labelKey="settings.environments.creationDate" />
                                    :&nbsp;
                                    <FormattedDate bold color="heading" date={envData.creationDate} />
                                </Box>
                                <Box>
                                    <Text
                                        color={envData.idEnvironment !== selected ? "inverse" : "primary"}
                                        noWrap
                                        labelKey="settings.environments.frequent"
                                        className="mr-5"
                                        bold
                                    />
                                </Box>
                            </Box>
                            <Box display="flex" alignY="center">
                                {!!envData.idEnvironment && (
                                    <RadioButton
                                        id={envData.idEnvironment}
                                        key={envData.idEnvironment}
                                        name="new_env"
                                        value={envData.idEnvironment}
                                        checked={envData.idEnvironment === selected}
                                        className="gap-0"
                                    />
                                )}
                            </Box>
                        </Box>
                    )}
                </Box>
            ))}
        </>
    );

    return (
        <>
            <Notification scopeToShow="settings" />
            <Head title="settings.environments.title" onBack={handleGoSettings} textBack="settings.returnToAdmin" />
            <MainContainer
                showLoader={isFetching}
                className={classNames("main-container", {
                    "box-shadow-small background-white rounded-border-top": !isDesktop,
                })}>
                <Box
                    borderRadius="lg"
                    background="white"
                    className={classNames("mt-3", {
                        "box-shadow-small p-7": isDesktop,
                    })}>
                    <div className="above-the-fold">
                        <Box fullWidth display="flex" className="px-7 py-7 mb-7">
                            <Box fitWidth className="mr-3 pt-3">
                                <Image src="images/icons/icn-mybusiness.svg" width="24px" color="transparent" />
                            </Box>
                            <Box fullWidth>
                                <Box>
                                    <Text
                                        component="h3"
                                        labelKey="settings.environments.subTitle"
                                        className={classNames({ "size-2": !isDesktop })}
                                        bold
                                    />
                                </Box>
                                <Box>
                                    <Text color="text-grey" labelKey="settings.environments.info" light />
                                </Box>
                            </Box>
                        </Box>

                        <PaginatedDataTable
                            nameList="environments"
                            data={environments}
                            rowsPerPage={rowsPerPage}
                            component={environmentItem}
                            componentProps={{
                                selected: selectedEnvironment,
                            }}
                        />
                    </div>
                </Box>
                <Row
                    gapX="7"
                    className={classNames("mt-7 mb-7", {
                        "d-flex flex-column-reverse": !isDesktop,
                    })}>
                    <Col xs={3} xsOffset={3}>
                        <Button
                            bsStyle="outline"
                            label="global.cancel"
                            className="mr-8"
                            btnUppercase={false}
                            onClick={handleClickCancel}
                            block
                        />
                    </Col>
                    <Col xs={3}>
                        <Button
                            bsStyle="primary"
                            label="global.save"
                            disabled={selectedEnvironment === null || selectedEnvironment === currentDefaultEnvironment}
                            loading={isFetching}
                            btnUppercase={false}
                            onClick={handleClickSubmit}
                            block
                        />
                    </Col>
                </Row>
            </MainContainer>

            <TextAndButtonsModal
                modalShow={showSettingsExitModal}
                cancelFunction={() => setShowSettingsExitModal(false)}
                acceptFunction={() => handleGoSettings()}
                confirmLabel="enrollment.modal.exit"
                headingText={i18n.get("enrollment.modal.headingText")}
                text={i18n.get("enrollment.modal.text")}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    environments: settingsSelectors.getEnvironments(state),
    currentDefaultEnvironment: sessionSelectors.getIdDefaultEnvironment(state),
    isFetching: settingsSelectors.isFetching(state),
});

ChangeDefaultEnvironment.propTypes = {
    currentDefaultEnvironment: number,
    environments: arrayOf(arrayOf(oneOfType([string, shape({})]))).isRequired,
    isFetching: bool.isRequired,
    dispatch: func.isRequired,
};

ChangeDefaultEnvironment.defaultProps = {
    currentDefaultEnvironment: null,
};

export default connect(mapStateToProps)(resizableRoute(ChangeDefaultEnvironment));
