import { call, takeEvery, takeLatest, put, select } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import * as settings from "middleware/settings";
import { types as settingsTypes, selectors as settingsSelectors } from "reducers/settings";
import { actions as i18nActions } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { adjustIdFieldErrors } from "util/form.js";
import * as utilDownload from "util/download";
import globalTypes from "reducers/types/global";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import { actions as sessionActions, types as sessionTypes } from "reducers/session";
import { maskString } from "util/string";
import { actions as creditCardsActions } from "reducers/creditCard";
import { types as secondFactorTypes } from "reducers/secondFactor";

const sagas = [
    takeLatest(settingsTypes.CHANGE_LANGUAGE_PRE, changeLanguage),

    takeLatest(settingsTypes.CHANGE_DEFAULT_ENVIRONMENT_PRE, changeDefaultEnvironment),

    takeLatest(settingsTypes.CHANGE_PASSWORD_REQUEST, changePassword),
    takeLatest(settingsTypes.CHANGE_PASSWORD_CONFIRM_REQUEST, changePasswordConfirm),
    takeLatest(settingsTypes.CHANGE_PASSWORD_RESEND_OTP_REQUEST, changePasswordResendOtp),

    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_PRE, changeSecuritySealPre),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_PRE, changeSecuritySealConfirmationPre),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_BACK, changeSecuritySealConfirmationBack),
    takeLatest(settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION, changeSecuritySealConfirmation),

    takeLatest(settingsTypes.NOTIFICATIONS_CONFIGURATION_PRE, notificationsConfigurationPre),
    takeLatest(settingsTypes.COMMUNICATION_TYPE_PRE, communicationTypePre),
    takeEvery(settingsTypes.MODIFY_NOTIFICATION_CONFIGURATIONS, modifyNotificationConfigurations),
    takeEvery(settingsTypes.MODIFY_PAPERLESS_CONFIGURATIONS, modifyPaperlessConfigurations),

    takeLatest(settingsTypes.GET_USER_DATA, getUserData),
    takeLatest(settingsTypes.MAIL_SEND_CODE, sendMailCode),
    takeLatest(settingsTypes.MAIL_UPDATE, updateMail),

    takeLatest(settingsTypes.MOBILEPHONE_SEND_CODE, sendMobilePhoneCode),
    takeLatest(settingsTypes.MOBILEPHONE_UPDATE, updateMobilePhone),
    takeLatest(settingsTypes.LOAD_DOCUMENTS_REQUEST, loadDocumentsRequest),
    takeLatest(settingsTypes.DOWNLOAD_DOCUMENT_REQUEST, downloadDocumentRequest),

    takeLatest(settingsTypes.FETCH_PRODUCTS_LIST, fetchProductsList),
    takeLatest(settingsTypes.SET_ALL_PAPERLESS_SUCCESS, fetchProductsList),
    takeLatest(settingsTypes.SET_ALL_PAPERLESS_REQ, setAllPaperlessValue),

    takeLatest(settingsTypes.GET_ADDRESSES_INFORMATION_REQ, getAddressesInformationRequest),
    takeLatest(settingsTypes.SET_ADDRESSES_INFORMATION_REQ, setAddressesInformationRequest),

    takeLatest(settingsTypes.GET_SECURITY_QUESTIONS_REQ, getSecurityQuestionsRequest),
    takeLatest(settingsTypes.REGISTER_SECURITY_QUESTIONS_REQ, registerSecurityQuestionsRequest),
    takeLatest(settingsTypes.UPDATE_SECURITY_QUESTIONS_REQ, updateSecurityQuestionsRequest),
    takeLatest(settingsTypes.LIST_ENVIRONMENTS_REQUEST, getEnvironmentsRequest),
];

export default sagas;

function* changeLanguage({ language, changeUserLanguage }) {
    if (changeUserLanguage) {
        const result = yield call(settings.changeLanguage, language);

        if (result && result.status === 200) {
            configUtils.setRecaptchaLang(language);
            yield put(i18nActions.setLang(language));
            // Se setea invalidate para TC con el fin de volver a peticionar y guardar el texto de la fecha limite de pago con el respectivo lenguaje
            yield put(creditCardsActions.invalidateCache());
            yield put({ type: settingsTypes.CHANGE_LANGUAGE_PRE_SUCCESS });
        } else {
            const confirmationMessage = i18n.get("settings.changeLanguage.errorMessage");
            yield put(notificationActions.showNotification(confirmationMessage, "error", ["changeLanguage"]));
            yield put({ type: settingsTypes.CHANGE_LANGUAGE_PRE_ERROR });
        }
    } else {
        yield put(i18nActions.setLang(language));
        const confirmationMessage = i18n.get("settings.changeLanguage.confirmationMessage");
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["changeLanguage"]));
        yield put({ type: settingsTypes.CHANGE_LANGUAGE_PRE_ERROR });
    }
}

function* changeDefaultEnvironment({ idEnvironment }) {
    const response = yield call(settings.changeDefaultEnvironment, idEnvironment);

    const { code, data } = response.data;
    if (code === "COR000I") {
        const defaultEnvironmentRemoved = data.idEnvironment === 0;
        yield put({
            type: settingsTypes.CHANGE_DEFAULT_ENVIRONMENT_PRE_SUCCESS,
            idDefaultEnvironment: !defaultEnvironmentRemoved ? data.idEnvironment : null,
        });

        let confirmationMessage = "";
        if (defaultEnvironmentRemoved) {
            confirmationMessage = i18n.get("settings.defaultEnvironment.confirmationRemovedMessage");
        } else {
            confirmationMessage = i18n.get("settings.defaultEnvironment.confirmationMessage");
        }
        yield put(notificationActions.showNotification(confirmationMessage, "success", ["settings"]));
        yield put(push("/settings/changeDefaultEnvironment/confirmation"));
        // yield put({
        //     type: sessionTypes.CHANGE_ENVIRONMENT_REQUEST,
        //     idEnvironment,
        //     rememberEnvironment: true,
        //     formikBag: undefined,
        //     shouldNavigate: false,
        // });
    } else {
        const errorMessage = i18n.get("settings.defaultEnvironment.errorMessage");
        yield put(notificationActions.showNotification(errorMessage, "error", ["changeDefaultEnvironment"]));
    }
}

function* notificationsConfigurationPre() {
    const response = yield call(settings.notificationsConfigurationPre);

    if (response && response.status === 200) {
        yield put({
            type: settingsTypes.NOTIFICATIONS_CONFIGURATION_PRE_SUCCESS,
            communicationTypes: response.data.data.communicationConfigurations,
        });
    }
}

function* communicationTypePre({ communicationType }) {
    const response = yield call(settings.communicationTypePre, communicationType);

    if (response && response.status === 200) {
        const communicationTransportsConfigurations = response.data.data.communicationConfigurations;

        yield put({
            type: settingsTypes.COMMUNICATION_TYPE_PRE_SUCCESS,
            communicationTransportsConfigurations,
        });
    } else {
        yield put(push("/desktop"));
    }
}

function* modifyNotificationConfigurations({ notificationType, transport, subscribed }) {
    const response = yield call(settings.modifyNotificationConfigurations, notificationType, transport, subscribed);

    if (response && response.status === 200) {
        if (response.data.data.transportModified) {
            yield put(
                notificationActions.showNotification(
                    i18n.get("settings.notificationsConfiguration.confirmationMessage"),
                    "success",
                    ["settings/notificationsConfiguration"],
                ),
            );
            yield put({ type: settingsTypes.NOTIFICATIONS_CONFIGURATION_PRE });
        } else {
            yield put(
                notificationActions.showNotification(
                    i18n.get("settings.notificationsConfiguration.errorMessage"),
                    "error",
                    ["settings/notificationsConfiguration"],
                ),
            );
        }
    }
}

function* modifyPaperlessConfigurations({ idProduct, paperless }) {
    const response = yield call(settings.modifyPaperlessConfigurations, idProduct, paperless);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    }
}

function* fetchProductsList() {
    const response = yield call(settings.fetchProductsList);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    } else {
        yield put({ type: settingsTypes.UPDATE_PRODUCT_LIST, productsList: response.data.data });
        yield put({ type: settingsTypes.FETCH_PRODUCTS_LIST_SUCCESS });
    }
}

function* setAllPaperlessValue({ paperless }) {
    const response = yield call(settings.setAllPaperlessValue, paperless);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
        yield put({ type: settingsTypes.SET_ALL_PAPERLESS_ERROR });
    } else {
        yield put({ type: settingsTypes.SET_ALL_PAPERLESS_SUCCESS });
    }
}

function* getUserData({ validateWally }) {
    const response = yield call(settings.getUserData, validateWally);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(response.data.message, "error", ["settings"]));
        yield put(replace("/settings"));
    } else {
        yield put({ type: settingsTypes.GET_USER_DATA_SUCCESS, data: response.data.data });
    }
}

function* sendMailCode({ mail, otp, formikBag }) {
    const response = yield call(settings.sendMailCode, mail, otp);

    if (response.type === "W") {
        if (response.data.data.otp === "returnCode.COR091W") {
            yield put({ type: globalTypes.CLEAN_UP });
            yield put(
                push({
                    pathname: "/session/expiredByMaxAttempts",
                }),
            );
        } else {
            formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        }
    } else {
        yield put(replace("/settings/changeEmail/confirmation"));
    }

    formikBag.setSubmitting(false);
}

function* updateMail({ mailCode, captcha, formikBag }) {
    const response = yield call(settings.updateMail, mailCode, captcha);

    let notificationScope = "/settings/changeEmail/confirmation";
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));

        switch (response.data.code) {
            case "COR020W":
                if (response.data.data.captcha) {
                    yield put({ type: settingsTypes.MAIL_UPDATE_FAILURE_REQUIRE_CAPTCHA });
                    formikBag.setSubmitting(false);
                    return;
                }
                break;
            case "API523W":
            case "API527W":
                yield put(push("/settings"));
                notificationScope = "settings";
                break;
            case "API525W":
            case "API526W":
                yield put({ type: settingsTypes.MAIL_UPDATE_FAILURE_REQUIRE_CAPTCHA });
                break;
            default:
                yield put(push("/settings"));
                notificationScope = "settings";
        }

        yield put(notificationActions.showNotification(response.data.message, "error", notificationScope));
    } else {
        const { mail } = response.data.data;

        yield put(sessionActions.emailUpdateSuccess(mail));
        yield put(
            notificationActions.showNotification(i18n.get("settings.changeEmail.success"), "success", ["settings"]),
        );
        yield put(replace("/settings"));
    }
    formikBag.setSubmitting(false);
}

function* sendMobilePhoneCode({ mobilePhone, otp, formikBag }) {
    const { prefix, value: number, areaCode } = mobilePhone;
    const prefixWithoutSymbols = prefix.replace(/-/g, "");
    const response = yield call(settings.sendMobilePhoneCode, areaCode + number, prefixWithoutSymbols, otp);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);
    } else {
        yield put({
            type: settingsTypes.PHONE_CAN_BE_USED_IN_WALLY,
            phoneCanBeUsedInWally: response.data.data.canBeUsedInWally,
        });
        formikBag.setSubmitting(false);
        const phoneNumberMask = maskString(`${prefixWithoutSymbols}${number}`, 4);
        yield put(replace(`/settings/changePhone/confirmation?mobilePhone=${phoneNumberMask}`));
    }
}

function* updateMobilePhone({ mobilePhoneCode, formikBag }) {
    const response = yield call(settings.updateMobilePhone, mobilePhoneCode);

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("settings.changePhone.success"), "success", ["settings"]),
        );
        yield put(replace("/settings"));
    }
    formikBag.setSubmitting(false);
}

function* setAddressesInformationRequest({ address, mailingAddress, otp, formikBag }) {
    const response = yield call(settings.setAddressesInformation, address, mailingAddress, otp);

    const message = response.data.data.message || response.data.message;

    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
    } else {
        yield put(notificationActions.showNotification(message, "success", ["settings"]));
        yield put(replace("/settings"));
    }
    formikBag.setSubmitting(false);
}

function* changePassword({ password, newPassword, newPasswordConfirmation, formikBag }) {
    formikBag.setErrors({ errors: {} });
    const response = yield call(settings.changePassword, password, newPassword, newPasswordConfirmation);
    if (response.type === "W") {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);
        yield put(notificationActions.showNotification(response.data.message, "error", ["changePassword"]));
    } else {
        formikBag.setSubmitting(false);
        /* yield put(
            notificationActions.showNotification(i18n.get("preferences.password.confirmation"), "success", [
                "settings",
            ]),
        ); */
        const { _finalPassword, operationType, contact, otpMethod } = response.data.data;
        yield put({
            type: settingsTypes.CHANGE_PASSWORD_SUCCESS,
            finalPassword: _finalPassword,
            operationType,
            contact,
            otpMethod,
        });
        yield put(push("/settings/changePassword/confirm"));
    }
}

function* changePasswordConfirm({ secondFactor, formikBag }) {
    const finalPassword = yield select(settingsSelectors.getFinalPassword);
    const response = yield call(settings.changePasswordConfirm, secondFactor, finalPassword);
    let notificationScope = "changePasswordConfirm";
    if (response.type === "W") {
        const { code, data } = response.data;
        if (code === "COR020W" && data.secondFactor) {
            formikBag.setErrors(data);
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            formikBag.setSubmitting(false);
            return;
        }

        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        formikBag.setSubmitting(false);
        switch (response.data.code) {
            case "API523W":
                yield put(push("/settings"));
                notificationScope = "settings";
                break;
            case "API707W":
                yield put(sessionActions.logoutUserBlocked());
                return;
            case "API708W":
                yield put(
                    notificationActions.showNotification(
                        i18n.get(
                            "secondFactor.credential.otp.expired",
                            "Código OTP expirado, solicite un nuevo código OTP",
                        ),
                        "warning",
                        ["settings"],
                    ),
                );
                return;
            default:
                break;
        }
        yield put(notificationActions.showNotification(response.data.message, "error", [notificationScope]));
    } else {
        yield put({
            type: sessionTypes.UPDATE_PASSWORD_EXPIRATION_CONDITIONS,
            daysBeforeExpirePassword: 77,
            notifyPasswordExpiration: false,
        });
        // notificationScope = "settings";
        formikBag.setSubmitting(false);
        // yield put(
        //     notificationActions.showNotification(i18n.get("preferences.password.confirmation"), "success", [
        //         notificationScope,
        //     ]),
        // );
        yield put(push("/settings/changePassword/success"));
    }
}

function* changePasswordResendOtp() {
    const response = yield call(settings.changePasswordResendOtp);
    yield put(notificationActions.removeNotification());
    const notificationScope = "changePasswordConfirm";
    if (response.type === "W") {
        const notificationErrorMessage = response.data.message;
        switch (response.data.code) {
            case "COR048W":
                break;
            case "COR020W":
                break;
            default:
                yield put(push("/settings/changePassword"));
        }

        if (response.data.code !== "COR020W") {
            yield put(notificationActions.showNotification(notificationErrorMessage, "error", [notificationScope]));
        }
    } else {
        yield put(
            notificationActions.showNotification(i18n.get("unlockUser.resendOtp.confirmation"), "success", [
                notificationScope,
            ]),
        );
    }
}

function* changeSecuritySealPre() {
    const response = yield call(settings.listSecuritySeals);

    if (response && response.status === 200) {
        yield put({
            type: settingsTypes.CHANGE_SECURITY_SEAL_PRE_SUCCESS,
            // eslint-disable-next-line no-underscore-dangle
            securitySeals: response.data.data._securitySeals,
        });
    }
}

function* changeSecuritySealConfirmationPre() {
    yield put(push("/settings/changeSecuritySeal/confirmation"));
}

function* changeSecuritySealConfirmationBack() {
    yield put(push("/settings/changeSecuritySeal/"));
}

function* changeSecuritySealConfirmation({ securitySeal }) {
    const otp = "";
    const response = yield call(settings.modifySecuritySeal, securitySeal, otp);

    if (response && response.status === 200) {
        if (response.type === "W") {
            yield put({ type: settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_ERROR, errors: response.data.data });
        } else {
            yield put({ type: settingsTypes.CHANGE_SECURITY_SEAL_CONFIRMATION_SUCCESS, securitySeal });
            yield put(push("/settings/changeAvatar/confirmation"));
        }
    }
}

function* loadDocumentsRequest() {
    const { type, data } = yield call(settings.listAllGeneralConditionDocuments);

    if (type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    } else {
        yield put({ type: settingsTypes.LOAD_DOCUMENTS_SUCCESS, documents: data.data.documents });
    }
}

function* downloadDocumentRequest({ fileNameKey }) {
    const { type, data } = yield call(settings.downloadDocument, fileNameKey);

    if (type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["settings"]));
    } else {
        const { content } = data.data;
        utilDownload.downloadPdf(i18n.get(fileNameKey), content);
    }
}

function* getAddressesInformationRequest() {
    const response = yield call(settings.getAddressesInformation);
    if (response.data.code !== "COR000I") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["session"]));
        yield put({ type: settingsTypes.GET_ADDRESSES_INFORMATION_FAILURE });
    } else {
        yield put({
            type: settingsTypes.GET_ADDRESSES_INFORMATION_SUCCESS,
            address: response.data.data.address,
            mailingAddress: response.data.data.mailingAddress,
            countries: response.data.data.countries,
        });
    }
}

function* getSecurityQuestionsRequest() {
    const response = yield call(settings.getSecurityQuestions);
    if (response.data.code !== "COR000I") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["securityQuestions"]),
        );
        yield put({ type: settingsTypes.GET_SECURITY_QUESTIONS_FAILURE });
    } else {
        const { questions, enabledUpdate, hasRegisteredQuestions } = response.data.data;
        yield put({
            type: settingsTypes.GET_SECURITY_QUESTIONS_SUCCESS,
            securityQuestions: questions,
            enabledUpdate,
            hasRegisteredQuestions,
        });
    }
}

function* registerSecurityQuestionsRequest({ questions, secondFactor, formikBag, onSuccess }) {
    const response = yield call(settings.registerSecurityQuestions, questions, secondFactor);

    if (response.type === "W") {
        const { code, data } = response.data;
        yield put({
            type: settingsTypes.REGISTER_SECURITY_QUESTIONS_FAILURE,
        });
        let showNotification = true;
        if (code === "COR020W" && data.secondFactor) {
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            showNotification = false;
        }

        if (code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }

        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "secondFactor.credential.otp.expired",
                        "Código OTP expirado, solicite un nuevo código OTP",
                    ),
                    "warning",
                    ["registerQuestions"],
                ),
            );
            return;
        }

        formikBag.setErrors(adjustIdFieldErrors(data));
        if (showNotification) {
            yield put(notificationActions.showNotification(response.data.message, "error", ["registerQuestions"]));
        }
    } else {
        yield put({
            type: settingsTypes.REGISTER_SECURITY_QUESTIONS_SUCCESS,
        });
        if (onSuccess) {
            onSuccess();
        }
    }
    formikBag.setSubmitting(false);
}

function* updateSecurityQuestionsRequest({ questions, secondFactor, formikBag, onSuccess }) {
    const response = yield call(settings.updateSecurityQuestions, questions, secondFactor);

    if (response.type === "W") {
        const { code, data } = response.data;

        yield put({
            type: settingsTypes.UPDATE_SECURITY_QUESTIONS_FAILURE,
        });
        let showNotification = true;
        if (code === "COR020W" && data.secondFactor) {
            yield put({ type: secondFactorTypes.SECOND_FACTOR_ATTEMPTS_FAILURE });
            showNotification = false;
        }

        if (code === "API707W") {
            yield put(sessionActions.logoutUserBlocked());
            return;
        }

        if (code === "API708W") {
            yield put(
                notificationActions.showNotification(
                    i18n.get(
                        "secondFactor.credential.otp.expired",
                        "Código OTP expirado, solicite un nuevo código OTP",
                    ),
                    "warning",
                    ["registerQuestions"],
                ),
            );
            return;
        }

        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        if (showNotification) {
            yield put(notificationActions.showNotification(response.data.message, "error", ["registerQuestions"]));
        }
    } else {
        yield put({
            type: settingsTypes.UPDATE_SECURITY_QUESTIONS_SUCCESS,
        });

        if (onSuccess) {
            onSuccess();
        }
    }
    formikBag.setSubmitting(false);
}

function* getEnvironmentsRequest() {
    const response = yield call(settings.getEnvironments);

    if (response.type === "W") {
        yield put({
            type: settingsTypes.LIST_ENVIRONMENTS_ERROR,
        });

        yield put(notificationActions.showNotification(response.data.message, "error", ["settings"]));
    } else {
        const { environments } = response.data.data;
        yield put({
            type: settingsTypes.LIST_ENVIRONMENTS_SUCCESS,
            environments,
        });
    }
}
