import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import LabelWithIcon from "pages/_components/LabelWithIcon";
import Loader from "pages/_components/Loader";
import PageLoading from "pages/_components/PageLoading";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import PinInput from "pages/_components/fields/PinInputV2";
import { bool, func, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { actions as enrollmentActions, selectors as enrollmentSelectors } from "reducers/enrollment";
import { compose } from "redux";
import EnrollmentWrapper from "../_components/EnrollmentWrapper";

const FORM_ID = "enrollment.wizard.validateInvitationCode";

const InvitationCode = (props) => {
    const [validating, setValidating] = useState(false);
    const { dispatch, fetching, isDesktop } = props;
    const [pin, setPin] = useState(new Array(6).fill(""));

    useEffect(() => {
        dispatch(enrollmentActions.clean());
    }, []);

    const cancel = () => {
        dispatch(enrollmentActions.showExitEnrollmentModal(true));
    };

    const clean = () => {
        dispatch(enrollmentActions.clean(clean));
    };

    const resetInput = () => {
        setPin(new Array(6).fill(""));
        document.getElementById("pin_0").focus();
    };

    const verifyInvitationCode = (code, formik) => {
        const formattedCode = code.replace(/(.{4})(.{4})(.{4})/, "$1-$2-$3").toUpperCase();
        setValidating(true);
        dispatch(enrollmentActions.validateInvitationCode(formattedCode, formik, setValidating));
    };

    const shortMessage = (errorCode) => {
        switch (errorCode) {
            case "API508W":
                return "enrollment.invitation.form.message.2";
            case "API509W":
                return "enrollment.invitation.form.message.3";
            case "API510W":
                return "enrollment.invitation.form.message.4";
            default:
                return "enrollment.invitation.form.message.1";
        }
    };

    const renderDocumentForm = () => {
        const { isSubmitting, setFieldValue, setErrors, errors, permanentMessage, isInvitationCodeValid } = props;

        const handlePinCodeChange = (pinCode) => {
            dispatch(enrollmentActions.setAditionalError(null));
            if (pinCode?.length === 12) {
                verifyInvitationCode(pinCode, { resetInput, setErrors });
            } else if (isInvitationCodeValid) {
                setFieldValue("code", "");
                clean();
            }
        };

        const message =
            permanentMessage !== null ? (
                <LabelWithIcon color="text-disabled-color" type="error" text={shortMessage(permanentMessage)} />
            ) : isInvitationCodeValid ? (
                <LabelWithIcon color="border-base-color" type="info" text="enrollment.step1.code.ok" />
            ) : null;

        const content = () => (
            <Form className={classNames("display-flex flex-column", { "full-height": !isDesktop })}>
                <Box display="flex" fullWidth className="login-title-container mb-3">
                    <Box fullWidth className="login-title-text">
                        <Text
                            size={isDesktop ? "3" : "2"}
                            className="btn-link-span"
                            bold
                            labelKey="enrollment.step1.title"
                        />
                    </Box>
                </Box>

                <Box display="flex" fullWidth className="mb-5 mt-7">
                    <Text className="btn-link-span" semibold labelKey="enrollment.step1.subtitle" />
                </Box>
                <Box display="flex" fullWidth className="mb-7">
                    <Text className="btn-link-span" labelKey="enrollment.step1.subject" />
                </Box>
                <Box
                    display="flex"
                    column
                    fullWidth
                    className={classNames("enrollment-pin mb-1", {
                        "has-error-input": permanentMessage !== null,
                    })}>
                    <Field
                        idForm={FORM_ID}
                        autoComplete="off"
                        name="code"
                        component={PinInput}
                        maxLength={2}
                        labelNoMarginTop
                        noMarginBottom
                        type="text"
                        inputLenght={6}
                        autoFocus={isDesktop}
                        defaultMessage={message}
                        handleChangeProp={(e) => handlePinCodeChange(e, errors, setErrors)}
                        pin={pin}
                        setPin={setPin}
                    />
                </Box>
                {validating && <Loader bgTransparent />}
                <Row>
                    <Col xs={isDesktop ? 6 : 12}>
                        <Button
                            bsStyle="primary"
                            label="global.continue"
                            loading={isSubmitting}
                            disabled={!isInvitationCodeValid}
                            className="full-width"
                            type="submit"
                        />
                    </Col>
                    <Col xs={isDesktop ? 6 : 12}>
                        <Button label="global.cancel" onClick={cancel} bsStyle="outline" block />
                    </Col>
                </Row>
            </Form>
        );

        return (
            <EnrollmentWrapper isDesktop={isDesktop}>
                <>{content()}</>
            </EnrollmentWrapper>
        );
    };

    return (
        <PageLoading loading={fetching} bgTransparent classicStyle={false}>
            <>{renderDocumentForm()}</>
        </PageLoading>
    );
};

InvitationCode.propTypes = {
    dispatch: func.isRequired,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    fetching: bool.isRequired,
    values: shape({
        code: string,
    }).isRequired,
    errors: shape({}).isRequired,
    setErrors: func.isRequired,
    permanentMessage: string,
    isInvitationCodeValid: bool.isRequired,
    setFieldValue: func.isRequired,
};
InvitationCode.defaultProps = {
    permanentMessage: undefined,
};

const mapStateToProps = (state) => ({
    fetching: enrollmentSelectors.isFetching(state),
    permanentMessage: enrollmentSelectors.getAditionalMessageError(state),
    isInvitationCodeValid: enrollmentSelectors.isInvitationCodeValid(state),
    documentInvitation: enrollmentSelectors.getDocumentInvitation(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: true,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: (props) => ({
            code: props.match.params.code || "",
        }),
        handleSubmit: ({}, formikBag) => {
            const { dispatch, documentInvitation } = formikBag.props;
            dispatch(enrollmentActions.generateValidationSMS(documentInvitation));
        },
    }),
)(InvitationCode);
