import { createReducer, makeActionCreator } from "util/redux";

export const softTokenTypes = {
    VALIDATE_TOKEN_STATUS_REQUEST: "VALIDATE_TOKEN_STATUS_REQUEST",
    SAVE_TOKEN_REQUEST: "SAVE_TOKEN_REQUEST",
    SAVE_TOKEN_FAILURE: "SAVE_TOKEN_FAILURE",
    SAVE_TOKEN_SUCCESS: "SAVE_TOKEN_SUCCESS",
    ACTIVATE_TOKEN_REQUEST: "ACTIVATE_TOKEN_REQUEST",
    ACTIVATE_TOKEN_FAILURE: "ACTIVATE_TOKEN_FAILURE",
    ACTIVATE_TOKEN_SUCCESS: "ACTIVATE_TOKEN_SUCCESS",
    GET_STATUS_TOKEN_REQUEST: "softToken/GET_STATUS_TOKEN_REQUEST",
    GET_STATUS_TOKEN_SUCCESS: "softToken/GET_STATUS_TOKEN_SUCCESS",
    GET_STATUS_TOKEN_FAILURE: "softToken/GET_STATUS_TOKEN_FAILURE",
    MIGRATE_ENTRUST_TOKEN_PRE_REQUEST: "softToken/MIGRATE_ENTRUST_TOKEN_PRE_REQUEST",
    MIGRATE_ENTRUST_TOKEN_PRE_SUCCESS: "softToken/MIGRATE_ENTRUST_TOKEN_PRE_SUCCESS",
    MIGRATE_ENTRUST_TOKEN_PRE_FAILURE: "softToken/MIGRATE_ENTRUST_TOKEN_PRE_FAILURE",
    MIGRATE_ENTRUST_TOKEN_PRE_CLEAR: "softToken/MIGRATE_ENTRUST_TOKEN_PRE_CLEAR",
    RESEND_OTP_AUTHENTICATE_SUCCESS: "softToken/RESEND_OTP_AUTHENTICATE_SUCCESS",
    RESEND_OTP_AUTHENTICATE_REQUEST: "softToken/RESEND_OTP_AUTHENTICATE_REQUEST",
    RESEND_OTP_AUTHENTICATE_FAILURE: "softToken/RESEND_OTP_AUTHENTICATE_FAILURE",
    PUT_ACTIVATION_FLOW_CURRENT_STEP: "softToken/PUT_ACTIVATION_FLOW_CURRENT_STEP",
    CLEAR_FETCHING: "softToken/CLEAR_FETCHING",
    CLEAR_ACTIVATION_STEP: "softToken/CLEAR_ACTIVATION_STEP",
    SEND_TOKEN_PIN: "softTokenTypes.SEND_TOKEN_PIN",
    GET_RATE_VALUE_REQUEST: "softToken/GET_RATE_VALUE_REQUEST",
    GET_RATE_VALUE_FAILURE: "softToken/GET_RATE_VALUE_FAILURE",
    GET_RATE_VALUE_SUCCESS: "softToken/GET_RATE_VALUE_SUCCESS",
    REMOVE_DEVICE_PIN: "softToken/REMOVE_DEVICE_PIN",
    GET_DEVICE_PIN: "softToken/GET_DEVICE_PIN",
    SET_DEVICE_PIN: "softToken/SET_DEVICE_PIN",
    GET_DEVICE_PIN_SUCCESS: "softToken/GET_DEVICE_PIN_SUCCESS",
};

export const INITIAL_STATE = {
    fetching: false,
    tokenStatus: undefined,
    fetchingTokenDeviceValidation: false,
    activationStep: "",
    pinCode: "",
    amountActivateRate: "",
    currencyActivateRate: "",
    devicePin: "",
};

export const selectors = {
    isFetching: ({ softToken }) => softToken.fetching || false,
    getStatusToken: ({ softToken }) => softToken.tokenStatus,
    getActivationStep: ({ softToken }) => softToken.activationStep,
    isFetchingTokenDeviceValidation: ({ softToken }) => softToken?.fetchingTokenDeviceValidation || false,
    getTokenPin: ({ softToken }) => softToken.pinCode,
    getAmountRateFee: ({ softToken }) => softToken.amountActivateRate,
    getCurrencyRateFee: ({ softToken }) => softToken.currencyActivateRate,
    getDevicePin: ({ softToken }) => softToken?.devicePin,
};

export const actions = {
    validateTokenStatusRequest: makeActionCreator(softTokenTypes.VALIDATE_TOKEN_STATUS_REQUEST),
    saveTokenRequest: makeActionCreator(softTokenTypes.SAVE_TOKEN_REQUEST, "saveRequest"),
    activateTokenRequest: makeActionCreator(softTokenTypes.ACTIVATE_TOKEN_REQUEST, "activateRequest"),
    getStatusTokenRequest: makeActionCreator(softTokenTypes.GET_STATUS_TOKEN_REQUEST, "preview"),
    getStatusTokenSuccess: makeActionCreator(softTokenTypes.GET_STATUS_TOKEN_SUCCESS, "tokenStatus"),
    migrateEntrustTokenPreRequest: makeActionCreator(softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_REQUEST, "validateData"),
    migrateEntrustTokenPreClear: makeActionCreator(softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_CLEAR),
    resendOtpAuthenticateRequest: makeActionCreator(softTokenTypes.RESEND_OTP_AUTHENTICATE_REQUEST),
    clearFetching: makeActionCreator(softTokenTypes.CLEAR_FETCHING),
    setActivationStep: makeActionCreator(softTokenTypes.PUT_ACTIVATION_FLOW_CURRENT_STEP, "activationStep"),
    sendTokenPin: makeActionCreator(softTokenTypes.SEND_TOKEN_PIN, "pinCode"),
    getRateAmountActivateRequest: makeActionCreator(softTokenTypes.GET_RATE_VALUE_REQUEST),
    getDevicePin: makeActionCreator(softTokenTypes.GET_DEVICE_PIN),
    removeDevicePin: makeActionCreator(softTokenTypes.REMOVE_DEVICE_PIN),
    setDevicePin: makeActionCreator(softTokenTypes.SET_DEVICE_PIN, "pinCode"),
};

export default createReducer(INITIAL_STATE, {
    [softTokenTypes.VALIDATE_TOKEN_STATUS_REQUEST]: (state) => ({ ...state, fetching: false }),
    [softTokenTypes.SAVE_TOKEN_REQUEST]: (state) => ({ ...state, fetching: true }),
    [softTokenTypes.SAVE_TOKEN_FAILURE]: (state) => ({ ...state, fetching: false }),
    [softTokenTypes.SAVE_TOKEN_SUCCESS]: (state) => ({ ...state, fetching: false }),

    [softTokenTypes.ACTIVATE_TOKEN_REQUEST]: (state) => ({ ...state, fetching: true }),
    [softTokenTypes.ACTIVATE_TOKEN_FAILURE]: (state) => ({ ...state, fetching: false }),
    [softTokenTypes.ACTIVATE_TOKEN_SUCCESS]: (state) => ({ ...state, fetching: false }),

    [softTokenTypes.GET_STATUS_TOKEN_REQUEST]: (state) => ({ ...state, fetching: true }),
    [softTokenTypes.GET_STATUS_TOKEN_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        tokenStatus: action?.tokenStatus,
    }),
    [softTokenTypes.GET_STATUS_TOKEN_FAILURE]: (state) => ({ ...state, fetching: false }),
    [softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_REQUEST]: (state) => ({
        ...state,
        migrateEntrustTokenPreData: undefined,
        fetchingTokenDeviceValidation: true,
    }),
    [softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_FAILURE]: (state) => ({
        ...state,
        fetchingTokenDeviceValidation: false,
    }),
    [softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_SUCCESS]: (state) => ({
        ...state,
        fetchingTokenDeviceValidation: false,
    }),
    [softTokenTypes.MIGRATE_ENTRUST_TOKEN_PRE_CLEAR]: (state) => ({
        ...state,
        migrateEntrustTokenPreData: undefined,
        fetchingTokenDeviceValidation: false,
    }),
    [softTokenTypes.RESEND_OTP_AUTHENTICATE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [softTokenTypes.RESEND_OTP_AUTHENTICATE_SUCCESS]: (state) => ({
        ...state,
        fetching: false,
    }),
    [softTokenTypes.RESEND_OTP_AUTHENTICATE_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [softTokenTypes.CLEAR_FETCHING]: (state) => ({
        ...state,
        fetching: false,
    }),
    [softTokenTypes.PUT_ACTIVATION_FLOW_CURRENT_STEP]: (state, action) => ({
        ...state,
        activationStep: action?.activationStep,
    }),
    [softTokenTypes.CLEAR_ACTIVATION_STEP]: (state) => ({
        ...state,
        activationStep: "",
    }),
    [softTokenTypes.SEND_TOKEN_PIN]: (state, action) => ({
        ...state,
        pinCode: action?.pinCode,
    }),
    [softTokenTypes.GET_RATE_VALUE_REQUEST]: (state) => ({
        ...state,
        fetching: true,
    }),
    [softTokenTypes.GET_RATE_VALUE_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),
    [softTokenTypes.GET_RATE_VALUE_SUCCESS]: (state, action) => ({
        ...state,
        fetching: false,
        amountActivateRate: action.amountActivateRate,
        currencyActivateRate: action.currencyActivateRate,
    }),
    [softTokenTypes.GET_DEVICE_PIN_SUCCESS]: (state, { pinCode }) => ({
        ...state,
        devicePin: pinCode
    }),
});
