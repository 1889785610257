import classNames from "classnames";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import withFocus from "pages/_components/withFocus";
import Select from "pages/forms/_components/_fields/Select";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import { any, arrayOf, bool, func, objectOf, oneOfType, shape, string } from "prop-types";
import React, { Component } from "react";
import * as i18n from "util/i18n";

class Selector extends Component {
    static propTypes = {
        field: shape({
            name: string,
        }).isRequired,
        form: shape({
            errors: objectOf(oneOfType([string, objectOf(string)])),
            touched: objectOf(oneOfType([arrayOf(bool), bool, objectOf(bool)])),
        }).isRequired,
        idForm: string.isRequired,
        inLineControl: bool,
        isFocused: bool,
        options: arrayOf(objectOf(any)).isRequired,
        renderAs: string,
        searchable: bool,
        value: string,
        placeholder: string,
        labelNoMarginTop: bool,
        handleChange: func,
        labelText: string,
        disabled: bool,
        noLabel: bool,
        column: bool,
        gap: string,
        multi: bool,
        radioClassNames: string,
        cControlClassName: string,
    };

    static defaultProps = {
        isFocused: false,
        inLineControl: false,
        renderAs: "combo",
        searchable: false,
        value: null,
        placeholder: null,
        labelNoMarginTop: false,
        labelText: null,
        handleChange: null,
        disabled: false,
        noLabel: false,
        column: false,
        gap: null,
        multi: false,
        radioClassNames: "",
        cControlClassName: "",
    };

    handleChange = (param) => {
        const { field, form, renderAs, handleChange } = this.props;
        let valueParam = param;

        if (renderAs === "combo") {
            const { value } = param;
            valueParam = value;
        }

        form.setFieldValue(field.name, valueParam);

        if (handleChange) {
            handleChange(valueParam);
        }
    };

    renderAsRadio() {
        const {
            options,
            form,
            field,
            isFocused,
            inLineControl,
            column,
            gap,
            radioClassNames,
            cControlClassName,
        } = this.props;
        const { touched, errors } = form;
        const hasError = touched[field.name] && errors[field.name];

        return (
            <fieldset
                className={classNames("form-group", {
                    "has-error": touched[field.name] && errors[field.name],
                    "has-focus": isFocused,
                })}>
                <RadioButtonGroup
                    className={radioClassNames}
                    cControlClassName={cControlClassName}
                    inLineControl={inLineControl}
                    name={field.name}
                    selectorId={field.name}
                    onChange={this.handleChange}
                    options={options}
                    value={field.value}
                    column={column}
                    gap={gap}
                />
                {hasError && <FieldError error={errors[field.name]} />}
            </fieldset>
        );
    }

    renderAsCombo() {
        const {
            form,
            field,
            isFocused,
            idForm,
            options,
            searchable,
            value,
            placeholder,
            labelNoMarginTop,
            labelText,
            disabled,
            noLabel,
            multi,
        } = this.props;
        const { touched, errors } = form;
        const { name } = field;
        const hasError = touched[field.name] && errors[field.name];
        const ph = placeholder != null ? placeholder : i18n.get(`${idForm}.${field.name}.placeholder`);

        return (
            <div
                className={classNames("form-group", {
                    "has-error": touched[name] && errors[name],
                    "has-focus": isFocused,
                })}>
                {!noLabel && (
                    <FieldLabel
                        idField={`react-select-${field.name}-input`}
                        {...(labelText ? { labelText } : { labelKey: `${idForm}.${field.name}.label` })}
                        labelNoMarginTop={labelNoMarginTop}
                    />
                )}

                <div className="input-group">
                    <Select
                        id={field.name}
                        searchable={searchable}
                        onChange={this.handleChange}
                        clearable={false}
                        name={name}
                        value={field.value ? field.value : value}
                        options={options}
                        placeholder={ph}
                        disabled={disabled}
                        valueKey="value"
                        multi={multi}
                    />
                </div>
                {hasError && <FieldError error={errors[field.name]} />}
            </div>
        );
    }

    render() {
        const { renderAs } = this.props;

        if (renderAs === "combo") {
            return this.renderAsCombo();
        }
        if (renderAs === "radio") {
            return this.renderAsRadio();
        }

        return null;
    }
}

export default withFocus(Selector);
