import React, { Component } from "react";
import Box from "pages/_components/Box";
import { isDesktop } from "react-device-detect";
import * as Widgets from "pages/desktop/widgets";
import * as stringUtils from "util/string";
import classNames from "classnames";
import ProductsTabs from "./_components/ProductsTabs";

class Products extends Component {
    renderItem = (item) => {
        const Widget = Widgets[stringUtils.capitalizeFirstLetter(item.id)];

        return (
            <Box
                key={item.id}
                iconLabel={item.icon}
                isDesktop={isDesktop}
                keyLabel={item.label ? item.label : `widget.products.${item.id}`}>
                <Widget isDesktop={isDesktop} />
            </Box>
        );
    };

    render() {
        const items = [
            { id: "accounts", icon: "images/products/icon_tab_CC.svg" },
            { id: "creditCards", icon: "images/products/icon_tab_TC.svg" },
            { id: "loans", icon: "images/icons/menu-loans.svg" },
            { id: "deposits", icon: "images/icons/menu-deposits.svg" },
        ];

        return (
            <Box
                className={classNames("background-white", {
                    "px-7 py-5  border-radius-lg box-shadow-small": isDesktop,
                    "px-5 mx-n-5 pt-0": !isDesktop,
                })}>
                <ProductsTabs>{items.map((item) => this.renderItem(item))}</ProductsTabs>
            </Box>
        );
    }
}

export default Products;
