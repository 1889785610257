import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

export const types = {
    CLEAN: "enrollment/CLEAN",

    SAVE_TYPE: "enrollment/SAVE_TYPE",

    PRE_REQUEST: "enrollment/PRE_REQUEST",
    PRE_SUCCESS: "enrollment/PRE_SUCCESS",
    PRE_ERROR: "enrollment/PRE_ERROR",

    VALIDATE_DOCUMENT_REQUEST: "enrollment/VALIDATE_DOCUMENT_REQUEST",
    VALIDATE_DOCUMENT_SUCCESS: "enrollment/VALIDATE_DOCUMENT_SUCCESS",
    VALIDATE_DOCUMENT_ERROR: "enrollment/VALIDATE_DOCUMENT_ERROR",

    VALIDATE_INVITATION_CODE_RESET: "enrollment/VALIDATE_INVITATION_CODE_RESET",
    VALIDATE_INVITATION_CODE_REQUEST: "enrollment/VALIDATE_INVITATION_CODE_REQUEST",
    VALIDATE_INVITATION_CODE_SUCCESS: "enrollment/VALIDATE_INVITATION_CODE_SUCCESS",
    VALIDATE_INVITATION_CODE_ERROR: "enrollment/VALIDATE_INVITATION_CODE_ERROR",
    VALIDATE_INVITATION_CODE_ERROR_REQUIRE_CAPTCHA: "enrollment/VALIDATE_INVITATION_CODE_ERROR_REQUIRE_CAPTCHA",

    VALIDATE_INVITATION_CODE_SMS_REQUEST: "enrollment/VALIDATE_INVITATION_CODE_SMS_REQUEST",
    VALIDATE_INVITATION_CODE_SMS_SUCCESS: "enrollment/VALIDATE_INVITATION_CODE_SMS_SUCCESS",
    VALIDATE_INVITATION_CODE_SMS_ERROR: "enrollment/VALIDATE_INVITATION_CODE_SMS_ERROR",
    VALIDATE_INVITATION_CODE_SMS_ERROR_REQUIRE_CAPTCHA: "enrollment/VALIDATE_INVITATION_CODE_SMS_ERROR_REQUIRE_CAPTCHA",

    GENERATE_VALIDATOR_SMS_REQUEST: "enrollment/GENERATE_VALIDATOR_SMS_REQUEST",
    GENERATE_VALIDATOR_SMS_SUCCESS: "enrollment/GENERATE_VALIDATOR_SMS_SUCCESS",
    GENERATE_VALIDATOR_SMS_ERROR: "enrollment/GENERATE_VALIDATOR_SMS_ERROR",
    GENERATE_VALIDATOR_SMS_RESET: "enrollment/GENERATE_VALIDATOR_SMS_RESET",

    CLEAN_DOCUMENT_NUMBER: "enrollment/CLEAN_DOCUMENT_NUMBER",

    FINISH_CREATE_REQUEST: "enrollment/FINISH_CREATE_REQUEST",
    FINISH_CREATE_SUCCESS: "enrollment/FINISH_CREATE_SUCCESS",
    FINISH_CREATE_ERROR: "enrollment/FINISH_CREATE_ERROR",

    VALIDATE_USER_REQUEST: "enrollment/VALIDATE_USER_REQUEST",
    VALIDATE_USER_SUCCESS: "enrollment/VALIDATE_USER_SUCCESS",
    VALIDATE_USER_ERROR: "enrollment/VALIDATE_USER_ERROR",
    VALIDATE_USER_ERROR_REQUIRE_CAPTCHA: "enrollment/VALIDATE_USER_ERROR_REQUIRE_CAPTCHA",

    FINISH_ASSOCIATE_REQUEST: "enrollment/FINISH_ASSOCIATE_REQUEST",
    FINISH_ASSOCIATE_SUCCESS: "enrollment/FINISH_ASSOCIATE_SUCCESS",
    FINISH_ASSOCIATE_ERROR: "enrollment/FINISH_ASSOCIATE_ERROR",

    VALIDATE_TOKEN_ACTIVATION_SELFIE_REQUEST: "enrollment/VALIDATE_TOKEN_ACTIVATION_SELFIE_REQUEST",
    VALIDATE_TOKEN_ACTIVATION_SELFIE_SUCCESS: "enrollment/VALIDATE_TOKEN_ACTIVATION_SELFIE_SUCCESS",
    VALIDATE_TOKEN_ACTIVATION_SELFIE_ERROR: "enrollment/VALIDATE_TOKEN_ACTIVATION_SELFIE_ERROR",

    VALIDATE_TOKEN_ACTIVATION_DOCUMENT_REQUEST: "enrollment/VALIDATE_TOKEN_ACTIVATION_DOCUMENT_REQUEST",
    VALIDATE_TOKEN_ACTIVATION_DOCUMENT_SUCCESS: "enrollment/VALIDATE_TOKEN_ACTIVATION_DOCUMENT_SUCCESS",
    VALIDATE_TOKEN_ACTIVATION_DOCUMENT_ERROR: "enrollment/VALIDATE_TOKEN_ACTIVATION_DOCUMENT_ERROR",
    SET_ACTIVATE_SOFTTOKEN: "enrollment/SET_ACTIVATE_SOFTTOKEN",
    CLEAR_ACTIVATE_SOFTTOKEN: "enrollment/CLEAR_ACTIVATE_SOFTTOKEN",
    CLEAR_EXCHANGE_TOKEN: "enrollment/CLEAR_EXCHANGE_TOKEN",

    SET_DOCUMENT_TYPE_TOKEN: "token/SET_DOCUMENT_TYPE_TOKEN",
    CLEAR_DOCUMENT_TYPE_TOKEN: "token/CLEAR_DOCUMENT_TYPE_TOKEN",

    SET_CALLBACK_DATA_DOCUMENT: "token/SET_CALLBACK_DATA_DOCUMENT",
    CLEAR_CALLBACK_DATA_DOCUMENT: "token/CLEAR_CALLBACK_DATA_DOCUMENT",

    SET_CALLBACK_DATA_SELFIE: "token/SET_CALLBACK_DATA_SELFIE",
    CLEAR_CALLBACK_DATA_SELFIE: "token/CLEAR_CALLBACK_DATA_SELFIE",
    CLEAR_FETCHING: "token/CLEAR_FETCHING",

    SAVE_USER_INFO: "enrollment/SAVE_USER_INFO",
    SELECT_AVATAR: "enrollment/SELECT_AVATAR",

    SET_ADDITIONAL_ERROR: "enrollment/SET_ADDITIONAL_ERROR",

    SHOW_EXIT_ENROLLMENT_MODAL: "enrollment/SHOW_ENROLLMENT_MODAL",

    VALIDATE_USERNAME_REQUEST: "enrollment/VALIDATE_USERNAME_REQUEST",
    VALIDATE_USERNAME_SUCCESS: "enrollment/VALIDATE_USERNAME_SUCCESS",
    VALIDATE_USERNAME_ERROR: "enrollment/VALIDATE_USERNAME_ERROR",
    SAVE_ANSWER: "enrollment/SAVE_ANSWERS",
    SAVE_QUESTION: "enrollment/SAVE_QUESTIONS",

    GET_QUESTIONS_REQUEST: "enrollment/GET_QUESTIONS_REQUEST",
    GET_QUESTIONS_SUCCESS: "enrollment/GET_QUESTIONS_SUCCESS",
    GET_QUESTIONS_ERROR: "enrollment/GET_QUESTIONS_ERROR",

    SAVE_QUESTION_ANSWERS: "enrollment/SAVE_QUESTION_ANSWERS",

    VALIDATE_USER_CREDENTIALS_REQUEST: "enrollment/VALIDATE_USER_CREDENTIALS_REQUEST",
    VALIDATE_USER_CREDENTIALS_SUCCESS: "enrollment/VALIDATE_USER_CREDENTIALS_SUCCESS",
    VALIDATE_USER_CREDENTIALS_ERROR: "enrollment/VALIDATE_USER_CREDENTIALS_ERROR",

    SAVE_INPUT_QUESTION: "enrollment/SAVE_INPUT_QUESTION",
    SET_ATTEMPT_REFRESH: "enrollment/SET_ATTEMPT_REFRESH",
    SET_ATTEMPT_OTP: "enrollment/SET_ATTEMPT_OTP",
};

export const INITIAL_STATE = {
    corporate: null,
    documentData: null,
    showCaptchaInvitationCode: false,
    showCaptchaValidateUser: false,
    exchangeToken: null,
    name: null,
    userFullName: null,
    documentCountry: null,
    documentType: null,
    documentNumber: null,
    environments: null,
    isMigrated: false,
    invitationKind: null,
    selfie: null,
    documentFrontImage: null,
    documentBackImage: null,
    ocrData: null,
    tokenDocumentType: null,
    fetching: false,
    activateSofttoken: false,
    documentTypeValidation: undefined,
    isFromAuthenticateHandler: false,
    attemptRefresh: 0,
    attemptOtp: 0,

    callbackDataDocument: {
        type: undefined,
        data: undefined,
        redirectSuccess: undefined,
        redirectError: undefined,
        scopeSuccess: [],
        scopeError: [],
    },
    callbackDataSelfie: {
        type: undefined,
        data: undefined,
        redirectSuccess: undefined,
        redirectError: undefined,
        scopeSuccess: [],
        scopeError: [],
        redirectSuccessDocument: undefined,
    },
    isGeneratedValidationCode: false,
    showErrorGeneratedValidationCode: false,
    verificationCodeCreateDate: null,
    newUsername: undefined,
    newPassword: undefined,
    userInfo: {
        email: undefined,
        mobileNumber: undefined,
        firstName: undefined,
        lastName: undefined,
        documentNumber: undefined,
        documentType: undefined,
        username: undefined,
    },
    selectedAvatar: null,
    aditionalMessageError: null,
    showExitModal: false,
    existUsername: false,
    invitationCode: null,
    answers: null,
    question: null,
    questionBack: null,
    hasRegisterQuestions: false,
    questionAndAnswers: null,
    inputs_questions: null,
    isInvitationCodeValid: false,
    documentInvitation: null,
};

const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.CLEAN:
            return INITIAL_STATE;
        case types.SAVE_TYPE:
            return {
                ...state,
                corporate: action.corporate,
            };
        case types.PRE_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.PRE_SUCCESS:
            return {
                ...state,
                documentData: action.documentData,
                showCaptchaInvitationCode: false,
                fetching: false,
            };
        case types.PRE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.VALIDATE_DOCUMENT_SUCCESS:
            return {
                ...state,
                documentNumber: action.documentNumber,
            };
        case types.CLEAN_DOCUMENT_NUMBER:
            return {
                ...state,
                documentNumber: null,
                showCaptchaInvitationCode: false,
                showCaptchaValidateUser: false,
            };
        case types.VALIDATE_INVITATION_CODE_REQUEST:
            return {
                ...state,
                aditionalMessageError: null,
                invitationCode: action.invitationCode,
                isInvitationCodeValid: false,
            };
        case types.VALIDATE_INVITATION_CODE_ERROR_REQUIRE_CAPTCHA:
            return {
                ...state,
                showCaptchaInvitationCode: true,
            };
        case types.VALIDATE_INVITATION_CODE_ERROR:
            return {
                ...state,
                isInvitationCodeValid: false,
            };
        case types.VALIDATE_INVITATION_CODE_SUCCESS:
            return {
                ...state,
                exchangeToken: action.exchangeToken,
                showCaptchaInvitationCode: false,
                showCaptchaValidateUser: false,
                isMigrated: action.isMigrated,
                invitationKind: action.invitationKind,
                environments: action.environments,
                userInfo: action.userInfo,
                aditionalMessageError: null,
                isInvitationCodeValid: true,
                documentInvitation: action.documentInvitation,
            };
        case types.FINISH_CREATE_SUCCESS:
        case types.FINISH_ASSOCIATE_SUCCESS:
            return {
                ...state,
                name: action.name,
            };
        case types.VALIDATE_USER_ERROR_REQUIRE_CAPTCHA:
            return {
                ...state,
                showCaptchaValidateUser: true,
            };
        case types.VALIDATE_USER_SUCCESS:
            return {
                ...state,
                exchangeToken: action.exchangeToken,
                showCaptchaValidateUser: false,
                userFullName: action.userFullName,
                documentCountry: action.userDocumentCountry,
                documentType: action.userDocumentType,
                documentNumber: action.userDocumentNumber,
            };
        case types.VALIDATE_TOKEN_ACTIVATION_SELFIE_REQUEST:
            return {
                ...state,
                selfie: action.selfie,
                fetching: true,
                selfieNonTokenized: action?.selfieNonTokenized,
            };
        case types.VALIDATE_TOKEN_ACTIVATION_SELFIE_SUCCESS:
            return {
                ...state,
                fetching: false,
            };
        case types.VALIDATE_TOKEN_ACTIVATION_SELFIE_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.VALIDATE_TOKEN_ACTIVATION_DOCUMENT_REQUEST:
            return {
                ...state,
                documentFrontImage: action.documentFrontImage,
                documentBackImage: action.documentBackImage,
                tokenDocumentType: action.documentType,
                ocrData: action.ocrData,
                fetching: true,
            };
        case types.VALIDATE_TOKEN_ACTIVATION_DOCUMENT_SUCCESS:
            return {
                ...state,
                documentFrontImage: action.documentFrontImage,
                documentBackImage: action.documentBackImage,
                fetching: false,
            };
        case types.VALIDATE_TOKEN_ACTIVATION_DOCUMENT_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.SET_ACTIVATE_SOFTTOKEN:
            return {
                ...state,
                activateSofttoken: true,
            };
        case types.CLEAR_ACTIVATE_SOFTTOKEN:
            return {
                ...state,
                activateSofttoken: false,
            };
        case types.SET_DOCUMENT_TYPE_TOKEN:
            return {
                ...state,
                documentTypeValidation: action?.documentType,
            };
        case types.CLEAR_DOCUMENT_TYPE_TOKEN:
            return {
                ...state,
                documentTypeValidation: undefined,
            };
        case types.SET_CALLBACK_DATA_DOCUMENT:
            return {
                ...state,
                callbackDataDocument: action?.callbackDataDocument,
            };
        case types.CLEAR_CALLBACK_DATA_DOCUMENT:
            return {
                ...state,
                callbackDataDocument: undefined,
            };
        case types.SET_CALLBACK_DATA_SELFIE:
            return {
                ...state,
                callbackDataSelfie: action?.callbackDataSelfie,
            };
        case types.CLEAR_CALLBACK_DATA_SELFIE:
            return {
                ...state,
                callbackDataSelfie: undefined,
            };
        case types.CLEAR_FETCHING:
            return {
                ...state,
                fetching: false,
            };

        case types.GENERATE_VALIDATOR_SMS_REQUEST:
            return {
                ...state,
                fetching: true,
                isGeneratedValidationCode: false,
                showErrorGeneratedValidationCode: false,
            };
        case types.GENERATE_VALIDATOR_SMS_SUCCESS:
            return {
                ...state,
                fetching: false,
                isGeneratedValidationCode: true,
                showErrorGeneratedValidationCode: false,
                verificationCodeCreateDate: new Date(),
            };
        case types.GENERATE_VALIDATOR_SMS_ERROR:
            return {
                ...state,
                fetching: false,
                isGeneratedValidationCode: false,
                showErrorGeneratedValidationCode: true,
            };
        case types.GENERATE_VALIDATOR_SMS_RESET:
            return {
                ...state,
                fetching: false,
                isGeneratedValidationCode: false,
            };
        case types.SAVE_USER_INFO:
            return {
                ...state,
                newUsername: action?.newUsername,
                newPassword: action?.newPassword,
            };
        case types.SELECT_AVATAR:
            return {
                ...state,
                selectedAvatar: action?.selectedAvatar,
            };
        case types.SET_ADDITIONAL_ERROR:
            return {
                ...state,
                aditionalMessageError: action.aditionalMessageError,
            };
        case types.VALIDATE_INVITATION_CODE_SMS_REQUEST:
            return {
                ...state,
                aditionalMessageError: null,
            };
        case types.SHOW_EXIT_ENROLLMENT_MODAL:
            return {
                ...state,
                showExitModal: action.showExitModal,
            };
        case types.VALIDATE_INVITATION_CODE_SMS_SUCCESS:
            return {
                ...state,
            };
        case types.VALIDATE_USERNAME_SUCCESS:
            return {
                ...state,
                existUsername: action.existUsername,
                fetching: false,
                exchangeToken: action.exchangeToken,
            };
        case types.VALIDATE_USERNAME_ERROR:
            return {
                ...state,
                fetching: false,
            };
        case types.SAVE_ANSWER:
            return {
                ...state,
                answers: action.answers,
            };
        case types.SAVE_QUESTION:
            return {
                ...state,
                question: action.question,
            };
        case types.GET_QUESTIONS_SUCCESS:
            return {
                ...state,
                // questionBack: action.questionBack,
                hasRegisterQuestions: action.hasRegisterQuestions,
            };

        case types.GET_QUESTIONS_REQUEST:
            return {
                ...state,
                fetching: true,
                questionBack: null,
                hasRegisterQuestions: false,
            };
        case types.SAVE_QUESTION_ANSWERS:
            return {
                ...state,
                questionAndAnswers: action.questionAndAnswers,
            };
        case types.VALIDATE_USER_CREDENTIALS_REQUEST:
            return {
                ...state,
                fetching: true,
            };
        case types.SAVE_INPUT_QUESTION:
            return {
                ...state,
                inputs_questions: action.inputs_questions,
            };
        case types.SET_ATTEMPT_REFRESH:
            return {
                ...state,
                attemptRefresh: action.attemptRefresh,
            };
        case types.SET_ATTEMPT_OTP:
            return {
                ...state,
                attemptOtp: action.attemptOtp,
            };
        default:
            return state;
    }
};

export default persistReducer(
    {
        key: "enrollment",
        storage: storageSession,
        blacklist: [],
    },
    reducer,
);

export const actions = {
    clean: () => ({
        type: types.CLEAN,
    }),
    saveEnrollmentType: (corporate) => ({
        type: types.SAVE_TYPE,
        corporate,
    }),
    pre: (callback) => ({
        type: types.PRE_REQUEST,
        callback,
    }),
    validateDocument: (document, formikBag) => ({
        type: types.VALIDATE_DOCUMENT_REQUEST,
        document,
        formikBag,
    }),
    validateInvitationCode: (invitationCode, formik, setValidating) => ({
        type: types.VALIDATE_INVITATION_CODE_REQUEST,
        invitationCode,
        formik,
        setValidating,
    }),
    validateInvitationCodeSMS: (invitationCode, userInfo, formikBag) => ({
        type: types.VALIDATE_INVITATION_CODE_SMS_REQUEST,
        invitationCode,
        formikBag,
        userInfo,
    }),
    generateValidationSMS: (document, action) => ({
        type: types.GENERATE_VALIDATOR_SMS_REQUEST,
        document,
        action,
    }),
    resetValidationSMS: () => ({
        type: types.GENERATE_VALIDATOR_SMS_RESET,
    }),
    cleanDocument: () => ({
        type: types.CLEAN_DOCUMENT_NUMBER,
    }),
    finishCreate: (newUsername, newPassword, newPasswordRepeat, formikBag) => ({
        type: types.FINISH_CREATE_REQUEST,
        newUsername,
        newPassword,
        newPasswordRepeat,
        formikBag,
    }),
    saveUserInfo: (newUsername, newPassword, newPasswordRepeat, formikBag) => ({
        type: types.SAVE_USER_INFO,
        newUsername,
        newPassword,
        newPasswordRepeat,
        formikBag,
    }),
    validateUser: (usernameToValidate, password, captcha, formikBag) => ({
        type: types.VALIDATE_USER_REQUEST,
        usernameToValidate,
        password,
        captcha,
        formikBag,
    }),
    finishAssociate: (callback) => ({
        type: types.FINISH_ASSOCIATE_REQUEST,
        callback,
    }),
    validateTokenActivationSelfie: (selfie, selfieNonTokenized, callbackDataSelfie) => ({
        type: types.VALIDATE_TOKEN_ACTIVATION_SELFIE_REQUEST,
        selfie,
        selfieNonTokenized,
        callbackType: callbackDataSelfie?.type,
        callbackData: callbackDataSelfie?.data,
        redirectSuccess: callbackDataSelfie?.redirectSuccess,
        redirectError: callbackDataSelfie?.redirectError,
        scopeSuccess: callbackDataSelfie?.scopeSuccess,
        scopeError: callbackDataSelfie?.scopeError,
        redirectDocument: callbackDataSelfie?.redirectDocument,
        currentAttempt: callbackDataSelfie?.currentAttempt,
    }),
    validateTokenActivationDocument: (documentSelected, documentScannedList, ocrData, callbackDataDocument) => ({
        type: types.VALIDATE_TOKEN_ACTIVATION_DOCUMENT_REQUEST,
        documentSelected,
        documentScannedList,
        ocrData,
        callbackType: callbackDataDocument?.type,
        callbackData: callbackDataDocument?.data,
        redirectSuccess: callbackDataDocument?.redirectSuccess,
        redirectError: callbackDataDocument?.redirectError,
        scopeSuccess: callbackDataDocument?.scopeSuccess,
        scopeError: callbackDataDocument?.scopeError,
    }),
    setActivateSoftToken: () => ({
        type: types.SET_ACTIVATE_SOFTTOKEN,
    }),
    clearActivateSoftToken: () => ({
        type: types.CLEAR_ACTIVATE_SOFTTOKEN,
    }),
    clearExchangeToken: () => ({
        type: types.CLEAR_EXCHANGE_TOKEN,
    }),
    clearDocumentTypeToken: () => ({
        type: types.CLEAR_DOCUMENT_TYPE_TOKEN,
    }),
    setCallbackDataDocument: (callbackDataDocument) => ({
        type: types.SET_CALLBACK_DATA_DOCUMENT,
        callbackDataDocument,
    }),
    clearCallbackDataDocument: () => ({
        type: types.CLEAR_CALLBACK_DATA_DOCUMENT,
    }),
    setCallbackDataSelfie: (callbackDataSelfie) => ({
        type: types.SET_CALLBACK_DATA_SELFIE,
        callbackDataSelfie,
    }),
    clearCallbackDataSelfie: () => ({
        type: types.CLEAR_CALLBACK_DATA_SELFIE,
    }),
    clearFetching: () => ({
        type: types.CLEAR_FETCHING,
    }),
    saveSelectedAvatar: (selectedAvatar) => ({
        type: types.SELECT_AVATAR,
        selectedAvatar,
    }),
    setAditionalError: (aditionalMessageError) => ({
        type: types.SET_ADDITIONAL_ERROR,
        aditionalMessageError,
    }),
    showExitEnrollmentModal: (showExitModal) => ({
        type: types.SHOW_EXIT_ENROLLMENT_MODAL,
        showExitModal,
    }),
    validateExistUsername: (existUsername, formikBag) => ({
        type: types.VALIDATE_USERNAME_REQUEST,
        existUsername,
        formikBag,
    }),
    saveAnswer: (answers) => ({
        type: types.SAVE_ANSWER,
        answers,
    }),
    saveQuestion: (question) => ({
        type: types.SAVE_QUESTION,
        question,
    }),
    getQuestions: () => ({
        type: types.GET_QUESTIONS_REQUEST,
    }),
    saveQuestionAnswers: (questionAndAnswers) => ({
        type: types.SAVE_QUESTION_ANSWERS,
        questionAndAnswers,
    }),
    validateUserCredentials: (formikBag) => ({
        type: types.VALIDATE_USER_CREDENTIALS_REQUEST,
        formikBag,
    }),
};

export const selectors = {
    isCorporate: ({ enrollment }) => enrollment.corporate,
    getDocumentData: ({ enrollment }) => enrollment.documentData,
    getShowCaptchaInvitationCode: ({ enrollment }) => enrollment.showCaptchaInvitationCode,
    getShowCaptchaValidateUser: ({ enrollment }) => enrollment.showCaptchaValidateUser,
    getExchangeToken: ({ enrollment }) => enrollment.exchangeToken,
    getName: ({ enrollment }) => enrollment.name,
    getUserFullName: ({ enrollment }) => enrollment.userFullName,
    getDocumentCountry: ({ enrollment }) => enrollment.documentCountry,
    getDocumentType: ({ enrollment }) => enrollment.documentType,
    getDocumentNumber: ({ enrollment }) => enrollment.documentNumber,
    getEnvironments: ({ enrollment }) => enrollment.environments,
    isMigrated: ({ enrollment }) => enrollment.isMigrated,
    getInvitationKind: ({ enrollment }) => enrollment.invitationKind,
    isFetching: ({ enrollment }) => enrollment.fetching,
    activateSoftToken: ({ enrollment }) => enrollment.activateSofttoken,
    getDocumentTypeToken: ({ enrollment }) => enrollment?.documentTypeValidation,
    getSelfieImage: ({ enrollment }) => enrollment?.selfie,
    getCallbackDataDocument: ({ enrollment }) => enrollment?.callbackDataDocument,
    getCallbackDataSelfie: ({ enrollment }) => enrollment?.callbackDataSelfie,
    getSelfieNonTokenized: ({ enrollment }) => enrollment?.selfieNonTokenized,
    isGeneratedValidationCode: ({ enrollment }) => enrollment?.isGeneratedValidationCode,
    showErrorGeneratedValidationCode: ({ enrollment }) => enrollment?.showErrorGeneratedValidationCode,
    getNewPassword: ({ enrollment }) => enrollment?.newPassword,
    getNewUsername: ({ enrollment }) => enrollment?.newUsername,
    getUserInfo: ({ enrollment }) => enrollment?.userInfo,
    getSelectedAvatar: ({ enrollment }) => enrollment?.selectedAvatar,
    getAditionalMessageError: ({ enrollment }) => enrollment?.aditionalMessageError,
    getShowEnrollmentModal: ({ enrollment }) => enrollment?.showExitModal,
    getVerificationCreateDate: ({ enrollment }) => enrollment?.verificationCodeCreateDate,
    getIsValidUsername: ({ enrollment }) => enrollment?.existUsername,
    getInvitationCode: ({ enrollment }) => enrollment?.invitationCode,
    getAnswers: ({ enrollment }) => enrollment?.answers,
    getSelectedQuestion: ({ enrollment }) => enrollment?.question,
    hasRegisterQuestions: ({ enrollment }) => enrollment?.hasRegisterQuestions,
    questionAndAnswers: ({ enrollment }) => enrollment?.questionAndAnswers,
    listQuestion: ({ enrollment }) => enrollment?.questionBack,
    getListInputQuestion: ({ enrollment }) => enrollment?.inputs_questions,
    isInvitationCodeValid: ({ enrollment }) => enrollment?.isInvitationCodeValid,
    getDocumentInvitation: ({ enrollment }) => enrollment?.documentInvitation,
    getAttemptRefresh: ({ enrollment }) => enrollment?.attemptRefresh,
    getAttemptOtp: ({ enrollment }) => enrollment?.attemptOtp,
};
