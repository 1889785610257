import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { makeActionCreator } from "util/redux";

export const types = {
    RESET: "changeExpiredPassword/RESET",
    // CHANGE_PASSWORD_INIT_FLOW: "changeExpiredPassword/CHANGE_PASSWORD_INIT_FLOW",
    CHANGE_PASSWORD_STEP_1_REQUEST: "changeExpiredPassword/CHANGE_PASSWORD_STEP_1_REQUEST",
    CHANGE_PASSWORD_STEP_1_SUCCESS: "changeExpiredPassword/CHANGE_PASSWORD_STEP_1_SUCCESS",
    CHANGE_PASSWORD_STEP_2_REQUEST: "changeExpiredPassword/CHANGE_PASSWORD_STEP_2_REQUEST",
    CHANGE_PASSWORD_STEP_2_SUCCESS: "changeExpiredPassword/CHANGE_PASSWORD_STEP_2_SUCCESS",
};
export const INITIAL_STATE = {
    username: null,
    exchangeToken: null,
    loading: false,
    resetCode: null,
    contact: "",
    operationType: "",
    questions: [],
};
const reducer = (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.RESET:
        case types.CHANGE_PASSWORD_STEP_1_REQUEST:
            return { ...INITIAL_STATE };
        case types.CHANGE_PASSWORD_STEP_1_SUCCESS:
            return {
                ...state,
                username: action.username,
                email: action.email,
                exchangeToken: action.exchangeToken,
            };
        default:
            return state;
    }
};
export default persistReducer(
    {
        key: "changeExpiredPassword",
        storage: storageSession,
        blacklist: [],
    },
    reducer,
);
export const actions = {
    reset: makeActionCreator(types.RESET),
    // initChangePasswordFlow: makeActionCreator(types.CHANGE_PASSWORD_INIT_FLOW),
    changePasswordStep1: (username, password, newPassword, newPasswordRepeat, formikBag, onSuccess) => ({
        type: types.CHANGE_PASSWORD_STEP_1_REQUEST,
        username,
        password,
        newPassword,
        newPasswordRepeat,
        formikBag,
        onSuccess,
    }),
    changePasswordStep2: (password, secondFactor, formikBag, onSuccess) => ({
        type: types.CHANGE_PASSWORD_STEP_2_REQUEST,
        password,
        secondFactor,
        formikBag,
        onSuccess,
    }),
};
export const selectors = {
    // getFetching: ({ changeExpiredPassword }) => changeExpiredPassword.fetching,
    getUsername: ({ changeExpiredPassword }) => changeExpiredPassword.username,
    getNotificationType: ({ changeExpiredPassword }) => changeExpiredPassword.notificationType,
    getExchangeToken: ({ changeExpiredPassword }) => changeExpiredPassword.exchangeToken,
    getEmail: ({ changeExpiredPassword }) => changeExpiredPassword.email,
    getIdTransaction: ({ changeExpiredPassword }) => changeExpiredPassword.idTransaction,
    getTypeOperation: ({ changeExpiredPassword }) => changeExpiredPassword.operationType,
    getClientQuestions: ({ changeExpiredPassword }) => changeExpiredPassword.questions,
    getOtpMethod: ({ changeExpiredPassword }) => changeExpiredPassword.otpMethod,
};
