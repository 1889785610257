import React, { useState } from "react";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import { bool, node, string } from "prop-types";
import ImageSlider from "pages/_components/ImageSlider";
import HelpModal from "pages/_components/modal/HelpModal";
import Text from "pages/_components/Text";
import PageTransition from "react-router-page-transition";
import classNames from "classnames";

const LoginFormContainer = ({ children }) => (
    <Box display="flex" fullWidth fullHeight className="login-form" alignY="center">
        <Box display="flex" column fullWidth alignX="center">
            {children}
        </Box>
    </Box>
);

LoginFormContainer.propTypes = {
    children: node.isRequired,
};

const LoginWrapper = (props) => {
    const { isMobile, transition, children, showInfoLinks, showHelpLinks } = props;
    const [showHelpCenterModal, setShowHelpCenterModal] = useState(false);
    const [showHelpCybersecurityModal, setShowHelpCybersecurityModal] = useState(false);

    if (isMobile) {
        return children;
    }

    const closeModals = () => {
        setShowHelpCenterModal(false);
        setShowHelpCybersecurityModal(false);
    };

    return (
        <Box className="login-wrapper l-wrapper" fullWidth>
            <Box className={classNames("login-wrapper-form l-wrapper-f", transition || "")}>
                {!transition ? (
                    <LoginFormContainer>{children}</LoginFormContainer>
                ) : (
                    <PageTransition timeout={600}>
                        <div className="transition-item full-height full-width">
                            <LoginFormContainer>{children}</LoginFormContainer>
                        </div>
                    </PageTransition>
                )}
            </Box>
            {showInfoLinks && (
                <Box className="login-wrapper-info" borderRadius="right-lg">
                    <ImageSlider
                        className="no-mobile"
                        images={["images/login_slider_1.png", "images/login_slider_2.png", "images/login_slider_3.png"]}
                    />
                    {showHelpLinks && (
                        <div className="login-wrapper-info-links">
                            <Button
                                onClick={() => setShowHelpCenterModal(true)}
                                bsStyle="link"
                                defaultLabelText={i18n.get("", "Centro de ayuda")}
                                primaryLink
                                className="px-2"
                                black
                            />
                            <div className="login-wrapper-info-links-divider" />
                            <Button
                                onClick={() => setShowHelpCybersecurityModal(true)}
                                bsStyle="link"
                                defaultLabelText={i18n.get("general.login.form.security.label")}
                                primaryLink
                                className="px-2"
                                black
                            />
                        </div>
                    )}
                </Box>
            )}
            <HelpModal
                modalShow={showHelpCenterModal}
                cancelFunction={closeModals}
                headingText={i18n.get("", "Centro de ayuda")}
                headingIcon="images/iconTitles/security_questions.svg">
                <>
                    <Button
                        externalHref="https://urldefense.proofpoint.com/v2/url?u=https-3A__portaldeayuda.bolivariano.com_si-2Dtengo-2Duna-2Dconsulta-2Dcomo-2Dme-2Dcomunico-2Dcon-2Del-2Dbanco&d=DwMGaQ&c=HnhbG9L9IOYxGhsEeEjNag&r=4Bm3PMNqGwYlyyu7K4SMnS819d6rA6EgYV1MSCJ-N9o&m=A7zoZQnz2LB8e-5TkD6JNO4xCbeGAxKU_5gMoSPQY-jDUZP9YrvO5wMqYqV5gNq4&s=mEJTZ-wm_Utmoh2qhjO0z_UJ-WdbIQMfME0USEH7rpc&e="
                        defaultLabelText={i18n.get("", "¿Cómo ser cliente de banca empresas?")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        // externalHref="#"
                        defaultLabelText={i18n.get("", "¿Es cliente y es la primera vez que ingresa?")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        externalHref="https://urldefense.proofpoint.com/v2/url?u=https-3A__portaldeayuda.bolivariano.com_donde-2Dpuedo-2Dsolicitar-2Dproductos-2Dy-2Dservicios&d=DwMGaQ&c=HnhbG9L9IOYxGhsEeEjNag&r=4Bm3PMNqGwYlyyu7K4SMnS819d6rA6EgYV1MSCJ-N9o&m=A7zoZQnz2LB8e-5TkD6JNO4xCbeGAxKU_5gMoSPQY-jDUZP9YrvO5wMqYqV5gNq4&s=a00E-_8u_IMt7ijnyVLXSghMlsUmsBGYYVNek9WTcQE&e="
                        defaultLabelText={i18n.get("", "¿Donde puedo solicitar productos y servicios?")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        externalHref="https://urldefense.proofpoint.com/v2/url?u=https-3A__reclamos.bolivariano.com_-23_inicio-3Forigen-3Dcliente&d=DwMGaQ&c=HnhbG9L9IOYxGhsEeEjNag&r=4Bm3PMNqGwYlyyu7K4SMnS819d6rA6EgYV1MSCJ-N9o&m=A7zoZQnz2LB8e-5TkD6JNO4xCbeGAxKU_5gMoSPQY-jDUZP9YrvO5wMqYqV5gNq4&s=1zgoppxwJXKL8iluk8TPfj1i4ELqg1n31sB3cFOWv6s&e="
                        defaultLabelText={i18n.get("", "¿Donde puedo ingresar un reclamo?")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Text
                        defaultValue={i18n.get("", "Otras acciones")}
                        className="my-5"
                        color="text-secondary"
                        size="5"
                        align="self-center"
                    />
                    <Button
                        // href="#"
                        defaultLabelText={i18n.get("", "Reenviar datos para primer inicio de sesión")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                </>
            </HelpModal>
            <HelpModal
                modalShow={showHelpCybersecurityModal}
                cancelFunction={closeModals}
                headingText={i18n.get("general.login.form.security.label")}
                headingIcon="images/iconTitles/security_device.svg">
                <>
                    <Button
                        externalHref="https://urldefense.proofpoint.com/v2/url?u=https-3A__landing.bolivariano.com.ec_es_seguritipsbb&d=DwMGaQ&c=HnhbG9L9IOYxGhsEeEjNag&r=4Bm3PMNqGwYlyyu7K4SMnS819d6rA6EgYV1MSCJ-N9o&m=A7zoZQnz2LB8e-5TkD6JNO4xCbeGAxKU_5gMoSPQY-jDUZP9YrvO5wMqYqV5gNq4&s=lyLr36NypBzuA55mbBDW45nlQKwGHIqUUjAHQ_mBMfk&e="
                        defaultLabelText={i18n.get("", "Principales delitos de delincuencia financiera")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        // externalHref="#"
                        defaultLabelText={i18n.get("", "Configure su reconocimiento facial y dactilar")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        externalHref="https://urldefense.proofpoint.com/v2/url?u=https-3A__blog.bolivariano.com.ec_seguritipsbb-2Dblog_conoce-2Dmas-2Dsobre-2Del-2Dsmishing&d=DwMGaQ&c=HnhbG9L9IOYxGhsEeEjNag&r=4Bm3PMNqGwYlyyu7K4SMnS819d6rA6EgYV1MSCJ-N9o&m=A7zoZQnz2LB8e-5TkD6JNO4xCbeGAxKU_5gMoSPQY-jDUZP9YrvO5wMqYqV5gNq4&s=QxmTF4w_NcoAhrVUF_Vzj_u1YwxZC1GNQ0BjJzsdQcY&e="
                        defaultLabelText={i18n.get("", "Conozca más sobre el smishing")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                    <Button
                        externalHref="https://urldefense.proofpoint.com/v2/url?u=https-3A__blog.bolivariano.com.ec_seguritipsbb-2Dblog_consejos-2Dde-2Dseguridad-2Dinform-25C3-25A1tica&d=DwMGaQ&c=HnhbG9L9IOYxGhsEeEjNag&r=4Bm3PMNqGwYlyyu7K4SMnS819d6rA6EgYV1MSCJ-N9o&m=A7zoZQnz2LB8e-5TkD6JNO4xCbeGAxKU_5gMoSPQY-jDUZP9YrvO5wMqYqV5gNq4&s=IZrXLhT4umbpOWFk4HYCS2ymuFmwNq5Nfs7hP5dECME&e="
                        defaultLabelText={i18n.get("", "Consejos de ciberseguridad")}
                        className="px-2"
                        bsStyle="outline"
                        btnUppercase={false}
                        block
                    />
                </>
            </HelpModal>
        </Box>
    );
};

LoginWrapper.propTypes = {
    children: node.isRequired,
    isMobile: bool,
    transition: string,
    showInfoLinks: bool,
    showHelpLinks: bool,
};

LoginWrapper.defaultProps = {
    isMobile: false,
    transition: null,
    showInfoLinks: true,
    showHelpLinks: true,
};

export default LoginWrapper;
