import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { arrayOf, func, number, oneOfType, shape, string } from "prop-types";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import FieldLabel from "pages/_components/fields/FieldLabel";
import FieldError from "pages/_components/fields/FieldError";
import * as i18n from "util/i18n";
import Tooltip from "pages/_components/Tooltip";
import * as secondFactorUtils from "util/secondFactorUtils";
import classNames from "classnames";

const QuestionField = ({
    form,
    field,
    questions,
    spacingInputs,
    maxReloads,
    changeQuestionProp,
    handleAnswerChangeProp,
    credentialType,
    maxAttempts,
    attempts,
    attemptsReload,
}) => {
    const questionUser = questions[0];

    const [originalValue, setOriginalValue] = useState("");

    const handleAnswerChange = (e) => {
        if (e.target.value?.length <= e.target.maxLength) {
            if (field && form) {
                let value = e?.target?.value;
                const originalValueIn = value;
                setOriginalValue(originalValueIn);
                if (credentialType) {
                    value = secondFactorUtils.getSecondFactorFormat(credentialType, value);
                }

                form.setFieldValue(field.name, value);

                if (handleAnswerChangeProp) {
                    handleAnswerChangeProp(originalValueIn);
                }
            }
        }
    };

    const changeQuestion = (e) => {
        if (changeQuestionProp) {
            changeQuestionProp();
        }

        if (e && maxReloads === attemptsReload) {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const fieldHasError = () => {
        if (!form || !form.errors || !field || !field.name) {
            return false;
        }
        const { errors } = form;
        const { name } = field;
        return Object.keys(errors).includes(name);
    };

    const renderError = () => {
        if (!fieldHasError()) {
            return <div />;
        }
        const { errors } = form;
        const { name } = field;
        const message = getAttemptsMessage(errors[name]);
        return <FieldError error={message} />;
    };

    const getAttemptsMessage = (error) => {
        const attempFinal = maxAttempts - attempts;
        let suffix = "s.";
        if (attempFinal == 1) {
            suffix = ".";
        }
        return secondFactorUtils.replaceParametersMessage(error, attempFinal, suffix);
    };

    return (
        <Box className="form-group">
            <FieldLabel idField={`${field.name}_question`} labelText={questionUser} />
            <Row className="p-0">
                <Col xs={10} className={spacingInputs}>
                    <div className="input-group">
                        <input
                            id={`${field.name}_ans`}
                            name={`${field.name}_answer`}
                            className="form-control ellipsis-input"
                            onChange={handleAnswerChange}
                            type="text"
                            value={originalValue}
                            maxLength={60}
                            autoComplete="off"
                        />
                    </div>
                </Col>
                <Col xs={1}>
                    <Tooltip
                        onHover={maxReloads > attemptsReload}
                        forEllipsis
                        inText={i18n.get("", "Solicitar nueva pregunta")}
                        position="top-centered"
                        className="height-fit-content pos-relative change-question-pos"
                        classNameTooltip="login-tooltip">
                        <Button
                            onClick={changeQuestion}
                            bsStyle="only-icon"
                            image="images/sync-btn.svg"
                            imageMd
                            imageColor="primary"
                            className={classNames("m-0 change-question-svg", {
                                "change-question-disabled": maxReloads === attemptsReload,
                            })}
                        />
                    </Tooltip>
                </Col>
            </Row>

            {renderError()}
        </Box>
    );
};

QuestionField.propTypes = {
    form: shape({}).isRequired,
    field: shape({
        onBlur: func,
        onChange: func,
        name: string,
        value: oneOfType([string, string]),
    }).isRequired,
    value: shape({}),
    questions: arrayOf(string).isRequired,
    spacingInputs: string,
    dispatch: func.isRequired,
    maxReloads: number.isRequired,
    changeQuestionProp: func,
    scopeToShow: string,
    handleAnswerChangeProp: func,
    credentialType: string,
    maxAttempts: number,
    attempts: number,
    attemptsReload: number,
};

QuestionField.defaultProps = {
    value: {},
    spacingInputs: "mb-4",
    changeQuestionProp: () => {},
    scopeToShow: "",
    credentialType: null,
    maxAttempts: null,
};

export default QuestionField;
