import React, { useEffect } from "react";

import { Link } from "react-router-dom";
import Box from "pages/_components/Box";
import { format } from "date-fns";
import { connect } from "react-redux";
import classNames from "classnames";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import FormattedAmount from "pages/_components/FormattedAmount";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { func, number, string, oneOf, instanceOf } from "prop-types";
import { parseISOAlternative, formatDate } from "util/date";

const LoanCardView = ({
    idProduct,
    name,
    onClick,
    path,
    productType,
    productTypeTitle,
    reference,
    title,
    amount,
    amountLabel,
    dueDateLabel,
    nextDueDate,
    dispatch,
}) => {
    useEffect(() => {
        dispatch(sessionActions.maskAmountUpdateRequest(idProduct, false));
    }, [idProduct]);

    // const urlLogo = `images/products/logo_${productType}.svg`;
    const urlIcon = `images/products/icon_PA.svg`;

    const parsedDate = parseISOAlternative(nextDueDate);
    const nextDueDateText = formatDate(parsedDate);

    return (
        <Box display="flex" alignX="between" alignY="center" className="prod-item prod-loans" noList>
            <Link
                aria-describedby={idProduct}
                className={classNames("prod-item-link full-width")}
                to={path}
                onClick={() => onClick()}>
                <Box fullWidth>
                    <Box className={classNames("prod-item-wrapper")} alignX="between" fullWidth gap="4" flex1>
                        <Box display="flex" alignY="flex-start" className="prod-item-info-wrapper mr-auto">
                            <Box className="item-info-wrapper">
                                <Image src={urlIcon} wrapperClassName="item-info-icon" />
                                <Text
                                    align="left"
                                    color="text"
                                    size="5"
                                    bold
                                    className="mt-1"
                                    ariaLabel={`${name} - ${title} - ${reference}`}
                                    ellipsis>
                                    {productTypeTitle}
                                </Text>
                            </Box>
                        </Box>
                        <Box align="left">
                            <Text
                                align="left"
                                color="text"
                                size="6"
                                className="mt-1"
                                ariaLabel={`${name} - ${title} - ${reference}`}
                                ellipsis>
                                {reference}
                            </Text>
                        </Box>
                        <Box display="flex" className="prod-item-amount-wrapper mt-3" flex1>
                            <Box display="flex" alignY="center">
                                <Text
                                    align="left"
                                    color="text"
                                    size="7"
                                    className="mt-1"
                                    ariaLabel={`${name} - ${title} - ${reference}`}
                                    ellipsis>
                                    {dueDateLabel}
                                </Text>
                            </Box>
                            <Box display="flex" className="ml-auto">
                                <Text
                                    align="right"
                                    color="text"
                                    size="7"
                                    className="mt-1"
                                    ariaLabel={`${name} - ${title} - ${reference}`}
                                    ellipsis>
                                    {nextDueDateText}
                                </Text>
                            </Box>
                        </Box>
                        <Box id={idProduct} display="flex" className="prod-item-amount-wrapper mt-3" flex1>
                            <Box display="flex" alignY="center">
                                <Text
                                    align="left"
                                    color="text"
                                    size="7"
                                    className="mt-1"
                                    ariaLabel={`${name} - ${title} - ${reference}`}
                                    ellipsis>
                                    {amountLabel}
                                </Text>
                            </Box>
                            <Box display="flex" className="ml-auto">
                                <FormattedAmount
                                    color="text"
                                    idProduct={idProduct}
                                    quantity={amount}
                                    noCurrency
                                    className="prod-item-amount-value"
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Link>
        </Box>
    );
};

LoanCardView.defaultProps = {
    onClick: () => {},
};

LoanCardView.propTypes = {
    idProduct: string.isRequired,
    name: string.isRequired,
    onClick: func.isRequired,
    path: string.isRequired,
    productType: string.isRequired,
    productTypeTitle: string.isRequired,
    reference: string.isRequired,
    title: string.isRequired,
    amount: number.isRequired,
    amountLabel: string.isRequired,
    dueDateLabel: string.isRequired,
    nextDueDate: oneOf(instanceOf(Date), string, number).isRequired,
    dispatch: func.isRequired,
};

const mapStateToProps = (state) => ({
    productsMaskAmount: sessionSelectors.getMaskAmountUpdate(state),
});

export default connect(mapStateToProps)(LoanCardView);
