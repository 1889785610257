import * as API from "middleware/api";

export const changeExpiredPassStep1 = (username, password, newPassword, newPasswordRepeat) =>
    API.executeAnonymous("/session.changeExpiredPassword.step1", {
        _username: username,
        _password: password,
        _newPassword: newPassword,
        _newPasswordRepeat: newPasswordRepeat,
    });
export const changeExpiredPassStep2 = (password, secondFactor) =>
    API.executeAnonymous("/session.changeExpiredPassword.step2", {
        _newPassword: password,
        _secondFactor: secondFactor,
    });
