import React, { useEffect } from "react";
import Notification from "pages/_components/Notification";
import Box from "pages/_components/Box";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { bool, func, shape, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { routerActions } from "react-router-redux/actions";
import Text from "pages/_components/Text";
import Image from "pages/_components/Image";
import Row from "pages/_components/Row";
import Button from "pages/_components/Button";
import {
    actions as secondFactorActions,
    selectors as secondFactorSelectors,
} from "reducers/secondFactor";
import { selectors as sessionSelector } from "reducers/session";
import { simpleMaskEmail } from "util/format";
import classNames from "classnames";
import { push } from "react-router-redux";

const SoftTokenSuccess = ({ email, isDesktop, dispatch, getSecurityQuestions, isFetching,
    registeredQuestions, goToSecurityQuestions, goToHome, handleBack}) => {

    useEffect(() => {
        getSecurityQuestions();
    }, []);

    const renderHeader = () => (
        <>
            <Notification scopeToShow="softTokenTermsAndConditions" />
            <Head onBack={handleBack} title="settings.softToken" textBack="softToken" />
        </>
    );


    const hasSecurityQuestions = registeredQuestions && Object.keys(registeredQuestions).length > 0;

    return (
        <>
            {renderHeader()}
            <MainContainer showLoader={isFetching}
                className={classNames("main-container", {
                    "background-white display-flex flex-column justify-content-between full-height": !isDesktop,
                })}>
                <Box
                    background="white"
                    borderRadius="lg"
                    className={classNames("mt-3 ", {
                        "p-7 box-shadow-small": isDesktop,
                        "pt-9": !isDesktop,
                    })}>
                    <Box display="flex" alignX="center" className="mb-8">
                        <Box className="box-image-success">
                            <Image src="images/util/success.gif" />
                        </Box>
                    </Box>
                    <Box className="text-center mb-7">
                        <Text
                            size={isDesktop ? "3" : "2"}
                            labelKey="softToken.activate.success.title"
                            bold
                            className={classNames({
                                "line-height-125": isDesktop,
                                "line-height-15": !isDesktop,
                            })}
                        />
                    </Box>
                    <Box className="text-center mb-7">
                        <Text
                            size={isDesktop ? "7" : "5"}
                            labelKey="softToken.activate.success.info"
                            light
                            className={classNames({
                                "line-height-125": isDesktop,
                                "line-height-15": !isDesktop,
                            })}
                        />
                        <Box className="mt-2">
                            <Text size={isDesktop ? "6" : "5"} bold>
                                {simpleMaskEmail(email)}
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <Row
                    className={classNames("mt-7 mb-7 row-template-center", {
                        "d-flex": !isDesktop,
                    })}>
                    { hasSecurityQuestions ?
                        <Col xs={3} xsOffset={4}>
                            <Button
                                type="button"
                                bsStyle="primary"
                                label="global.goToHome"
                                btnUppercase={false}
                                onClick={goToHome}
                                block
                            />
                        </Col>
                    :
                        <Col xs={3} xsOffset={4}>
                            <Button
                                type="button"
                                bsStyle="primary"
                                label="softToken.activate.success.btn.questions"
                                btnUppercase={false}
                                onClick={goToSecurityQuestions}
                                block
                            />

                            <Button
                                type="button"
                                bsStyle="outline"
                                label="global.goToHome"
                                btnUppercase={false}
                                onClick={goToHome}
                                block
                            />
                        </Col>
                    }
                </Row>
            </MainContainer>
        </>
    );
};

SoftTokenSuccess.propTypes = {
    dispatch: func.isRequired,
    getSecurityQuestions: func.isRequired,
    goToSecurityQuestions: func.isRequired,
    goToHome: func.isRequired,
    handleBack: func.isRequired,
    email: string.isRequired,
    isDesktop: bool.isRequired,
    isFetching: bool,
    registeredQuestions: shape({}),
};

SoftTokenSuccess.defaultProps = {
    isFetching: false,
    registeredQuestions: undefined,
};

const mapDispatchToProps = (dispatch) => ({
    getSecurityQuestions: () => {
        dispatch(
            secondFactorActions.secondFactorVerificationQuestionsRequest({
                exchangeToken: undefined,
                scopeToShow: "",
            }),
        );
    },
    goToSecurityQuestions: () => {
        dispatch(routerActions.push("/securityQuestions"));
    },
    goToHome: () => {
        dispatch(push("/desktop"));
    },
    handleBack: () => {
        dispatch(push("/settings/authenticatorHandler"));
    },
});

const mapStateToProps = (state) => ({
    email: sessionSelector.getEmail(state),
    registeredQuestions: secondFactorSelectors.getQuestions(state),
    isFetching: secondFactorSelectors.isFetching(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(SoftTokenSuccess);
