import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, func, number, shape, string } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import * as secondFactorUtils from "util/secondFactorUtils";
import { isMobileNative } from "util/device";
import FieldError from "../FieldError";

const PinInput = ({
    inputLenght,
    form,
    field,
    className,
    noMarginBottom,
    isMasked,
    inputMode,
    handleChangeProp,
    labelKey,
    defaultLabelText,
    autoFocus,
    credentialType,
    maxAttempts,
    attempts,
}) => {
    const [pin, setPin] = useState(new Array(inputLenght).fill(""));

    const [isVisible, setVisible] = useState(false);
    const inputRefs = useRef([]);

    const handleChange = (element, index) => {
        const valueInput = element?.value && element.value !== "" ? [...element.value][0] : "";

        // Only numbers validation
        if (!Number.isNaN(Number(element.value))) {
            // mobile focus next input
            if (isMobileNative) {
                const nextIndex = index + 1;
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }

            const resultPin = [...pin].map((item, idx) => (idx === index ? valueInput : item));
            setPin(resultPin);
            let valuePin = resultPin.reduce((acc, item) => acc + item, "");
            const originalValuePin = valuePin;
            if (credentialType) {
                valuePin = secondFactorUtils.getSecondFactorFormat(credentialType, valuePin);
            }
            form.setFieldValue(field.name, valuePin);
            if (handleChangeProp) {
                handleChangeProp(originalValuePin);
            }
        }
    };

    const fieldHasError = () => {
        if (!form || !form.errors || !field || !field.name) {
            return false;
        }
        const { errors } = form;
        const { name } = field;
        return Object.keys(errors).includes(name);
    };

    const renderError = () => {
        if (!fieldHasError()) {
            return <div />;
        }
        const { errors } = form;
        const { name } = field;
        const message = getAttemptsMessage(errors[name]);
        return <FieldError error={message} />;
    };

    const getAttemptsMessage = (error) => {
        const attempFinal = maxAttempts - attempts;
        let suffix = "s.";
        if (attempFinal == 1) {
            suffix = ".";
        }
        return secondFactorUtils.replaceParametersMessage(error, attempFinal, suffix);
    };

    const preventSpace = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    const handleFocus = (e) => {
        e.target.select();
    };

    const handleKeyUp = (e) => {
        if ((e.key >= "0" && e.key <= "9") || e.key === "Backspace") {
            if (e.target.nextSibling && e.target.value !== "" && e.key !== "Tab" && e.Key !== "Shift") {
                if (!isMobileNative) {
                    e.target.nextSibling.focus();
                }
            }

            if (e.target.previousSibling && e.key === "Backspace" && e.key !== "Tab" && e.Key !== "Shift") {
                e.target.previousSibling.focus();
            }
        }
    };

    const toggleIsVisible = () => {
        setVisible((prevState) => !prevState);
    };

    useEffect(() => {
        pin.forEach((_item, index) => {
            document.getElementById(`pin_${index}`).addEventListener("paste", (e) => {
                e.preventDefault();
            });
        });
    }, []);

    return (
        <Box
            className={classNames("form-group input-second-factor", className, {
                "has-error": fieldHasError(),
            })}>
            {(labelKey || defaultLabelText) && (
                <Text size="2" bold labelKey={labelKey} defaultValue={defaultLabelText} />
            )}
            <Box
                display="flex"
                className={classNames("height-auto", {
                    "mb-0": noMarginBottom,
                    "mb-10 mb-md-8": !noMarginBottom,
                    "mt-3": labelKey || defaultLabelText,
                })}>
                {pin.map((data, index) => (
                    <input
                        id={`pin_${index}`}
                        className={classNames("form-control input", { "mask-input": !isVisible })}
                        type={!isVisible ? "password" : "text"}
                        name="pin"
                        placeholder="-"
                        maxLength="1"
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        value={data}
                        onChange={(e) => handleChange(e.target, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                        onKeyPress={preventSpace}
                        onFocus={handleFocus}
                        onKeyUp={handleKeyUp}
                        inputMode={inputMode}
                        pattern="^[0-9]*$"
                        autoFocus={autoFocus && index === 0}
                    />
                ))}
                {isMasked && (
                    <Button
                        className="btn-only-icon"
                        bsStyle="link"
                        block={false}
                        onClick={toggleIsVisible}
                        ariaLabel={
                            isVisible
                                ? "login.step1.secondFactor.maskPassword.a11y"
                                : "login.step1.secondFactor.unmaskPassword.a11y"
                        }>
                        <Image
                            src={isVisible ? "images/icons/showPass.svg" : "images/icons/hidePass.svg"}
                            className="svg-icon"
                            color="primary-color-i"
                        />
                    </Button>
                )}
            </Box>
            {renderError()}
        </Box>
    );
};

PinInput.propTypes = {
    className: string,
    field: shape({}).isRequired,
    form: shape({}).isRequired,
    inputLenght: number,
    isMasked: bool,
    noMarginBottom: bool,
    inputMode: string,
    handleChangeProp: func.isRequired,
    labelKey: string,
    defaultLabelText: string,
    autoFocus: bool,
    credentialType: string,
    maxAttempts: number,
    attempts: number,
};

PinInput.defaultProps = {
    className: "mb-10 mb-md-8",
    inputLenght: 4,
    isMasked: false,
    noMarginBottom: false,
    inputMode: "numeric",
    labelKey: null,
    defaultLabelText: null,
    autoFocus: false,
    credentialType: null,
};

export default PinInput;
