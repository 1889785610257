import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import * as config from "util/config";
import Button from "pages/_components/Button";
import { Col } from "react-bootstrap";
import DateField from "pages/_components/fields/DateField";
import Selector from "pages/_components/fields/formik/Selector";
import classNames from "classnames";
import * as i18n from "util/i18n";
import moment from "moment";
import { arrayOf, bool, func, instanceOf, shape } from "prop-types";
import { connect } from "react-redux";
import { actions as depositsActions, selectors as depositsSelectors } from "reducers/deposits";
import { differenceInDays } from "date-fns";
import FieldError from "pages/_components/fields/FieldError";

const FORM_ID = "accounts.movements.filters";

const FormFilter = ({
    dispatch,
    minDate,
    maxDate,
    handleFilter,
    handleCancel,
    isDesktop,
    depositTypes,
    depositStatus,
    depositFilters,
}) => {
    const [errorDate, setErrorDates] = useState(false);
    const maxRangeDates = config.getInteger("deposits.filters.maxRangeDays", "30");
    const minDateFrom = minDate;
    const minDateTo = minDate;
    const maxDateTo = maxDate;
    const optionsDate = [
        {
            value: "lastSeven",
            label: i18n.get("accounts.movements.filters.searchBy.lastSeven"),
        },
        {
            value: "lastThirty",
            label: i18n.get("accounts.movements.filters.searchBy.lastThirty"),
        },
        {
            value: "period",
            label: i18n.get("accounts.movements.filters.searchBy.period"),
        },
    ];

    const validateFilterBtn = (formik) => {
        if (!formik.values.selectedDate || !formik.values.status || !formik.values.depositType || errorDate) {
            return true;
        }
        return false;
    };

    const handleChangeSelect = (option, { values }) => {
        if (option !== "period") {
            setErrorDates(false);
        } else {
            const { dateFrom, dateTo } = values;
            const differenceDays = differenceInDays(dateTo, dateFrom);
            setErrorDates(differenceDays > maxRangeDates);
        }
    };

    const handleChangeDateFrom = (dateFrom, { values }) => {
        const { dateTo } = values;
        const differenceDays = differenceInDays(dateTo, dateFrom.toDate());
        setErrorDates(differenceDays > maxRangeDates);
    };

    const handleChangeDateTo = (dateTo, { values }) => {
        const { dateFrom } = values;
        const differenceDays = differenceInDays(dateTo.toDate(), dateFrom);
        setErrorDates(differenceDays > maxRangeDates);
    };

    return (
        <Formik
            initialValues={{
                ...depositFilters,
            }}
            onSubmit={(values) => {
                dispatch(depositsActions.setDepositFilterData(values));
                dispatch(depositsActions.listDeposits());
                handleFilter();
            }}>
            {(formik) => (
                <Form className="deposit-filter-container select-label-text-bold">
                    <Box column fullWidth className={classNames("deposits-content-filers mb-5")}>
                        <Row>
                            <Col lg={6} sm={12}>
                                <Field
                                    component={Selector}
                                    options={depositStatus}
                                    name="status"
                                    placeholder="Seleccionar"
                                    labelText={i18n.get("deposits.filter.status")}
                                />
                            </Col>
                            <Col lg={6} sm={12} className="depostit-type">
                                <Field
                                    component={Selector}
                                    options={depositTypes}
                                    name="depositType"
                                    placeholder="Seleccionar"
                                    labelText={i18n.get("deposits.filter.type.deposit")}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Field
                                component={Selector}
                                options={optionsDate}
                                idForm={FORM_ID}
                                name="selectedDate"
                                placeholder="Seleccionar"
                                labelText={i18n.get("deposits.filter.expiration.date")}
                                handleChange={(selected) => handleChangeSelect(selected, formik)}
                            />
                        </Row>
                        {formik.values.selectedDate && formik.values.selectedDate === "period" && (
                            <>
                                <Row
                                    gapY="0"
                                    className={classNames("px-0", {
                                        "d-flex flex-column": !isDesktop,
                                    })}>
                                    <Col xs={6}>
                                        <Field
                                            component={DateField}
                                            endDate={formik.values.dateTo}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="dateFrom"
                                            selectsStart
                                            startDate={formik.values.dateFrom}
                                            // handleChange={this.handleChangeDateFrom}
                                            popperPlacement="bottom"
                                            popperModifiers={{
                                                flip: {
                                                    behavior: ["bottom"], // don't allow it to flip to be above
                                                },
                                            }}
                                            {...(minDateFrom && { minDate: minDateFrom })}
                                            {...(formik.values.dateTo && { maxDate: formik.values.dateTo })}
                                            handleChange={(dateSelect) => handleChangeDateFrom(dateSelect, formik)}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <Field
                                            component={DateField}
                                            endDate={formik.values.dateTo}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="dateTo"
                                            selectsEnd
                                            startDate={formik.values.dateFrom}
                                            popperPlacement="bottom"
                                            popperModifiers={{
                                                flip: {
                                                    behavior: ["bottom"], // don't allow it to flip to be above
                                                },
                                            }}
                                            {...(maxDateTo && { maxDate: maxDateTo })}
                                            // eslint-disable-next-line no-nested-ternary
                                            {...(formik.values.dateFrom
                                                ? { minDate: formik.values.dateFrom }
                                                : minDateTo
                                                ? { minDate: minDateTo }
                                                : moment().add(-6, "months"))}
                                            handleChange={(dateSelect) => handleChangeDateTo(dateSelect, formik)}
                                        />
                                    </Col>
                                </Row>
                                {errorDate && (
                                    <Box className="my-3 filter-content-error">
                                        {" "}
                                        <FieldError
                                            error={i18n.get("deposits.filter.dates.max.range", null, {
                                                days: maxRangeDates,
                                            })}
                                        />
                                    </Box>
                                )}
                            </>
                        )}
                    </Box>

                    <Box display="flex" column fullWidth className={classNames("mb-5")}>
                        <Row>
                            {!isDesktop ? (
                                <>
                                    <Col lg={6} sm={12}>
                                        <Button
                                            type="submit"
                                            label="product.filters.filter"
                                            bsStyle="primary"
                                            disabled={validateFilterBtn(formik)}
                                            block
                                        />
                                    </Col>
                                    <Col lg={6} sm={12}>
                                        <Button
                                            type="button"
                                            label="global.cancel"
                                            bsStyle="outline"
                                            onClick={() => {
                                                dispatch(depositsActions.resetDepositFilters());
                                                dispatch(depositsActions.listDeposits());
                                                handleCancel();
                                            }}
                                            block
                                        />
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col lg={6} sm={12}>
                                        <Button
                                            type="button"
                                            label="global.cancel"
                                            bsStyle="outline"
                                            onClick={() => {
                                                dispatch(depositsActions.resetDepositFilters());
                                                dispatch(depositsActions.listDeposits());
                                                handleCancel();
                                            }}
                                            block
                                        />
                                    </Col>
                                    <Col lg={6} sm={12}>
                                        <Button
                                            type="submit"
                                            label="product.filters.filter"
                                            bsStyle="primary"
                                            disabled={validateFilterBtn(formik)}
                                            block
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

FormFilter.propTypes = {
    minDate: instanceOf(Date),
    maxDate: instanceOf(Date),
    initialData: shape({}).isRequired,
    handleFilter: func.isRequired,
    handleCancel: func.isRequired,
    isDesktop: bool.isRequired,
    depositTypes: arrayOf(shape({})).isRequired,
    depositStatus: arrayOf(shape({})).isRequired,
    depositFilters: arrayOf(shape({})).isRequired,
    dispatch: func.isRequired,
};

FormFilter.defaultProps = {
    minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
    maxDate: new Date(),
};

const mapStateToProps = (state) => ({
    deposits: depositsSelectors.getDeposits(state),
    depositTypes: depositsSelectors.getDepositTypes(state),
    depositStatus: depositsSelectors.getDepositStatus(state),
    depositFilters: depositsSelectors.getDepositFilters(state),
});

export default connect(mapStateToProps)(FormFilter);
