import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, oneOfType, shape, string, number as typeNumber } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Tooltip from "pages/_components/Tooltip";
import CircularProgressBar from "pages/_components/CircularProgressBar";
import FormattedAmount from "pages/_components/FormattedAmount";

const generateCircle = (rate, status) => (
    <div className="circular-progressBar">
        <CircularProgressBar percentage={rate} status={status} />
    </div>
);

const DepositWidget = ({
    id,
    isDesktop,
    path,
    onClick,
    totalAmount,
    currencyDescription,
    productType,
    number,
    percentDetail,
    expirationDate,
    rate,
    status,
}) => {
    const Component = path ? Link : Box;

    return (
        <Component
            {...(path && { to: path })}
            {...(!path &&
                onClick && {
                    onClick: () => {
                        onClick();
                    },
                })}>
            <Box id={id} className="prod-item prod-accounts m-3 m-3">
                <Box display="flex" alignY="flex-start" fullWidth>
                    <Box display="flex" alignY="center" fullWidth>
                        <Box>
                            <Image src="images/products/icon_deposit.svg" className="credit-card-logo" />
                        </Box>
                        {/* <Box display="flex" alignX="end" alignY="center" className="pb-1" fullWidth>
                            <Text size={isDesktop ? "7" : "6"} light>
                                {percentDetail}
                            </Text>
                        </Box> */}
                    </Box>
                </Box>
                <Box display="flex" alignX="flex-start" fullWidth>
                    <Box display="flex" alignY="center" fullWidth>
                        <Box display="flex" column alignX="start" fullWidth>
                            <Text size={"5"} bold className="text-left">
                                {productType}
                            </Text>
                            <Box className="mt-2">
                                <Text size={isDesktop ? "6" : "5"} light>
                                    {number}
                                </Text>
                            </Box>
                        </Box>
                        <Box
                            className={
                                isDesktop
                                    ? "pr-3 d-grid max-width-105rem circle-widget-tooltip"
                                    : "max-width-2 circle-widget-tooltip"
                            }>
                            <Tooltip
                                id={id}
                                onHover
                                forEllipsis
                                inText={
                                    <Text
                                        labelKey="widgets.deposits.myDeposits.tooltip.text"
                                        EXPIRATION_DATE={expirationDate}
                                        size={isDesktop ? "6" : "5"}
                                        light
                                    />
                                }
                                position="top-right"
                                className="align-self-center height-fit-content">
                                {generateCircle(rate, status)}
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" fullWidth className="mt-2">
                    <Box display="flex" alignY="center" fullWidth>
                        <Box display="flex" alignX="start" className="pr-2" fullWidth>
                            <Text size={isDesktop ? "7" : "6"} color="black" light>
                                {currencyDescription}
                            </Text>
                        </Box>
                        <Box display="flex" alignX="end">
                            <FormattedAmount
                                quantity={totalAmount}
                                noCurrency
                                className={isDesktop ? "text-semibold size-4" : "text-semibold size-2"}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Component>
    );
};

DepositWidget.propTypes = {
    id: string,
    isDesktop: bool.isRequired,
    onClick: func,
    path: oneOfType([string, shape({ pathname: string })]),
    totalAmount: typeNumber,
    productType: string,
    number: string,
    rate: typeNumber,
    currencyDescription: string,
    toolTipDescription: string,
    productDetail: string,
    percentDetail: string,
    expirationDate: string,
    status: string.isRequired,
};

DepositWidget.defaultProps = {
    id: null,
    onClick: () => {},
    path: null,
    totalAmount: null,
    productType: "",
    number: "",
    rate: 0,
    currencyDescription: "",
    toolTipDescription: "",
    productDetail: "",
    percentDetail: "",
    expirationDate: "",
};

export default resizableRoute(DepositWidget);
