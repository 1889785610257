import * as secondFactor from "middleware/secondFactor";
import {
    types as secondFactorTypes,
    actions as secondFactorActions,
    selectors as secondFactorSelectors,
} from "reducers/secondFactor";
import { softTokenTypes, selectors as softTokenSelectors, actions as softTokenActions } from "reducers/softToken";
import { actions as notificationActions } from "reducers/notification";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as i18n from "util/i18n";
import { isMobileNativeFunc } from "util/device";
import { ENT000, hasRegisteredIdentity } from "util/softToken.util";
import { push, replace } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";

const sagas = [
    takeLatest(secondFactorTypes.SECOND_FACTOR_PREVIEW_REQUEST, secondFactorPreview),
    takeLatest(secondFactorTypes.SECOND_FACTOR_VERIFICATION_CODE_REQUEST, secondFactorVerificationCode),
    takeLatest(secondFactorTypes.SECOND_FACTOR_VERIFICATION_QUESTIONS_REQUEST, secondFactorVerificationQuestions),
    takeLatest(secondFactorTypes.SECOND_FACTOR_STATUS_TOKEN_REQUEST, secondFactorStatusToken),
];

export default sagas;

function* showGenericError(redirectError, scopeError, messageError) {
    if (redirectError) {
        yield put(push(redirectError));
    }

    yield put(
        notificationActions.showNotification(
            messageError || i18n.get("secondFactor.generic.error.message"),
            "error",
            [scopeError] || [],
            false,
        ),
    );
}

function* secondFactorPreview({ preview }) {
    const { idActivity, exchangeToken, scopeToShow } = preview;

    const deviceUUID = window?.app?.getDeviceUUID() || "";
    const deviceModel = window?.device?.model || "";
    const deviceBrand = window?.device?.manufacturer || "";

    let hasTokenSeed = false;
    if (isMobileNativeFunc()) {
        hasTokenSeed = yield select(secondFactorSelectors.getUserHasToken);
        console.log("HasTokenSeed (from selector): ", hasTokenSeed);
        if (!hasTokenSeed) {
            hasTokenSeed = yield call(validateUserToken);
            console.log("HasTokenSeed (from entrust SDK): ", hasTokenSeed);
        }
    }

    const response = yield call(
        secondFactor.secondFactorPreview,
        deviceUUID,
        deviceModel,
        deviceBrand,
        hasTokenSeed,
        idActivity,
        exchangeToken,
    );
    if (response?.data?.code === "API710W") {
        yield put(routerActions.replace("/deviceRequired"));
        return;
    }
    if (!response?.type || !response?.data?.data) {
        yield put({
            type: secondFactorTypes.SECOND_FACTOR_PREVIEW_FAILURE,
        });
        yield;
        return;
    }
    const { type, data } = response;
    if (type === "W") {
        yield put({
            type: secondFactorTypes.SECOND_FACTOR_PREVIEW_FAILURE,
        });
        yield;
        return;
    }
    const { credentials, _email, _mobileNumber, attempts } = data?.data;
    if (!credentials) {
        yield put({
            type: secondFactorTypes.SECOND_FACTOR_PREVIEW_FAILURE,
        });
        yield;
        return;
    }
    if (_email) {
        yield put({
            type: secondFactorTypes.SECOND_FACTOR_SET_CONTACT_OTP,
            email: _email,
            mobileNumber: _mobileNumber,
        });
    }
    yield put(secondFactorActions.secondFactorPreviewSuccess(credentials, attempts));
}

function* secondFactorVerificationCode({ preview }) {
    const { exchangeToken } = preview;
    const response = yield call(secondFactor.secondFactorVerificationCode, exchangeToken);
    if (!response?.type || !response?.data?.data) {
        yield put({
            type: secondFactorTypes.SECOND_FACTOR_VERIFICATION_CODE_FAILURE,
        });
        yield;
        return;
    }
    const { type, data } = response;
    if (type === "W") {
        yield put({
            type: secondFactorTypes.SECOND_FACTOR_VERIFICATION_CODE_FAILURE,
        });
        yield;
        return;
    }

    yield put(secondFactorActions.secondFactorVerificationCodeSuccess());
}

function* secondFactorVerificationQuestions({ preview, isChange = false }) {
    const { exchangeToken, scopeToShow } = preview;
    const response = yield call(secondFactor.secondFactorVerificationQuestions, exchangeToken, isChange);
    if (!response?.type || !response?.data?.data) {
        yield put({
            type: secondFactorTypes.SECOND_FACTOR_VERIFICATION_QUESTIONS_FAILURE,
        });
        yield;
        return;
    }
    const { type, data } = response;
    if (type === "W") {

        if (data?.code === "API711W") {
            yield put({
                type: secondFactorTypes.SECOND_FACTOR_VERIFICATION_QUESTIONS_FAILURE,
            });
            notificationActions.showNotification(
                i18n.get("secondFactor.credential.question.attempt.change.message"),
                "warning",
                [scopeToShow] || [],
                false,
            )
            return;
        }

        // Se corrige para que solo sea para anonimas esta redireccion, sino no deja ingresar a desbloqueo de token y saca al /
        if (exchangeToken && data?.code === "API705W") {
            yield put(replace("/"));
        }
        /*
        yield put({
            type: secondFactorTypes.SECOND_FACTOR_VERIFICATION_QUESTIONS_FAILURE,
            error : data?.data.message
        });
        */
        yield call(showGenericError, undefined, scopeToShow, data?.data.message);
        yield;
        return;
    }

    const { questions, attemptsChangeQuestion } = data?.data;
    if (!questions) {
        yield put({
            type: secondFactorTypes.SECOND_FACTOR_PREVIEW_FAILURE,
        });
        yield;
        return;
    }
    yield put(secondFactorActions.secondFactorVerificationQuestionsSuccess(questions,attemptsChangeQuestion));
}

function* secondFactorStatusToken({ preview }) {
    const { exchangeToken } = preview;
    yield put(softTokenActions.getStatusTokenRequest({ exchangeToken }));

    if (!isMobileNativeFunc()) {
        return;
    }
    const userHasToken = yield call(validateUserToken);
    if (userHasToken) {
        console.log("secondFactorStatusToken -- Seteando user hasToken: ", userHasToken);
        yield put(secondFactorActions.secondFactorStatusTokenSuccess(userHasToken));
    }
}

function* validateUserToken() {
    const hasTokenResponse = yield call(hasRegisteredIdentity);
    console.log("validateUserToken hasTokenResponse: ", hasTokenResponse);
    if (!hasTokenResponse) {
        return;
    }

    const { code: codeIdentity, data: dataIdentity } = JSON.parse(hasTokenResponse);
    if (!codeIdentity || !dataIdentity || codeIdentity !== ENT000 || dataIdentity !== "true") {
        return;
    }

    console.log("validateUserToken dataIdentity: ", dataIdentity);
    return dataIdentity;
}
