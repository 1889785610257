/* eslint-disable react/prop-types  */
/* eslint-disable no-unused-vars  */
/* eslint-disable radix  */
import React, { useState } from "react";
import classnames from "classnames";
import { arrayOf, bool, func, shape, string } from "prop-types";

import { Field } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import { get as getMessage } from "util/i18n";
import { noop } from "pages/_components/utils";
import FieldError from "pages/_components/fields/FieldError";
import SelectList from "./SelectList";
import {
    getCurrentGroup,
    getPermissions,
    canSelectProducts,
    merge,
    sortByOrdinal,
    getItemValue,
    iconClassName,
    addAmountSelected,
    getAllowProductSelection,
    ProductModal,
} from "./permissionsUtils";

const VIEW = "view";
const ADVANCED = "advanced";

const Permissions = ({ administrationSchema, data: { groups, products }, form, field, mode, reduced, viewReduced }) => {
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [showModal, setShowModal] = useState(false);
    // const { messages } = useContext(I18nContext);

    const isReadonly = mode === VIEW;
    const isAdvancedSchema = administrationSchema === ADVANCED;
    const allowProductSelection = getAllowProductSelection(reduced, administrationSchema);
    const permissions = getPermissions(groups, field.value || {}, products, reduced, administrationSchema);

    const getI18nKey = (resource) => `administration.permissions.${resource}`;

    const normalizers = {
        priority: (val) => parseInt(val),
    };

    const normalize = (key, val) => (normalizers[key] ? normalizers[key](val) : val);

    const handleOnFieldChange = (changedField, changedForm, normalizeField = false) => {
        const { name, newValue } = changedField;
        const { setFieldValue, setFieldError } = changedForm;

        const value = normalizeField
            ? normalize(name, newValue && newValue.target ? newValue.target.value : newValue)
            : newValue;

        setFieldValue(name, value);
        setFieldError(name, undefined);
    };

    const getFieldProps = (selectedField, selectedForm, handlers = noop, normalizeField = false) => ({
        ...selectedField,
        onChange: (newValue) => {
            handleOnFieldChange({ name: selectedField.name, newValue }, selectedForm, normalizeField);
        },
        ...handlers(selectedForm, selectedField),
    });

    const handleGroupSelect = (group) => {
        if (group.isLeaf) {
            setSelectedGroups(selectedGroups.filter((selectedGroup) => selectedGroup.level < group.level));
        } else if (selectedGroups.findIndex((selectedGroup) => selectedGroup.level === group.level) === -1) {
            setSelectedGroups([...selectedGroups, group]);
        } else {
            setSelectedGroups(
                selectedGroups.flatMap((selectedGroup) => {
                    if (selectedGroup.level === group.level) {
                        return group;
                    }
                    if (selectedGroup.level < group.level) {
                        return selectedGroup;
                    }
                    return [];
                }),
            );
        }
    };

    const selectedPermission = ({ idItem }, level = 0) =>
        selectedGroups[level] && selectedGroups[level].idItem === idItem;

    const getPermissionSelected = (permissionList, idItem, count = { all: 0, selected: 0 }) => {
        if (permissionList && permissionList.length) {
            if (permissionList[0][allowProductSelection] && permissionList[0].productTypes) {
                if (isAdvancedSchema) {
                    const result = permissionList[0].productTypes.split(",").reduce((acc, type) => {
                        if (permissions[idItem] && permissions[idItem].includes(`ALL_${type}`)) {
                            return { all: acc.all + 1, selected: acc.selected + 1 };
                        }
                        return { all: acc.all + 1, selected: acc.selected };
                    }, count);

                    const hasProductsSelected =
                        permissions[idItem] &&
                        permissions[idItem].some((item) => item.indexOf("ALL_") === -1) &&
                        !permissions[idItem].some((item) => item === "NONE");

                    if (hasProductsSelected) {
                        return { all: result.all + 1, selected: result.selected + 1 };
                    }

                    return result;
                }
                return products.reduce((acc, product) => {
                    if (permissions[idItem] && permissions[idItem].includes(product.idProduct)) {
                        return { all: acc.all + 1, selected: acc.selected + 1 };
                    }
                    return { all: acc.all + 1, selected: acc.selected };
                }, count);
            }
            if (!permissionList[0][allowProductSelection]) {
                if (permissions[permissionList[0].idPermission]) {
                    return { all: count.all + 1, selected: count.selected + 1 };
                }
                return { all: count.all + 1, selected: count.selected };
            }
        }

        return count;
    };

    const getSimpleMediumItems = (categoryOption, isFirstLevel = true) => {
        const { idItem, childrenList, permissionList } = categoryOption;
        if (childrenList && childrenList.length) {
            const result = childrenList.flatMap((child) => getSimpleMediumItems(child, false));

            return isFirstLevel
                ? result
                : [
                      {
                          ...categoryOption,
                          className: "fields-label sub-title",
                          hideCheck: true,
                          inline: true,
                          isLeaf: true,
                      },
                      ...result,
                  ];
        }

        if (canSelectProducts(permissionList, allowProductSelection)) {
            return products
                .filter((product) => permissionList[0].productTypes.includes(product.type))
                .reduce((availableProducts, product) => {
                    const { idPermission } = permissionList[0];
                    const checked = permissions[idPermission] && permissions[idPermission].includes(product.idProduct);

                    return [
                        ...availableProducts,
                        {
                            ...product,
                            isLeaf: true,
                            className: isReadonly ? iconClassName({ all: 1, selected: checked ? 1 : 0 }) : undefined,
                            permission: {
                                id: idPermission,
                                permissionList: [idPermission],
                                value: product.idProduct,
                            },
                        },
                    ];
                }, []);
        }

        const selected = getPermissionSelected(permissionList, idItem);

        return [
            {
                ...categoryOption,
                isLeaf: true,
                className: isReadonly ? iconClassName(selected) : undefined,
                permission: {
                    id: idItem,
                    permissionList: permissionList.map((permission) => permission.idPermission),
                    value: getItemValue(categoryOption, reduced),
                },
            },
        ];
    };

    const getAdvancedItems = (categoryOption) => {
        const { idItem, childrenList, permissionList } = categoryOption;

        if (childrenList && childrenList.length) {
            return childrenList.reduce((elements, child) => {
                if (child.childrenList && child.childrenList.length) {
                    return [
                        ...elements,
                        { ...child, className: "label", hideCheck: true, isLeaf: true },
                        ...getAdvancedItems(child),
                    ];
                }

                const isLeaf =
                    child.permissionList &&
                    child.permissionList.length &&
                    !child.permissionList[0][allowProductSelection];
                const selected = getPermissionSelected(child.permissionList, child.idItem);

                const isActive =
                    selectedGroups[selectedGroups.length - 1] &&
                    selectedGroups[selectedGroups.length - 1].idItem === child.idItem;

                const classes = classnames({
                    "item-active": isActive,
                    [iconClassName(selected)]: !isLeaf || isReadonly,
                });

                return [
                    ...elements,
                    {
                        ...child,
                        className: classes,
                        isLeaf,
                        hideCheck: !isLeaf,
                        inline: !isLeaf,
                        permission: {
                            id: child.idItem,
                            permissionList: child.permissionList.map((permission) => permission.idPermission),
                            value: getItemValue(child, reduced),
                        },
                    },
                ];
            }, []);
        }

        if (canSelectProducts(permissionList, allowProductSelection) && permissionList[0].productTypes) {
            return permissionList[0].productTypes.split(",").map((type) => ({
                className: classnames({
                    [permissions[idItem] && permissions[idItem].includes(`ALL_${type}`)
                        ? "select-item select-item--all"
                        : "select-item select-item--none"]: isReadonly,
                }),
                isLeaf: true,
                label: getMessage(`products.ALL_${type}`),
                permission: {
                    id: permissionList[0].idPermission,
                    permissionList: [permissionList[0].idPermission],
                    value: `ALL_${type}`,
                },
            }));
        }

        const isNone = ({ idPermission }) => permissions[idPermission] && permissions[idPermission][0] === "NONE";

        return [
            {
                ...categoryOption,
                isLeaf: true,
                className: classnames({
                    [permissionList.every(isNone)
                        ? "select-item select-item--all"
                        : "select-item select-item--none"]: isReadonly,
                }),
                permission: {
                    id: idItem,
                    permissionList: permissionList.map((permission) => permission.idPermission),
                    value: getItemValue(categoryOption, reduced),
                },
            },
        ];
    };

    const selectedFn = (array, item, selector) => {
        const value = item[selector] && item[selector].value;
        if (!array.length) {
            const { childrenList, permissionList } = item;
            if (childrenList && childrenList.length) {
                return false;
            }

            if (permissionList && permissionList.length && !permissionList[0][allowProductSelection]) {
                const permission = permissionList[0];

                if (permissions[permission.idPermission]) {
                    return permissions[permission.idPermission].includes(Array.isArray(value) ? value[0] : value);
                }
                return false;
            }
        }

        return array.includes(Array.isArray(value) ? value[0] : value);
    };

    const handlers = (handlerForm, handlerField) => ({
        onChange: () => {},
        onItemChecked: (item) => {
            const { permissionList, value } = item;

            const valueArray = Array.isArray(value) ? value : [value];
            const currentGroup = getCurrentGroup(selectedGroups);

            const newValue = permissionList.reduce((permissionsAcc, permission) => {
                const dependentPermission = currentGroup.isDependent;
                const dependsOnPermission = currentGroup.dependsOn;

                // The item checkes has NO permissions selected, so, we add them
                if (!permissionsAcc[permission]) {
                    if (dependentPermission) {
                        return {
                            ...permissionsAcc,
                            [permission]: valueArray,
                            [dependentPermission]: merge(permissionsAcc[dependentPermission], valueArray),
                        };
                    }
                    return {
                        ...permissionsAcc,
                        [permission]: valueArray,
                    };
                }
                // The item checkes has SOME permissions selected and one of them is removed, so, we keep the diff
                if (permissionsAcc[permission].includes(valueArray[0])) {
                    const filteredValue = permissionsAcc[permission].filter((target) => !valueArray.includes(target));

                    if (filteredValue.length === 0) {
                        // if no diff, we remove the permissions
                        const { [permission]: rem, ...newPermissionsAcc } = permissionsAcc;

                        if (dependsOnPermission) {
                            const { [dependsOnPermission]: rem2, ...newPermissionsAcc2 } = newPermissionsAcc;
                            return newPermissionsAcc2;
                        }

                        return newPermissionsAcc;
                    }
                    if (dependsOnPermission) {
                        const filteredValue2 =
                            permissionsAcc[dependsOnPermission] &&
                            permissionsAcc[dependsOnPermission].filter((target) => !valueArray.includes(target));
                        if (filteredValue2) {
                            return {
                                ...permissionsAcc,
                                [permission]: filteredValue,
                                [dependsOnPermission]: filteredValue2,
                            };
                        }
                    }
                    return {
                        ...permissionsAcc,
                        [permission]: filteredValue,
                    };
                }
                // The item checkes has SOME permissions selected but the items are new, so, we keep both sets
                if (dependentPermission) {
                    return {
                        ...permissionsAcc,
                        [permission]: merge(permissionsAcc[permission], valueArray),
                        [dependentPermission]: merge(permissionsAcc[dependentPermission], valueArray),
                    };
                }
                return {
                    ...permissionsAcc,
                    [permission]: merge(permissionsAcc[permission], valueArray),
                };
            }, permissions);

            handleOnFieldChange({ name: handlerField.name, newValue }, handlerForm);
        },
    });

    const handleAddButtonClick = (modalPermissions) => {
        const { idItem } = getCurrentGroup(selectedGroups);

        const newValue = {
            ...permissions,
            [idItem]: modalPermissions,
        };

        handleOnFieldChange({ name: field.name, newValue }, form);

        setShowModal(false);
    };

    const renderSelectedProducts = (group) => {
        const { idItem, permissionList } = group;
        const items =
            products &&
            products
                .filter(
                    (product) =>
                        permissionList[0].productTypes.includes(product.type) &&
                        permissions[idItem] &&
                        permissions[idItem].includes(product.idProduct),
                )
                .map((product) => ({ ...product, className: "select-item select-item--all" }));

        return (
            <>
                <Box className="permissions__actions">
                    {isReadonly && (
                        <>
                            <p className="label ml-3">
                                <I18n id={getI18nKey("actions.products.title")} />
                            </p>
                            {(!items || items.length === 0) && (
                                <p className="label ml-3">
                                    <I18n id={getI18nKey("products.noSelectedProducts")} />
                                </p>
                            )}
                        </>
                    )}
                    {!isReadonly && (
                        <Button onClick={() => setShowModal(true)} className="btn-link">
                            <I18n id={getI18nKey("actions.products.edit")} />
                        </Button>
                    )}
                </Box>
                <SelectList
                    {...getFieldProps(field, form, handlers)}
                    itemLabel={(item) => item.label}
                    items={items}
                    readonly
                    scrollable={false}
                    showSelectAll={false}
                    sort={sortByOrdinal}
                />
            </>
        );
    };

    const renderChildrenColumns = () =>
        selectedGroups.map((selectedGroup) => {
            const { idItem, label, permissionList, isLeaf, level } = selectedGroup;
            if (!isLeaf) {
                const items = isAdvancedSchema ? getAdvancedItems(selectedGroup) : getSimpleMediumItems(selectedGroup);

                const hasAdvancedProductSelection =
                    isAdvancedSchema && canSelectProducts(permissionList, allowProductSelection);

                return (
                    <Box className="permissions__item permissions__item--selected" key={idItem}>
                        {/* <Box className="permissions__title">{label}</Box> */}
                        {!hasAdvancedProductSelection ? (
                            <Box className="permissions__title">
                                <I18n id={getI18nKey("title.cashServices")} />
                            </Box>
                        ) : (
                            <Box className="permissions__title">{label}</Box>
                        )}
                        {items && items.length > 0 ? (
                            <>
                                {/* {!!hasAdvancedProductSelection && (
                                    <Box className="permissions__actions">
                                        <p className="label">
                                            <I18n id={getI18nKey("actions.smartGroupList.title")} />
                                        </p>
                                    </Box>
                                )} */}
                                {!hasAdvancedProductSelection && (
                                    <Box className="permissions__list">
                                        <SelectList
                                            {...getFieldProps(field, form, handlers)}
                                            idSelector="permission"
                                            itemLabel={(item) => item.label}
                                            items={items}
                                            onItemClick={(group) =>
                                                handleGroupSelect({
                                                    ...group,
                                                    level: level + 1,
                                                })
                                            }
                                            readonly={isReadonly}
                                            scrollable={false}
                                            selected={permissions[idItem]}
                                            selectedFn={selectedFn}
                                            selectedTitle={(item) => selectedPermission(item, level + 1)}
                                            showSelectAll={false}
                                            sort={sortByOrdinal}
                                        />
                                    </Box>
                                )}
                                {!!hasAdvancedProductSelection && renderSelectedProducts(selectedGroup)}
                            </>
                        ) : (
                            <div className="text-center">
                                <I18n id={getI18nKey("products.noProducts")} />
                            </div>
                        )}
                    </Box>
                );
            }

            return null;
        });

    const { touched, errors } = form;
    const hasError = touched[field.name] && errors[field.name];

    return (
        <Box>
            {hasError && (
                <Box className="mb-5">
                    <FieldError error={errors[field.name]} />
                </Box>
            )}
            <Box display="flex" className="permissions">
                <Box className={`permissions__body${viewReduced ? "_two_cols" : "_cash_product"} perm-mode-${mode}`}>
                    <Box className="permissions__item">
                        <Box className="permissions__title">
                            <I18n id={getI18nKey("title.cashProducts")} />
                        </Box>
                        <SelectList
                            idSelector="permission"
                            itemLabel={(item) => item.label}
                            items={addAmountSelected(groups, getPermissionSelected)}
                            listKey="groups"
                            onItemClick={(group) => handleGroupSelect({ ...group, level: 0 })}
                            readonly
                            scrollable={false}
                            selectedTitle={selectedPermission}
                            showSelectAll={false}
                            sort={sortByOrdinal}
                        />
                    </Box>
                    {renderChildrenColumns()}
                    {/* {form.errors && form.errors.permissions && (
                        <Box className="invalid-feedback">
                            <I18n id={form.errors.permissions} />
                        </Box>
                    )} */}
                </Box>
                {showModal && (
                    <ProductModal
                        value={permissions}
                        initialSelected={
                            permissions[
                                selectedGroups.sort((x, y) => x.level - y.level)[selectedGroups.length - 1].idItem
                            ] || []
                        }
                        selectedGroups={selectedGroups}
                        products={products}
                        addItems={handleAddButtonClick}
                        onClose={() => setShowModal(false)}
                        getI18nKey={getI18nKey}
                    />
                )}
            </Box>
        </Box>
    );
};

Permissions.propTypes = {
    form: shape({
        setErrors: func.isRequired,
        setFieldValue: func.isRequired,
    }).isRequired,
    field: shape({
        name: string.isRequired,
        value: shape({}),
    }).isRequired,
    administrationSchema: string.isRequired,
    data: shape({
        groups: arrayOf(shape({})),
        products: arrayOf(shape({})),
    }).isRequired,
    mode: string.isRequired,
    reduced: bool,
    viewReduced: bool,
};

Permissions.defaultProps = {
    reduced: false,
    viewReduced: false,
};

// eslint-disable-next-line react/prop-types
export default ({ fieldName, ...props }) => <Field name={fieldName} component={Permissions} {...props} />;
