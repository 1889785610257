import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import MainContainer from "pages/_components/MainContainer";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { selectors } from "reducers/unlockUser";
import LoginWrapper from "pages/login/_components/LoginWrapper";
import * as i18n from "util/i18n";
import { replace } from "react-router-redux";
import Image from "pages/_components/Image";

const UnlockUserStep3 = ({ email, isMobile, dispatch }) => {
    const goToLogin = () => {
        dispatch(replace("/loginStep1"));
    };

    return (
        <LoginWrapper>
            <>
                <MainContainer>
                    <Box>
                        <Box display="flex" alignX="center" className="mb-8">
                            <Box className="login-blocked-icon">
                                <Image src="images/util/success.gif" />
                            </Box>
                        </Box>
                        <Box className="text-center mb-7">
                            <Text size="3" labelKey="recoveryUser.step3.title" bold />
                        </Box>
                        <Box
                            display="flex"
                            column
                            fullWidth
                            {...(isMobile ? { fullHeight: true } : { fullHeight: false })}>
                            <Box display="block" fullWidth className="text-center mb-7 line-height-15">
                                <Text
                                    defaultValue={i18n.get(
                                        "unlockUser.step2.OTPMailSend",
                                        "Se ha enviado un mensaje como confirmación al correo electrónico&nbsp;",
                                    )}
                                />
                                <Text bold defaultValue={email} />
                            </Box>

                            <Box display="flex" className="mb-7 mb-md-0 mt-auto">
                                <Button label="login.comeIn" bsStyle="primary" onClick={goToLogin} block />
                            </Box>
                        </Box>
                    </Box>
                </MainContainer>
            </>
        </LoginWrapper>
    );
};

UnlockUserStep3.propTypes = {
    dispatch: func.isRequired,
    isMobile: bool.isRequired,
    fetching: bool.isRequired,
    email: string.isRequired,
};

const mapStateToProps = (state) => ({
    fetching: selectors.getFetching(state),
    email: selectors.getEmail(state),
});

export default connect(mapStateToProps)(UnlockUserStep3);
