import React, { useState } from "react";
import { func, string } from "prop-types";
import { connect } from "react-redux";
import { isDesktop } from "react-device-detect";
import FlowTransition from "pages/_components/FlowTransition";
import { routerActions } from "react-router-redux";
import ExitModal from "pages/_components/modal/ExitModal";
import * as i18n from "util/i18n";
import { selectors } from "reducers/login";
import { actions } from "reducers/changeExpiredPassword";
import ChangePasswordSuccess from "./ChangePasswordSuccess";
import ChangePassword from "./ChangePassword";
import ChangePasswordConfirmation from "./ChangePasswordConfirmation";

const steps = {
    making: "making",
    confirmation: "confirmation",
    success: "success",
};
const ID_FORM = "changeExpiredPassword";
const ChangePasswordFlow = ({ username, dispatch }) => {
    const [currentStep, setCurrentStep] = useState(steps.making);
    const [confirmationData, setConfirmationData] = useState({});
    const [showExitModal, setShowExitModal] = useState(false);
    const onSuccessStep1 = (data) => {
        setConfirmationData(data);
        setCurrentStep(steps.confirmation);
    };
    const changePassStep1 = (data, formikBag) => {
        const { password, newPassword, newPasswordRepeat } = data;
        dispatch(
            actions.changePasswordStep1(username, password, newPassword, newPasswordRepeat, formikBag, onSuccessStep1),
        );
    };
    const handleSubmitMaking = (data, formikBag) => {
        changePassStep1(data, formikBag);
    };
    const onSuccess = () => {
        setCurrentStep(steps.success);
    };
    const handleSubmitConfirm = ({ secondFactor }, formikBag) => {
        const { newPassword } = confirmationData;
        dispatch(actions.changePasswordStep2(newPassword, secondFactor, formikBag, onSuccess));
    };
    const handlePreCancel = () => {
        setShowExitModal(true);
    };
    const goToLogin = () => {
        dispatch(routerActions.push("/"));
    };
    const handleAcceptModal = () => {
        goToLogin();
        setShowExitModal(false);
    };
    const handleHideModal = () => {
        setShowExitModal(false);
    };
    if (!username) {
        goToLogin();
        return null;
    }

    return (
        <>
            <FlowTransition
                currentPage={currentStep}
                pages={{
                    [steps.making]: () => (
                        <>
                            <ChangePassword
                                idForm={ID_FORM}
                                isDesktop={isDesktop}
                                handleSubmit={handleSubmitMaking}
                                handleCancel={handlePreCancel}
                            />
                        </>
                    ),
                    [steps.confirmation]: () => (
                        <ChangePasswordConfirmation
                            idForm={ID_FORM}
                            isDesktop={isDesktop}
                            handleSubmit={handleSubmitConfirm}
                            handleCancel={handlePreCancel}
                        />
                    ),
                    [steps.success]: () => (
                        <ChangePasswordSuccess idForm={ID_FORM} isDesktop={isDesktop} goToLogin={goToLogin} />
                    ),
                }}
            />
            <ExitModal
                modalShow={showExitModal}
                acceptFunction={handleAcceptModal}
                cancelFunction={handleHideModal}
                headingText={i18n.get("confirm.exit.title")}
                text={i18n.get("confirm.exit.info")}
            />
        </>
    );
};
ChangePasswordFlow.propTypes = {
    username: string.isRequired,
    dispatch: func.isRequired,
};
const mapStateToProps = (state) => ({
    username: selectors.getUsername(state),
});
export default connect(mapStateToProps)(ChangePasswordFlow);
