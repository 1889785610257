import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { bool, func, string } from "prop-types";
import { getPasswordHelp } from "pages/settings/ChangePassword";
import Button from "pages/_components/Button";
import Box from "pages/_components/Box";
import Credential from "pages/_components/fields/credentials/Credential";
import LabelWithIcon from "pages/_components/LabelWithIcon";
import Text from "pages/_components/Text";
import classNames from "classnames";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import * as config from "util/config";
import Row from "pages/_components/Row";
import { Col } from "react-bootstrap";

const ChangePassword = ({ idForm, isDesktop, handleSubmit, handleCancel }) => {
    const minLength = config.getInteger("core.password.minLength");
    const maxLength = config.getInteger("core.password.maxLength");
    const [disableButton, setDisableButton] = useState(true);
    const alertMessage = (values) => {
        const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
        const hasSpecialChar = specialCharRegex.test(values.newPassword);
        const numericCharRegex = /[0-9]/;
        const hasNumericChar = numericCharRegex.test(values.newPassword);
        const uppercaseRegex = /[A-Z]/;
        const hasUppercase = uppercaseRegex.test(values.newPassword);
        const lowercaseRegex = /[a-z]/;
        const hasLowercase = lowercaseRegex.test(values.newPassword);
        const isValidPassword =
            values.newPassword.length >= minLength &&
            values.newPassword.length < maxLength &&
            hasSpecialChar &&
            hasNumericChar &&
            hasUppercase &&
            hasLowercase;
        setDisableButton(!isValidPassword);
        return (
            <Box fullWidth className="mb-7 mb-md-7 ">
                <LabelWithIcon
                    type={values.newPassword.length < minLength || values.newPassword.length > 15 ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.1"
                />
                <LabelWithIcon
                    type={!hasSpecialChar ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.2"
                />
                <LabelWithIcon
                    type={!hasNumericChar ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.3"
                />
                <LabelWithIcon
                    type={!hasUppercase ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.4"
                />
                <LabelWithIcon
                    type={!hasLowercase ? "error" : "alert"}
                    text="recovery.password.step3.password.alert.5"
                />
            </Box>
        );
    };

    const validateFields = ({ values }) =>
        disableButton || values?.newPasswordRepeat !== values?.newPassword || values?.password?.length < minLength;

    const validationSchema = Yup.object().shape({
        password: Yup.string().required(i18n.get("global.field.required")),
        newPassword: Yup.string().required(i18n.get("global.field.required")),
        newPasswordRepeat: Yup.string().required(i18n.get("global.field.required")),
    });

    return (
        <Box fullWidth>
            <Formik
                enableReinitialize
                initialValues={{
                    password: "",
                    newPassword: "",
                    newPasswordRepeat: "",
                }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}>
                {(formik) => (
                    <Form>
                        <Box
                            display="flex"
                            column
                            fullWidth
                            {...(!isDesktop ? { fullHeight: true } : { fullHeight: false })}>
                            <Box className="mb-8">
                                <Text labelKey={`${idForm}.title`} size="2" bold />
                            </Box>
                            <Box display="flex" column className="my-auto pb-7">
                                <Field
                                    name="password"
                                    idForm={idForm}
                                    component={Credential}
                                    maxLength={maxLength}
                                    placeholder=""
                                    // tooltip={getPasswordHelp()}
                                    className="mb-7"
                                    tooltipPosition="bottom-right"
                                />
                                <Field
                                    name="newPassword"
                                    idForm={idForm}
                                    component={Credential}
                                    maxLength={maxLength}
                                    placeholder=""
                                    tooltip={getPasswordHelp()}
                                    className="mb-7"
                                    tooltipPosition="bottom-right"
                                />
                                {alertMessage(formik.values)}
                                <Field
                                    name="newPasswordRepeat"
                                    idForm={idForm}
                                    component={Credential}
                                    maxLength={maxLength}
                                    placeholder=""
                                />
                            </Box>
                            <Row
                                gapX="7"
                                className={classNames("mb-3", {
                                    "d-flex flex-column": !isDesktop,
                                })}>
                                <Col xs={6}>
                                    <Button
                                        type="submit"
                                        bsStyle="primary"
                                        label="global.continue"
                                        btnUppercase={false}
                                        disabled={validateFields(formik)}
                                        loading={formik.isSubmitting}
                                        block
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Button
                                        type="button"
                                        bsStyle="outline"
                                        label="global.cancel"
                                        btnUppercase={false}
                                        onClick={handleCancel}
                                        block
                                    />
                                </Col>
                            </Row>
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};
ChangePassword.propTypes = {
    isDesktop: bool.isRequired,
    idForm: string.isRequired,
    handleSubmit: func.isRequired,
    handleCancel: func.isRequired,
};
export default ChangePassword;
