/* eslint-disable react/prop-types */
import classNames from "classnames";
import React, { useState } from "react";

import Button from "pages/_components/Button";
import I18n from "pages/_components/I18n";
import Box from "pages/_components/Box";
import DefaultModal from "pages/_components/modal/DefaultModal";
import Table from "rc-table";
import * as i18n from "util/i18n";
import Checkbox from "pages/_components/fields/Checkbox";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";

const SIMPLE = "simple";
const MEDIUM = "medium";
const ADVANCED = "advanced";

export const merge = (array1, array2) => [...new Set([...(array1 || []), ...(array2 || [])])];

export const getItemValue = (item, reduced) => {
    if (reduced) {
        return "NONE";
    }
    const permission = item.permissionList[0];
    return !permission.productTypes
        ? "NONE"
        : permission.productTypes.split(",").map((productType) => `ALL_${productType}`);
};

export const getItemProducts = (group, products) => {
    const productTypes = group.permissionList[0].productTypes.split(",");
    const possibleProducts = products.filter((product) => productTypes.includes(product.type));
    return possibleProducts.map((p) => p.idProduct);
};

export const canSelectProducts = (permissionList, allowProductSelection) =>
    permissionList && permissionList.length && permissionList[0][allowProductSelection];

export const getPossibleValues = (group, reduced, products, allowProductSelection) => {
    const values = getItemValue(group, reduced);

    if (canSelectProducts(group.permissionList, allowProductSelection)) {
        return [...values, ...getItemProducts(group, products)];
    }
    return values;
};

export function getAllowProductSelection(reduced, administrationSchema) {
    if (reduced) {
        // Clear allowProduct selection for reduced renders
        return "";
    }
    switch (administrationSchema) {
        case SIMPLE:
            return "simpleAllowProductSelection";
        case MEDIUM:
            return "mediumAllowProductSelection";
        default:
            return "advancedAllowProductSelection";
    }
}
export const sortByOrdinal = (a, b) => (a.ordinal >= b.ordinal ? 1 : -1);

export const getCurrentGroup = (selectedGroups) =>
    selectedGroups.sort((x, y) => x.level - y.level)[selectedGroups.length - 1];

const reducePermissions = (permissionList, possibles) =>
    permissionList.reduce(
        (acc, { idPermission }) => ({
            ...acc,
            [idPermission]: possibles,
        }),
        {},
    );

export const getSimpleMediumGroupPermissions = (group, permissions, products, reduced, administrationSchema) => {
    const { childrenList, permissionList } = group;
    const allowProductSelection = getAllowProductSelection(reduced, administrationSchema);

    if (childrenList && childrenList.length) {
        return childrenList.reduce(
            (acc, child) => ({
                ...acc,
                ...getSimpleMediumGroupPermissions(child, permissions, products, reduced, administrationSchema),
            }),
            {},
        );
    }

    if (canSelectProducts(permissionList, allowProductSelection) && permissionList[0].productTypes) {
        const { idPermission } = permissionList[0];
        return {
            [idPermission]: getItemProducts(group, products),
        };
    }

    const itemValue = getItemValue(group, reduced);
    return reducePermissions(permissionList, itemValue);
};

export const getAdvancedGroupPermissions = (group, permissions, products, reduced, administrationSchema) => {
    const { childrenList, permissionList } = group;

    const allowProductSelection = getAllowProductSelection(reduced, administrationSchema);

    if (childrenList && childrenList.length) {
        return childrenList.reduce((acc, child) => {
            if (child.childrenList && child.childrenList.length) {
                return {
                    ...acc,
                    ...getAdvancedGroupPermissions(child, permissions, products, reduced, administrationSchema),
                };
            }

            if (child.permissionList && child.permissionList.length) {
                const possibles = getPossibleValues(child, reduced, products, allowProductSelection);
                return {
                    ...acc,
                    ...reducePermissions(child.permissionList, possibles),
                };
            }

            return acc;
        }, {});
    }

    if (canSelectProducts(permissionList, allowProductSelection) && permissionList[0].productTypes) {
        const possibles = getPossibleValues(group, reduced, products, allowProductSelection);
        return reducePermissions(permissionList, possibles);
    }

    const itemValue = getItemValue(group, reduced);
    return reducePermissions(permissionList, itemValue);
};

export const getValidPermissions = (permissions, allPermissions) =>
    Object.keys(permissions).reduce((acc, id) => {
        const filterValue =
            permissions[id] && permissions[id].filter((val) => allPermissions[id] && allPermissions[id].includes(val));
        if (filterValue && filterValue.length) {
            return { ...acc, [id]: filterValue };
        }
        return acc;
    }, {});

export const getPermissions = (groups, permissions, products, reduced, administrationSchema) => {
    const isAdvancedSchema = administrationSchema === ADVANCED;

    const getPerms = isAdvancedSchema ? getAdvancedGroupPermissions : getSimpleMediumGroupPermissions;
    const allPermissions = groups.reduce(
        (acc, group) => ({
            ...acc,
            ...getPerms(group, permissions, products, reduced, administrationSchema),
        }),
        {},
    );

    return getValidPermissions(permissions, allPermissions);
};

export const iconClassName = ({ all, selected }) => {
    if (all === selected) {
        return "select-item select-item--all";
    }
    if (selected === 0) {
        return "select-item select-item--none";
    }
    return "select-item select-item--some";
};

export const getAmountByGroup = (group, getPermissionSelected) =>
    group.reduce(
        (acc, g) => {
            let count = { all: 0, selected: 0 };

            if (g.childrenList && g.childrenList.length > 0) {
                count = getAmountByGroup(g.childrenList, getPermissionSelected);
            }

            const amount = getPermissionSelected(g.permissionList, g.idItem, count);

            return {
                all: acc.all + amount.all,
                selected: acc.selected + amount.selected,
            };
        },
        { all: 0, selected: 0 },
    );

export const addAmountSelected = (groups, getPermissionSelected) =>
    groups &&
    groups.map((group) => {
        let count = { all: 0, selected: 0 };
        if (group.childrenList && group.childrenList.length > 0) {
            count = getAmountByGroup(group.childrenList, getPermissionSelected);
        }

        const { all, selected } = getPermissionSelected(group.permissionList, group.idItem);
        const className = iconClassName({
            all: all + count.all,
            selected: selected + count.selected,
        });

        return { ...group, className };
    }, []);

const populateData = (products, selected, handleItemChecked) =>
    products.map(({ idProduct, label, type }) => ({
        key: idProduct,
        selection: (
            <Checkbox
                onChange={() => handleItemChecked(idProduct)}
                hideLabel
                id={idProduct}
                name={idProduct}
                checked={selected.includes(idProduct)}
            />
        ),
        label: (
            <div className="data-wrapper data-wrapper-flex">
                <span className="data-desc">{label}</span>
            </div>
        ),
        type: (
            <div>
                <I18n id={`productType.${type}`} />
            </div>
        ),
    }));

export const ProductModal = ({ value, initialSelected, selectedGroups, products, addItems, onClose, getI18nKey }) => {
    const [selected, setModalSelectedPermissions] = useState(initialSelected);
    const group = getCurrentGroup(selectedGroups);
    const productTypes = group.permissionList[0].productTypes.split(",");

    const handleAddButtonClick = () => {
        addItems(selected);
    };

    const footer = (
        <>
            <Button secondary onClick={onClose} data-dismiss="modal">
                <I18n id="cybo.general.modals.buttons.cancel" />
            </Button>
            <Button primary form="auth-form" onClick={handleAddButtonClick}>
                <I18n id="cybo.general.modals.buttons.accept" />
            </Button>
        </>
    );

    const items = products.filter((product) => productTypes.some((item) => item === product.type));

    const handleItemChecked = (item) => {
        if (selected.includes(item)) {
            setModalSelectedPermissions(selected.filter((x) => x !== item));
        } else {
            setModalSelectedPermissions([...selected, item]);
        }
    };

    const isAllSelected = selected.filter((item) => !item.includes("ALL_")).length === items.length;

    const handleSelectAllClick = () => {
        const initialModalProducts = value[group.idItem]
            ? value[group.idItem].filter((item) => item.includes("ALL_"))
            : [];

        if (isAllSelected) {
            setModalSelectedPermissions([...initialModalProducts]);
        } else {
            setModalSelectedPermissions([...initialModalProducts, ...items.map((item) => item.idProduct)]);
        }
    };

    const columns = [
        {
            key: "selection",
            dataIndex: "selection",
            title: (
                // <BulkCheckBoxCell
                //     toggleSelectedProps={() => ({
                //         // ...toggleAllRowsSelectedProps,
                //         onChange: (e) => {
                //             // toggleAllRowsSelectedProps.onChange(e);
                //             handleSelectAllClick();
                //         },
                //     })}
                //     isSelected={isAllSelected}
                // />
                <Checkbox onChange={handleSelectAllClick} hideLabel checked={isAllSelected} />
            ),
            width: 20,
            onCell: () => ({
                onClick(e) {
                    e.stopPropagation();
                },
            }),
        },
        {
            key: "name",
            dataIndex: "label",
            title: i18n.get(getI18nKey("productList.modal.name")),
        },
        {
            key: "type",
            dataIndex: "type",
            title: i18n.get(getI18nKey("productList.modal.type")),
        },
    ];

    const [searchValue, setSearchValue] = useState("");

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleClearFilter = () => {
        setSearchValue("");
    };

    const itemsFiltered = items?.filter((item) => item.label.toLowerCase().includes(searchValue.toLowerCase()));

    return (
        <DefaultModal
            className="modal-permission-products"
            modalShow
            renderChildren
            onDismiss={onClose}
            headingText={
                <I18n
                    id={getI18nKey("perm.productList.title")}
                    permName={group.label}
                    params={{ permName: group.label }}
                />
            }
            cancelFunction={onClose}
            acceptFunction={handleAddButtonClick}
            footer={footer}>
            <Box className="permissions-products my-7">
                {items && items.length ? (
                    <>
                        <Box className="my-5">
                            <TextSimpleField
                                name="searchInput"
                                placeholder={i18n.get("administration.groups.search")}
                                value={searchValue}
                                onChange={handleInputChange}
                                serarchStyle
                                autoComplete="off"
                            />

                            <Box
                                className={classNames("quick-search__cancel", { show: searchValue })}
                                onClick={handleClearFilter}
                            />
                        </Box>
                        <Box className="permissions-table-products custom-scrollbar mb-3">
                            {/* <Table columns={columns} data={itemsFiltered} onSelectedRows={noop} /> */}
                            <Table columns={columns} data={populateData(itemsFiltered, selected, handleItemChecked)} />
                        </Box>
                    </>
                ) : (
                    <div className="text-center">
                        <I18n id={getI18nKey("products.noProducts")} />
                    </div>
                )}
            </Box>
        </DefaultModal>
    );
};

export const filterPermissionsSelected = (groups, selected) => {
    const targets = Object.keys(selected);
    return groups
        .map((f) => ({
            ...f,
            childrenList:
                f.childrenList
                    ?.map((c) => ({
                        ...c,
                        childrenList: c.childrenList?.filter((z) => targets.includes(z.idItem)) || [],
                    }))
                    .filter((c) => c.childrenList && c.childrenList.length > 0) || [],
        }))
        .filter((f) => f.childrenList && f.childrenList.length > 0);
};
