import classNames from "classnames";
// import Logo from "pages/login/_components/Logo";
import MainContainer from "pages/_components/MainContainer";
import withExchangeToken from "pages/_components/withExchangeToken";
import { bool, func, number, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux/actions";
import { selectors as loginSelectors } from "reducers/login";
import { selectors as sessionSelectors } from "reducers/session";
import { compose } from "redux";
import * as i18n from "util/i18n";
import Text from "pages/_components/Text";
import LoginInfo from "./_components/LoginInfo";
import Box from "pages/_components/Box/Box";

class InfoOtp extends Component {
    componentDidMount() {
        const { dispatch, activeEnvironment, username } = this.props;
        if (activeEnvironment) {
            dispatch(push("/desktop"));
        } else if (!username) {
            dispatch(push("/"));
        }
    }

    render() {
        const { isDesktop } = this.props;

        return (
            <>
                <MainContainer
                    className={classNames("main-container max-width-full", {
                        "container-fluid with-special-header-login": isDesktop,
                    })}>
                    <LoginInfo 
                    title={i18n.get("login.step3.secondFactor.otp.help.title")}
                    image="images/iconPopOvers/OTP.svg"
                    content={<>
                    
                        <Box className="pl-6 pr-6">
                            <Box display="block" fullWidth>
                                <Text defaultValue={i18n.get("login.step3.secondFactor.otp.help.text2")} size="5" />
                                <Text bold defaultValue={i18n.get("login.step3.secondFactor.otp.help.text3")} size="5" />
                                <Text defaultValue={i18n.get("login.step3.secondFactor.otp.help.text4")} size="5" />
                            </Box>
                        </Box>
                        <Box className="pl-6 pr-6">
                            <Text
                                size="5"
                                className="secondary-description"
                                defaultValue={i18n.get("login.step3.secondFactor.otp.help.text5")}
                            />
                        </Box>
                    </>
                    }/>
                </MainContainer>
            </>
        );
    }
}

InfoOtp.propTypes = {
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    username: string.isRequired,
    activeEnvironment: number,
};

InfoOtp.defaultProps = {
    activeEnvironment: null,
};

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    username: loginSelectors.getUsername(state),
});

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(InfoOtp));
