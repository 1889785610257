import EmptyTextBox from "pages/_components/EmptyTextBox";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import LoansList from "pages/loans/List";
import { array, bool, func, oneOfType, string } from "prop-types";
import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { routerActions } from "react-router-redux/actions";
import { actions as loansActions, selectors as loansSelectors } from "reducers/loans";
import { actions as productsActions } from "reducers/products";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as productRequestActions } from "reducers/productRequest";
import Box from "pages/_components/Box";
import * as config from "util/config";
import PaginatedDataTable from "pages/_components/PaginatedTableComponent";
import { parse } from "query-string";
import HeaderLoanDescription from "./_components/HeaderLoanDescription";
import LoanTabs from "./_components/LoanTabs";

class ProductList extends Component {
    static propTypes = {
        isRequestAvailable: oneOfType([string, bool]),
        dispatch: func.isRequired,
        isDesktop: oneOfType([string, bool]),
        isMobile: oneOfType([string, bool]),
        fetching: oneOfType([string, bool]),
        loans: oneOfType([array]),
        showProductIcon: bool,
        completeFavorites: bool,
        // loggedUser: shape({
        //     bankType: string,
        // }),
    };

    static defaultProps = {
        isRequestAvailable: false,
        isDesktop: false,
        isMobile: false,
        fetching: false,
        loans: [],
        showProductIcon: true,
        completeFavorites: false,
        // loggedUser: null,
    };

    state = {
        showNoLimitAccountRequestModal: false,
        rowsPerPage: config.getInteger("table.rowsPerPage", 10),
        downloading: false,
        items: [
            { id: "loans", groupCode: "CARTERA" },
            { id: "factoring", groupCode: "FACTORING" },
            { id: "commerce", groupCode: "COMEXT" },
        ],
        typeIniIndex: 0,
    };

    componentDidMount() {
        const { dispatch, location } = this.props;
        const idLoanFromRedirect = parse(location.search)?.id;
        if (idLoanFromRedirect != null) {
            const items= this.getItems();
            const loan = this.getLoanFromRedirect(idLoanFromRedirect);
            const index = items.findIndex((item) => item.groupCode === loan.groupCode);
            this.selectItem(index);
        }

        dispatch(loansActions.listLoans());
    }

    getLoanFromRedirect(idLoan) {
        const { loans } = this.props;
        return loans.find((loan) => loan.idProduct === idLoan) || null;
    }

    handleRequestLoanClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/loansPayment/request/"));
    };

    handleBack = () => {
        const { dispatch } = this.props;

        dispatch(push(`/desktop`));
    };

    displayProductRequestModal = () => {
        const { dispatch } = this.props;
        dispatch(productRequestActions.modalShow("LO"));
    };

    onFinishDownload = () => {
        this.setState({
            downloading: false,
        });
    };

    handleClickDownload = (format) => {
        const { dispatch } = this.props;
        const { typeIniIndex } = this.state;
        const items = this.getItems();
        this.setState({
            downloading: true,
        });
        dispatch(loansActions.downloadLoans(format, items[typeIniIndex].groupCode, this.onFinishDownload));
    };

    renderHeader = () => {
        const {
            isRequestAvailable,
            isMobile,
            loans,
            isDesktop,
            // loggedUser
        } = this.props;
        // const { bankType } = loggedUser;

        // const requestLoan = config.get(`loans.requestLoan.byBankType.${bankType}`);
        // const showNewLoanButton = config.get("loans.show.button.createLoan");

        // const optionToCreatLoan =
        //     showNewLoanButton && requestLoan
        //         ? {
        //               addLinkTo: "/loansPayment/request/",
        //               addLinkToLabel: "loan.new",
        //           }
        //         : null;

        // const optionToCreatLoanMobile =
        //     showNewLoanButton && requestLoan
        //         ? {
        //               addLinkTo: "/loansPayment/request/",
        //           }
        //         : null;

        const { downloading } = this.state;

        const documents = [
            {
                label: "global.pdfFile",
                onClick: () => this.handleClickDownload("pdf"),
            },
            {
                label: "global.xlsFile",
                onClick: () => this.handleClickDownload("xls"),
            },
            {
                label: "global.txtFile",
                onClick: () => this.handleClickDownload("txt"),
            },
        ];

        if (!isRequestAvailable) {
            return (
                <Head
                    {...(!isDesktop && { onBack: this.handleBack })}
                    title="menu.loans"
                    {...(loans && loans.length > 0 && { exportList: documents })}
                    isFetchingExport={downloading}
                    exportListBsStyle={!isMobile && "download"}
                    // {...optionToCreatLoanMobile}
                />
            );
        }
        if (isMobile) {
            return (
                <Head
                    onBack={this.handleBack}
                    title="menu.loans"
                    {...(loans && loans.length > 0 && { exportList: documents })}
                    isFetchingExport={downloading}
                    // {...optionToCreatLoanMobile}
                />
            );
        }

        return (
            <Head
                title="menu.loans"
                {...(loans && loans.length > 0 && { exportList: documents })}
                exportListBsStyle="download"
                isFetchingExport={downloading}
                // {...optionToCreatLoan}
            />
        );
    };

    btnHandlerOnClick = () => {
        const { dispatch } = this.props;
        dispatch(push("/loansPayment/request/"));
    };

    setFavoriteLoan = (idProduct, productType, favorite) => {
        const { dispatch } = this.props;
        dispatch(productsActions.toggleFavorite(idProduct, productType, favorite, null, true));
    };

    getFilteredItems(loans, item) {
        let filteredItems = [];
        if (loans && loans.length > 0) {
            filteredItems = loans?.filter((e) => e.groupCode === item.groupCode);
        }
        return filteredItems;
    }

    selectItem = (idx) => {
        this.setState({
            typeIniIndex: idx,
        });
    };

    renderItem(item) {
        const { loans, isDesktop, showProductIcon, completeFavorites } = this.props;
        const { rowsPerPage } = this.state;
        const filteredLoans = this.getFilteredItems(loans, item);
        if (filteredLoans && filteredLoans.length) {
            return (
                <Box
                    key={item.id}
                    isDesktop={isDesktop}
                    keyLabel={item.label ? item.label : `loans.list.${item.id}.label`}>
                    <PaginatedDataTable
                        nameList="loans"
                        data={filteredLoans}
                        component={LoansList}
                        rowsPerPage={rowsPerPage}
                        componentProps={{
                            setFavoriteLoan: this.setFavoriteLoan,
                            showProductIcon,
                            completeFavorites,
                            isDesktop,
                        }}
                    />
                </Box>
            );
        }
        return <></>;
    }

    getItems() {
        const { loans } = this.props;
        const { items } = this.state;
        const itemsFinal = items.filter((c) => loans.filter((l) => l.groupCode === c.groupCode).length > 0);
        return itemsFinal;
    }

    render() {
        const { loans, fetching } = this.props;
        const items = this.getItems();
        const isLoading = fetching && !loans.length;
        const { typeIniIndex } = this.state;
        return (
            <Fragment>
                <Notification scopeToShow="loans" />
                {!isLoading && this.renderHeader()}
                <MainContainer
                    showLoader={isLoading}
                    className="main-container border-radius-lg p-7 box-shadow-small texture-header background-white">
                    <Col xs={12} md={8} mdOffset={2}>
                        <HeaderLoanDescription />
                        {loans && loans.length > 0 ? (
                            <LoanTabs onSelect={(index) => this.selectItem(index)} selectedIndex={typeIniIndex}>
                                {items && items.length === 1
                                    ? this.renderItem(items[0])
                                    : items.map((item) => this.renderItem(item))}
                            </LoanTabs>
                        ) : (
                            <EmptyTextBox text="product.empty.text.loans" />
                        )}
                    </Col>
                </MainContainer>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    loans: loansSelectors.getLoans(state),
    fetching: loansSelectors.getFetching(state),
    // loggedUser: sessionSelectors.getUser(state),
    isRequestAvailable: sessionSelectors.hasPermissions(state, ["requestLoan"]),
    completeFavorites: loansSelectors.getCompleteFavorites(state),
});

export default connect(mapStateToProps)(ProductList);
